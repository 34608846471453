// Config model
export const CONFIG = {
    init: false,
    debug: true,
    analytics: null,
    reverseProxy: false,
    app: "firefox",
    url: "",
    kiosk: false,
    userAgent: false,
    width: 1920,
    height: 1080,
    background: "default",
    backgroundUrl: "",
    primaryProtocol: "webrtc",
    taskbar: "hidden",
    allowDownload: true,
    allowClipboard: false,
    allowBorder: true,
    languageChange: true,
    copyOption: "",
    pasteOption: "",
    favicon: "",
    customFavicon: "",
    customTitle: "",
    loadingText: "",
    loadingImage: "",
    loadingFooter: "",
    webrtcAttempts: 3,
    negotiateVia: "http",
    bitrate: 4000,
    codec: "h264",
    fps: 30,
    keyframe: 10,
    defaultWebrtcCategory: true,
    highPerformanceMode: true,
    coturnClient: {},
}