import WSS from "@/services/wss.js"
import { processMessage } from "@/services/comms.js"

export default {
    namespaced: true,
    state: {
        wss: null
    },
    mutations: {
        SET_WSS(state, wss) {
            state.wss = wss
        }
    },
    actions: {
        setWSS({ commit }, wss) {
            commit("SET_WSS", wss)
        },
        processMessage({}, message) {
            processMessage(message)
        },
        sendMessage({ state }, message) {
            if(state.wss && state.wss.conn && (state.wss.conn.readyState === state.wss.conn.OPEN)) {            
                state.wss.sendMessage(message)
            }
        },
        openWSS({ commit }) {
            const wss = new WSS()
            commit("SET_WSS", wss)
        }
    }
}