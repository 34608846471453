<template>
    <div class="stream-container">
        <information-overlay v-show="loading" />
        <interaction-overlay v-show="!loading"/>

        <div class="player-layer" v-if="backendReady">
            <div class="player-container"
                :class="{
                    'with-taskbar': config.taskbar !== 'hidden',
                    'with-border': config.allowBorder
                }">

                <!-- WSS Player -->
                <div class="wss-container">
                    <canvas ref="canvas"
                            id="video-canvas"
                            :class="{'active': novnc}">
                    </canvas>
                </div>

                <!-- WEBRTC Player -->
                <video ref="video"
                       v-if="isWebrtc"
                       :srcObject="webrtc.videoStream"
                       playsinline="true"
                       v-show="playWebrtc"
                       @canplaythrough="videoStateChange"
                       muted>
                </video>

                <!-- NOVNC Player -->
                <iframe ref="iframe"
                        v-if="!playWebrtc"
                        :src="novncURL">
                </iframe>

                <audio ref="audio"
                       @canplaythrough="playAudio"
                       :srcObject="webrtc.audioStream"
                       autoplay />
            </div>

            <Taskbar />
        </div>
    </div>
</template>

<script>
import {useStore, mapState} from 'vuex'
import {assignIsolator} from '@/services/isolator'
import {initWebrtc} from '@/services/webrtc'
import {WSSStream} from '@/services/wss_stream.js'

import InformationOverlay from "@/components/overlays/InformationOverlay"
import InteractionOverlay from "@/components/overlays/InteractionOverlay"
import Taskbar from "@/components/taskbar/Taskbar.vue"

export default {
    components: {
        Taskbar,
        InformationOverlay,
        InteractionOverlay
    },
    data() {
        return {
            webrtc: {},
            store: useStore(),
            loading: true,
            videoRunning: false,
            videoState: 0
        }
    },
    computed: {
        ...mapState({
            id: state => state.general.id,
            streamProtocol: state => state.general.activeStreamProtocol,
            config: state => state.config,
            isWebrtc: state => state.general.activeStreamProtocol === 'webrtc',
            isNovnc: state => state.general.activeStreamProtocol === 'novnc'
        }),
        novncURL: function() {
            console.log("Novnc url got upated")
            return `${this.store.getters['general/isolatorURL']}novnc/vnc.html`
        },
        playWebrtc: function() {
            console.log("Playwebrtc called")
            console.log("VIdeo stream is ", this.webrtc.videoStream)
            console.log("Video peer connection is ", this.webrtc.peerConnection.connectionState)
            console.log("Video ready state is ", this.videoState)
            console.log("Is webrtc ", this.isWebrtc)
            if( !this.isWebrtc ||
                this.webrtc.peerConnection.connectionState !== "connected" ||
                !this.webrtc.videoStream ||
                this.videoState !== 4
            ) return false

            this.playVideo()
            console.log("Should be on webrtc now ")
            return true
        },
        playNovnc: function() {
            console.log("Will show the novnc now ")
            return this.isNovnc || !this.playWebrtc
        },
        backendReady: function() {
            const s = this.store.state
            console.log("Extension loaded is ", s.ui.extensionLoaded)
            console.log("COntinue in progerss is ", s.ui.continueInProgress)
            const ready = s.general.id &&
                   s.ui.extensionLoaded &&
                   !s.ui.continueInProgress

            return ready
        }
    },
    created() {
        this.checkStreamProtocol()
        assignIsolator()
    },
    methods: {
        checkStreamProtocol() {
            if(!this.streamProtocol) {
                let protocol = this.config.primaryProtocol
                this.store.dispatch("general/setActiveStreamProtocol", protocol)
            }
        },
        startStream() {
            if(!this.id) return

            if(this.isWebrtc) {
                this.webrtc = initWebrtc()
            } else if(this.isNovnc) {
                WSSStream.startStream(false, true)
            }
        },
        playVideo() {
            const v = this.$refs.video;

            if(!v.paused && v.currentTime > 0 && !v.ended) {
                this.videoRunning = true
            } else if(v.currentTime == 0 && !v.ended && !v.paused) {
                setTimeout(() => {
                    this.playVideo()
                }, 100)
            } else {
                try {
                    v.play().catch(err => console.log("Err playing video", err))
                } catch(e) {
                    console.log("Catched error on video play ", e)
                }
            }
        },
        playAudio() {
            const a = this.$refs.audio

            if(a.currentTime === 0) {
                try {
                    a.play()
                } catch(e) {
                    console.log("Error playing audio", e)
                }
            }
        },
        videoStateChange() {
            this.videoState = this.$refs.video.readyState
            console.log("Video state is ", this.videoState)
        }
    },
    watch: {
        id: {
            handler() {
                this.startStream()
            },
            immediate: true
        },
        isWebrtc: {
            handler() {
                this.startStream()
            }
        },
        isNovnc: {
            handler() {
                this.startStream() 
            }
        },
        backendReady: {
            handler() {
                if(this.backendReady) {
                    console.log("Backend is ready but we will wait a bit")
                    setTimeout(() => {
                        console.log("Will stop waiting")
                        this.loading = false
                    }, 2000)
                }
            },
            immediate: true
        }
    }
}
</script>