import store from "@/store"
import Sweetalert from "@/services/sweetalert.js"

export async function sendPaste (event) {
    const message = {
        type: "clipboard",
        body: {
            paste: true
        }
    }

    if (store.state.config.pasteOption) {
        if (event.clipboardData) {
            message.body.data = event.clipboardData.getData("Text")
        } else {
            message.body.data = await navigator.clipboard.readText()
        }
    }
    store.dispatch("ui/showClipboardMessage", "Pasted")
    store.dispatch("comms/sendMessage", message)
}

export function sendCopy () {
    const message = {
        type: "clipboard",
        body: {
            copy: true
        }
    }
    store.dispatch("ui/showClipboardMessage", "Copied")
    store.dispatch("comms/sendMessage", message)
}

export function processPaste (message) {
    if (message.clipboard) {
        store.dispatch("ui/showClipboardMessage", "Pasted")
    }
}

export function promptCopy (message) {
    if (store.state.config.copyOption) {
        if (message.clipboard) {
            Sweetalert.confirm("Do you want to copy the selected area from Cloudpurge browser to your computer", "Yes, copy", "No", () => copyToClipboard(message.clipboard), () => { });
        }
    } else {
        if (message.clipboard) {
            store.dispatch("ui/showClipboardMessage", "Copied")
        }
    }
}

function copyToClipboard (clipboard) {
    navigator.clipboard.writeText(clipboard)
    store.dispatch("ui/showClipboardMessage", "Copied")
}