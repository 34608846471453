import { createApp } from "vue";
import store from "./store";
import utils from "@/services/utils.js"
import { filters } from "./services/filters";

// import veProgress from "vue-ellipse-progress";

import App from "./App.vue";

import "@/assets/scss/main.scss"

const app = createApp(App)

store.subscribe((mutation, state) => {
    if (mutation.type.includes("streaminfo")) {
        localStorage.setItem('cp-streaminfo', JSON.stringify(state.streaminfo))
    }
})

app.use(store)
// app.component('veProgress', veProgress)

app.config.globalProperties.$isMobile = utils.isMobile()
app.config.globalProperties.$isMac = utils.isMac()
app.config.globalProperties.$filters = filters

app.mount("#app");