<template>
    <div class="three-dots-container">
        <span>.</span><span>.</span><span>.</span>
    </div>    
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
.three-dots-container {
    font-size: inherit;
    color: inherit;

    span:first-of-type {
        animation-name: blink;
        animation-duration: 1s;
        animation-delay: 0;
        animation-iteration-count: infinite;
    }

    span:nth-of-type(2) {
        animation-name: blink;
        animation-duration: 1s;
        animation-delay: .2s;
        animation-iteration-count: infinite;
    }

    span:last-of-type {
        animation-name: blink;
        animation-duration: 1s;
        animation-delay: .4s;
        animation-iteration-count: infinite;
    }

    @keyframes blink {
        0% {
            opacity: .4;
        }

        100% {
            opacity: 1;
        }
    }
}
</style>