import * as consts from "@/assets/js/mouse"
import store from "@/store"

export default class Mouse {
    constructor() {
        this.scroll = { ...consts.scrollModel }
        this.x = null
        this.y = null
        this.lastTouch = null
        this.lastMove = null
        this.lastScroll
        this.moved = false
        this.started = false
        this.clicked = false
    }

    mouseDown(event) {
        this.moved = false;
        if (event.which === consts.leftClick) {
            this.clicked = true
        }
    }

    mouseMove(event, force = false) {
        if (this.lastMove === null) {
            this.lastMove = new Date().getTime()
        }
        this.moved = true

        if (this.clicked && !this.started) {
            this.handleInteraction(event, "drag_start")
            this.started = true
        }

        const curTime = new Date().getTime()
        if ((store.state.config.highPerformanceMode && curTime - this.lastMove > consts.highPerformanceMouseDelay) || curTime - this.lastMove > consts.normalMouseDelay || force) {
            this.lastMove = curTime;
            this.handleInteraction(event, "move")
        }
    }

    mouseUp(event) {
        this.clicked = false

        if (event.which === consts.leftClick) {
            if (this.moved) {
                this.handleInteraction(event, "drag_end")
                this.moved = false;
                this.started = false;
            } else {
                this.handleInteraction(event, "left")
            }
        }
    }

    contextmenu(event) {
        event.preventDefault()
        this.handleInteraction(event, "right")
    }

    wheel(event) {
        if (event.shiftKey || event.deltaX !== 0) {
            this.checkScroll("x", false)
        } else {
            this.checkScroll("y", false)
        }
    }

    touchHandler(event) {
        const current = event.touches[0]
        let distanceY = 0
        let distanceX = 0

        if (this.lastTouch && current && current.clientY) {
            distanceY = Math.abs(Math.round(this.lastTouch.clientY - current.clientY))
        }
        if (this.lastTouch && current && current.clientX) {
            distanceX = Math.abs(Math.round(this.lastTouch.clientX - current.clientX))
        }

        const axis = distanceX > distanceY ? "x" : "y"

        switch (event.type) {
            case "touchstart":
                this.lastTouch = current;
                this.mouseMove(event, true);
                this.mouseDown(event);
                break;
            case "touchmove":
                this.checkScroll(axis, true)
                break;
            case "touchend":
                this.mouseUp(event);
                this.lastTouch = null
                break;
            default:
                return;
        }
    }

    handleInteraction(event, type) {
        this.x = this.setMouseCoordinates(event, true)
        this.y = this.setMouseCoordinates(event, false)
        this.sendInput(type)
    }

    setMouseCoordinates(event, isX) {
        let current;
        let percent;
        let absolute;
        let touches

        const el = store.state.ui.interactionEl

        if(event.touches && event.touches.length !== 0) {
            touches = event.touches[0]
        } else if(event.changedTouches && event.changedTouches.length !== 0) {
            touches = event.changedTouches[0]
        }
        const BoundingRect = el.getBoundingClientRect();

        if (isX) {
            let clientX
            if (event.clientX !== null && event.clientX !== undefined) {
                clientX = event.clientX
            } else {
                clientX = touches.clientX
            }
            current = clientX - el.offsetLeft - BoundingRect.x;
            percent = current / BoundingRect.width;

            absolute = el.offsetWidth * percent;
            return absolute;
        } else {
            let clientY
            if (event.clientY !== null && event.clientY !== undefined) {
                clientY = event.clientY
            } else {
                clientY = touches.clientY
            }
            current = clientY - el.offsetTop - BoundingRect.y;
            percent = current / BoundingRect.height;

            absolute = el.offsetHeight * percent;
            return absolute;
        }
    }

    sendInput(type) {
        const message = {
            type: "mouse",
            body: {
                x: this.x,
                y: this.y,
                type
            }
        }
        store.dispatch("comms/sendMessage", message)
    }

    checkScroll(axis, touch) {
        if (!this.scroll[axis].scrollInterval) {
            this.scroll[axis].scrollInterval = setInterval(() => {
                this.triggerScroll(axis, touch)
            }, consts.scrollCheckInterval)
        }
    }

    resetScrollPosition(axis) {
        if (this.scroll[axis].resetRetry > consts.maxScrollResetRetries) {
            this.scroll[axis].resetRetry = 0
            return
        }

        const scrollEl = store.state.ui.interactionEl
        const rect = scrollEl.children[0].getBoundingClientRect()
        if (rect.width === 0 || rect.height === 0) {
            setTimeout(() => {
                this.scroll[axis].resetRetry++
                this.resetScrollPosition(axis)
            }, 50)
            return
        }

        if (axis === "y") {
            scrollEl.scrollTop = rect.height / 2
            this.scroll[axis].lastSent = rect.height / 2
        } else if (axis === "x") {
            scrollEl.scrollLeft = rect.width / 2
            this.scroll[axis].lastSent = rect.width / 2
        }
        store.dispatch("ui/setInteractionEl", scrollEl)
        this.scroll[axis].resetRetry = 0
    }

    triggerScroll(axis, touch) {
        const e = store.state.ui.interactionEl
        const msg = {
          type: 'mouse',
          body: {
            x: 0,
            y: 0,
            type: 'scroll',
            touch
          }
        }
        let dist = 0
    
        if(axis === "y") {
          dist = e.scrollTop - this.scroll.y.lastSent
          if(Math.abs(dist) > consts.minDistToCountScroll) {
            if(this.scroll.y.resetTimeout) {
              clearTimeout(this.scroll.y.resetTimeout)
              this.scroll.y.resetTimeout = null
            }
    
            this.scroll.y.resetTimeout = setTimeout(() => {
              clearInterval(this.scroll.y.scrollInterval)
              this.scroll.y.scrollInterval = null
            }, consts.scrollResetTime)
            
            this.scroll.y.lastSent = e.scrollTop
            msg.body.y = dist
            if(!this.scroll.y.sendTimeout) {
              this.scroll.y.sendTimeout = setTimeout(() => {
                this.scroll.y.sendTimeout = null;
                store.dispatch("comms/sendMessage", msg)
              }, consts.scrollSendDelay)
            }
            if(e.scrollTop < 100 || (e.offsetHeight + e.scrollTop >= e.scrollHeight)) {
              this.resetScrollPosition(axis)
            }
          }
        } else {
          dist = e.scrollLeft - this.scroll.x.lastSent
    
          if(Math.abs(dist) > consts.minDistToCountScroll) {
            if(this.scroll.x.resetTimeout) {
              clearTimeout(this.scroll.x.resetTimeout)
              this.scroll.x.resetTimeout = null
            }
    
            this.scroll.x.resetTimeout = setTimeout(() => {
              clearInterval(this.scroll.x.scrollInterval)
              this.scroll.x.scrollInterval = null
            }, consts.scrollResetTime)
    
            this.scroll.x.lastSent = e.scrollLeft
            msg.body.x = dist
            if(!this.scroll.x.sendTimeout) {
              this.scroll.x.sendTimeout = setTimeout(() => {
                this.scroll.x.sendTimeout = null;
                store.dispatch("comms/sendMessage", msg)
              }, consts.scrollSendDelay)
            }
          }
          if(e.scrollLeft < 100 || (e.offsetWidth + e.scrollLeft >= e.scrollWidth)) {
            this.resetScrollPosition(axis)
          }
        }
    }
}