<template>
  <div class="upload-popup">
    <div class="upload__header">
      File Upload
      <close-x-svg @click="close" />
    </div>

    <div class="upload__body">
      <div
        class="upload__container"
        v-show="!isMobile && files.length === 0"
        @dragenter="dragEnter()"
        @dragleave="dragLeave()"
        :class="{ drag__enter: drag }"
      >
        <upload-cloud-svg v-if="files.length === 0" />

        <p class="drag_n_drop">
          Drag and drop to upload, or click to select
          {{ files ? "another" : "a" }} file
        </p>
        <p class="security__info">
          (For security reasons we'll first need to check your file, then you
          can upload it to the desired site.)
        </p>

        <input
          type="file"
          ref="fileUpload"
          @change="fileUploaded"
          name="filetoupload"
          multiple="multiple"
        />
      </div>

      <div
        v-show="files.length > 0"
        id="uploads__holder"
        ref="uploadsHolder"
        class="uploads__holder"
        @dragenter="handleDrag('dragenter')"
        @dragover="handleDrag('dragover')"
        @dragleave="handleDrag('dragleave')"
        @drop="handleDrop"
      >
        <div class="uploaded__file pushed__file">
          <file-icon-svg class="file__svg" />

          <p>
            {{ files.length + (files.length === 1 ? "file" : "files") }} |
            {{ calculateSize(size) }} total
          </p>
          <!-- <progress v-if="queue.len" :value="progressValue" :max="progressMax"></progress> -->
          <span @click="clearAll" v-if="uploads.inProgress === 0"
            >Clear all</span
          >
        </div>

        <div class="uploaded__files uploaded__files__show">
          <div
            v-for="(file, index) in files"
            class="file"
            :key="index"
            :class="{ deleted__file: file.deleted }"
            :title="file.content.name"
          >
            <close-circle-svg
              @click="removeFile(index)"
              v-if="file.status === status.inactive"
            />
            <div
              class="thumbnail"
              :class="{ 'thumbnail-success': file.progressPercent === 100 }"
              v-if="file.progressPercent === 0"
            >
              <img
                :class="file.type"
                :src="file.src"
                v-if="file.type === 'image'"
              />
              <video-file-svg v-else-if="file.type === 'video'" />
              <file-icon-svg v-else />
            </div>
            <ve-progress
              :color="file.progressPercent < 100 ? '#2EA3FF' : '#39DB80'"
              :progress="file.progressPercent"
              v-else-if="!file.hideLoader"
              :size="110"
            >
              <p class="uploaded-percentage" v-if="file.progressPercent < 100">
                {{ file.progressPercent }}
              </p>
              <p class="uploaded-msg" v-else-if="file.progressPercent === 100">
                Uploaded
              </p>
            </ve-progress>
            <p class="file-description">{{ file.content.name }}</p>
            <p class="file-description">
              ( {{ calculateSize(file.content.size) }} )
            </p>
          </div>

          <div class="file upload__circle" v-if="!uploads.inProgress">
            <upload-circle-svg />
            <input
              type="file"
              ref="fileUpload1"
              @change="fileUploaded"
              name="filetoupload"
              multiple="multiple"
            />
            <p>Add more</p>
          </div>

          <p v-show="error" class="error__label">{{ error }}</p>
        </div>
      </div>

      <div class="btn-holder" v-if="!isMobile">
        <button
          v-if="files.length === 0 || uploads.inProgress === 0"
          @click="processQueue"
        >
          Confirm upload
        </button>
        <button @click="close">Hide</button>
        <button @click="cancel" v-if="uploads.inProgress > 0">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore, mapState } from "vuex";

import FileIconSvg from "@/components/svg/FileIconSvg.vue";
import UploadCircleSvg from "@/components/svg/UploadCircleSvg.vue";
import UploadCloudSvg from "@/components/svg/UploadCloudSvg.vue";
import CloseCircleSvg from "@/components/svg/CloseCircleSvg.vue";
import CloseXSvg from "@/components/svg/CloseXSvg.vue";
import VideoFileSvg from "@/components/svg/VideoFileSvg.vue";

import { processFiles, statuses } from "@/services/uploads";
import Sweetalert from "@/services/sweetalert";

export default {
  components: {
    FileIconSvg,
    UploadCircleSvg,
    UploadCloudSvg,
    CloseCircleSvg,
    VideoFileSvg,
    CloseXSvg,
  },
  data() {
    return {
      store: useStore(),
      drag: false,
      error: null,
      progressValue: 0,
      progressMax: 0,
      filesList: [],
      queueIndex: 0,
      isMobile: false,
      status: { ...statuses },
    };
  },
  computed: {
    ...mapState({
      uploads: (state) => state.uploads,
      files: (state) => state.uploads.queue,
      size: (state) => state.uploads.queueSize,
    }),
  },
  methods: {
    close() {
      this.$emit("close");
    },
    cancel() {
      Sweetalert.confirm(
        "This action will result with uploads in progress. Proceed ?",
        "Yes, cancel uploads",
        "Back",
        this.confirmCancel,
        this.close
      );
    },
    confirmCancel() {
      this.files.map((file) => file.ajax.abort());
    },
    clearAll() {
      this.store.dispatch("uploads/updateQueue", []);
      this.filesList = [];
      console.log("Files list will be cleared");
    },
    dragEnter() {
      this.drag = true;
    },
    dragLeave() {
      this.drag = false;
    },
    handleDrop(e) {
      this.preventDefaults(e);
      const dt = e.dataTransfer;
      this.appendFilesList(dt.files);
      this.confirmFiles();
    },
    handleDrag(e) {
      this.$refs.uploadsHolder.addEventtListener(
        e,
        this.preventDefaults,
        false
      );
    },
    fileUploaded(e) {
      this.appendFilesList(e.target.files);
    },
    appendFilesList(files) {
      for (const key in files) {
        if (typeof files[key] === "object") {
          this.filesList.push(files[key]);
        }
      }
    },
    removeFile(index) {
      const files = [...this.filesList];
      files[index].deleted = true;
      this.filesList = [...files];

      setTimeout(() => {
        this.filesList.splice(index, 1);
      }, 250);
    },
    processQueue() {
      this.store.dispatch("uploads/processQueue");
    },
    confirmFiles() {
      let files = processFiles(this.filesList);
      console.log("Will set queue to be ", files);
      this.store.dispatch("uploads/updateQueue", files);
      this.$refs.fileUpload.value = null;
      this.$refs.fileUpload1.value = null;
    },
    preventDefaults(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    calculateSize(size) {
      if (size / 1024 / 1024 < 1) {
        return (size / 1024).toFixed(2) + "KB";
      } else {
        return (size / 1024 / 1024).toFixed(2) + "MB";
      }
    },
  },
  watch: {
    filesList: {
      handler() {
        console.log("Files list changed");
        if (this.filesList.length > 0) {
          this.confirmFiles();
        }
      },
      deep: true,
    },
    files: {
      handler() {
        if (this.files.length === 0) {
          this.filesList = [];
          this.close();
        }
      },
    },
    deep: true,
  },
};
</script>