<template>
    <div id="btn-copy" class="taskbar-btn"  @click="copy" title="Copy">
        <svg xmlns="http://www.w3.org/2000/svg" height="512px" viewBox="-21 0 512 512" width="512px">
            <g>
                <path d="m410.667969 405.332031h-245.335938c-32.363281 0-58.664062-26.300781-58.664062-58.664062v-288c0-32.363281 26.300781-58.667969 58.664062-58.667969h181.503907c21.058593 0 41.6875 8.535156 56.554687 23.445312l42.496094 42.496094c15.125 15.125 23.445312 35.222656 23.445312 56.574219v224.152344c0 32.363281-26.300781 58.664062-58.664062 58.664062zm-245.335938-373.332031c-14.699219 0-26.664062 11.96875-26.664062 26.667969v288c0 14.699219 11.964843 26.664062 26.664062 26.664062h245.335938c14.699219 0 26.664062-11.964843 26.664062-26.664062v-224.152344c0-12.820313-4.992187-24.871094-14.058593-33.941406l-42.496094-42.496094c-8.9375-8.957031-21.289063-14.078125-33.941406-14.078125zm0 0" data-original="#000000" class="active-path" data-old_color="#000000" fill="#5f6368"/>
                <path d="m314.667969 512h-256c-32.363281 0-58.667969-26.304688-58.667969-58.667969v-341.332031c0-32.363281 26.304688-58.667969 58.667969-58.667969h10.664062c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16h-10.664062c-14.699219 0-26.667969 11.96875-26.667969 26.667969v341.332031c0 14.699219 11.96875 26.667969 26.667969 26.667969h256c14.699219 0 26.664062-11.96875 26.664062-26.667969v-10.664062c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v10.664062c0 32.363281-26.300781 58.667969-58.664062 58.667969zm0 0" data-original="#000000" class="active-path" data-old_color="#000000" fill="#5f6368"/>
                <path d="m368 181.332031h-160c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h160c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0" data-original="#000000" class="active-path" data-old_color="#000000" fill="#5f6368"/>
                <path d="m368 245.332031h-160c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h160c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0" data-original="#000000" class="active-path" data-old_color="#000000" fill="#5f6368"/>
                <path d="m368 309.332031h-160c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h160c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0" data-original="#000000" class="active-path" data-old_color="#000000" fill="#5f6368"/>
            </g>
        </svg>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { sendCopy } from "@/services/clipboard"

export default {
    data() {
        return {
            store: useStore()
        }
    },
    methods: {
        copy(event) {
            sendCopy(event)
        }
    }
}
</script>