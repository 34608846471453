<template>
    <svg id="file-icon" height="512" viewBox="0 0 128 128" width="512" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="m107.812 14.836h-8.53v-8.529a1.749 1.749 0 0 0 -1.75-1.75h-56.585a1.7 1.7 0 0 0 -.337.034h-.01a1.758 1.758 0 0 0 -.433.154c-.03.015-.058.031-.088.048a1.754 1.754 0 0 0 -.377.279l-20.749 20.755a1.708 1.708 0 0 0 -.278.374c-.018.031-.035.061-.051.093a1.793 1.793 0 0 0 -.152.427v.023a1.715 1.715 0 0 0 -.033.322v84.348a1.75 1.75 0 0 0 1.75 1.75h8.531v8.529a1.749 1.749 0 0 0 1.75 1.75h77.344a1.749 1.749 0 0 0 1.75-1.75v-105.107a1.749 1.749 0 0 0 -1.752-1.75zm-68.612-4.305v14.785h-14.788zm-17.263 18.285h19.01a1.749 1.749 0 0 0 1.75-1.75v-2.713h38.913a1.75 1.75 0 0 0 0-3.5h-38.91v-12.796h53.082v101.607h-73.845zm84.125 91.127h-73.844v-6.779h65.314a1.749 1.749 0 0 0 1.75-1.75v-93.078h6.78z"/>
            <path d="m36.109 48.524h45.5a1.75 1.75 0 1 0 0-3.5h-45.5a1.75 1.75 0 0 0 0 3.5z"/>
            <path d="m36.109 60.61h45.5a1.75 1.75 0 0 0 0-3.5h-45.5a1.75 1.75 0 1 0 0 3.5z"/>
            <path d="m36.109 72.7h45.5a1.75 1.75 0 0 0 0-3.5h-45.5a1.75 1.75 0 0 0 0 3.5z"/>
            <path d="m36.109 84.781h45.5a1.75 1.75 0 1 0 0-3.5h-45.5a1.75 1.75 0 0 0 0 3.5z"/>
            <path d="m36.109 96.867h45.5a1.75 1.75 0 0 0 0-3.5h-45.5a1.75 1.75 0 1 0 0 3.5z"/>
            <path d="m36.109 36.438h45.5a1.75 1.75 0 0 0 0-3.5h-45.5a1.75 1.75 0 1 0 0 3.5z"/>
        </g>
    </svg>
</template>

<script>
export default {
    
}
</script>