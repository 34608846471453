<template>
    <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">
        <defs></defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
            <g id="icon-128-document-scan" sketch:type="MSArtboardGroup" fill="#000000">
                <path d="M22,29 L20,29 L20,29 L20,28 L22,28 L22,29 L22,29 L22,29 Z M23,29 L24.0025781,29 C25.1057238,29 26,28.1017876 26,27.0092049 L26,26 L26,26 L25,26 L25,27.0066023 C25,27.5550537 24.5523026,28 24.0000398,28 L23,28 L23,29 L23,29 L23,29 Z M19,29 L17,29 L17,29 L17,28 L19,28 L19,29 L19,29 L19,29 Z M16,29 L14,29 L14,29 L14,28 L16,28 L16,29 L16,29 L16,29 Z M13,29 L11,29 L11,29 L11,28 L13,28 L13,29 L13,29 Z M10,29 L8.99742191,29 C7.89092539,29 7,28.1012878 7,26.9926701 L7,26 L8,26 L8,27.004543 C8,27.5543187 8.45470893,28 8.9999602,28 L10,28 L10,29 L10,29 Z M26,25 L26,23 L26,23 L25,23 L25,25 L26,25 L26,25 L26,25 Z M7,25 L7,23 L8,23 L8,25 L7,25 L7,25 L7,25 Z M26,22 L26,20 L26,20 L25,20 L25,22 L26,22 L26,22 L26,22 Z M7,22 L7,20 L8,20 L8,22 L7,22 L7,22 L7,22 Z M27,18 L27,16 L28,16 L28,21 L27,21 L27,19 L6,19 L6,21 L5,21 L5,16 L6,16 L6,18 L27,18 L27,18 Z M26,17 L26,9 L26,9 L20,2 L9.00276013,2 C7.89666625,2 7,2.89833832 7,4.00732994 L7,17 L8,17 L8,3.99545703 C8,3.45526288 8.44573523,3 8.9955775,3 L19,3 L19,7.99408095 C19,9.11344516 19.8944962,10 20.9979131,10 L25,10 L25,17 L26,17 L26,17 L26,17 Z M20,3.5 L20,7.99121523 C20,8.54835167 20.4506511,9 20.9967388,9 L24.6999512,9 L20,3.5 L20,3.5 Z" id="document-scan" sketch:type="MSShapeGroup"></path>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    
}
</script>