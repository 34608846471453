import { createLogger, createStore } from "vuex";
import createPersistedState from "vuex-persistedstate"

import config from "@/store/modules/config.js"
import ui from "@/store/modules/ui.js"
import general from "@/store/modules/general.js"
import downloads from "@/store/modules/downloads.js"
import uploads from "@/store/modules/uploads.js"
import comms from "@/store/modules/comms.js"
import streaminfo from "@/store/modules/streaminfo.js"


export default createStore({
  plugins: [
    createLogger({
      actionFilter (action) {
        return action.type !== "comms/sendMessage" && action.type !== "downloads/updateFile"
      },
      logMutations: false,
      logActions: false
    }),
    createPersistedState({
      storage: window.sessionStorage,
      paths: ['config', 'general']
    })
  ],
  state: {},
  mutations: {},
  actions: {
    resetState ({ dispatch }) {
      dispatch('ui/reset', false, { root: true })
      dispatch('downloads/reset', false, { root: true })
      dispatch('uploads/reset', false, { root: true })
    }
  },
  modules: { config, ui, general, uploads, downloads, comms, streaminfo },
});
