<template>
    <svg id="malicious-shield" x="0px" y="0px" viewBox="0 0 304.761 304.761">
        <g id="g9">
          <g id="Layer_5_88_">
            <g id="g6">
              <title>Malicious file</title>
              <path fill="#ff0033" d="M168.493,241.462c0-40.269,32.644-72.911,72.911-72.911c8.476,0,13.96,1.012,19.709,2.711     c3.871,1.641,5.352-1.592,5.756-3.1c2.634-9.839,4.111-19.873,4.111-29.982c0-0.677-0.378-86.735-0.378-87.798     c0-8.487-4.863-8.467-7.034-8.456c-15.578,0.074-31.457,0.081-48.161-4.734C180.703,27.186,149.85,0,138.121,0     c-11.361,0-34.269,27.187-68.974,37.192c-19.57,5.642-38.337,4.595-55.894,3.781c-2.063-0.095-5.347-0.642-6.389,6.508     c-0.263,1.802-1.602,89.731-1.602,90.699c0,85.898,106.271,166.581,132.857,166.581c8.507,0,22.214-6.832,40.662-20.561     c2.458-2.123,1.141-3.531,0.414-4.717C172.413,268.413,168.493,255.398,168.493,241.462z" id="path2" />
              <path fill="#ff0033" d="m 253.1865,237.30444 25.97963,-24.09725 c 2.87048,-2.6625 2.87048,-6.97907 0,-9.63741 l -3.46341,-3.21246 c -2.87138,-2.66333 -7.52514,-2.66333 -10.39114,0 l -25.97872,24.09642 -25.97963,-24.10142 c -2.87048,-2.66249 -7.52425,-2.66249 -10.39024,0 l -3.46879,3.21247 c -2.87048,2.66333 -2.87048,6.9799 0,9.63824 l 25.98411,24.10141 -25.97873,24.09642 c -2.87138,2.66333 -2.87138,6.9799 0,9.63823 l 3.46341,3.21247 c 2.87048,2.6625 7.52425,2.6625 10.39024,0 l 25.97963,-24.09725 25.97872,24.09725 c 2.87138,2.6625 7.52514,2.6625 10.39114,0 l 3.46341,-3.21247 c 2.87048,-2.66332 2.87048,-6.9799 0,-9.63823 z m 0,0" id="path2-8" style="stroke-width:0.221162" />
            </g>
          </g>
        </g>
    </svg>
</template>

<script>
export default {
    
}
</script>