// import store from "@/store"

export function overrideConsole () {
    if (!window.console) {
        setTimeout(() => {
            overrideConsole()
        }, 500)
    }

    // const debug = store.state.config.debug
    // if (!debug) {
    //     console.log = function () { }
    //     console.info = function () { }
    //     console.warn = function () { }
    //     console.error = function () { }
    //     console.table = function () { }
    // }
}