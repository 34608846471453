<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="510" height="340" viewBox="0 0 510 340">
        <defs>
            <path id="a" d="M1 237h507v80H1z"/>
            <path id="c" d="M314.702 2.787C193.339.072 92.028-1.342 81.575 8.881c-1.002.979-1.212 1.972-1.212 2.633 0 9.72 19.639 13.912 40.431 18.349 17.347 3.702 37.007 7.899 37.574 14.803.073.893-.294 1.765-1.124 2.664C144.858 60.743 52.001 64.415.196 64.542v2.991c26.697-.124 143.967-1.614 159.252-18.168 1.411-1.528 2.053-3.192 1.91-4.945-.749-9.127-19.784-13.19-39.937-17.49-17.84-3.808-38.058-8.124-38.058-15.416 0-.167.216-.398.309-.489 3.069-3.002 19.943-6.686 82.895-7.194 44.577-.356 99.557.871 148.067 1.955 58.957 1.319 105.528 2.36 112.679.112l-.901-2.861c-6.678 2.098-55.352 1.01-111.71-.25z"/>
            <path id="d" d="M-80.755 25.223c-.488-.495-.729-.958-.717-1.376.158-5.212 15.472-8.023 26.654-10.075 12.512-2.296 21.552-3.955 21.346-8.707-.114-2.63-.226-6.242-114.128-5.959C-203.178-.76-259.438.324-260 .335l.059 3C-174.96 1.679-46.949.961-36.605 5.624c-1.332 2.001-12.126 3.981-18.754 5.198-14.199 2.605-28.879 5.299-29.111 12.934-.039 1.269.492 2.472 1.579 3.574 9.267 9.398 65.334 13.204 107.838 13.203 2.94 0 5.792-.023 8.582-.058v-2.996c-49.121.653-106.204-4.061-114.284-12.256z"/>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <path fill="#D6D5D0" d="M273.798 48.971h-1.588v7.504h1.588a3.763 3.763 0 0 0 3.753-3.751 3.763 3.763 0 0 0-3.753-3.753"/>
            <path fill="#FFFFFE" d="M310.875 47.881c0 21.055-17.066 38.121-38.12 38.121-21.055 0-38.121-17.066-38.121-38.12 0-21.055 17.066-38.121 38.12-38.121 21.055 0 38.121 17.066 38.121 38.12"/>
            <path fill="#D7EDF2" d="M304.936 48.18c0 18.511-15.006 33.517-33.516 33.517-18.513 0-33.519-15.006-33.519-33.517 0-18.51 15.006-33.518 33.519-33.518 18.51 0 33.516 15.008 33.516 33.518"/>
            <path fill="#43A2CA" d="M271.665 12.573c-19.77 0-35.853 16.082-35.853 35.852 0 19.769 16.082 35.853 35.853 35.853 19.768 0 35.853-16.084 35.853-35.853 0-19.77-16.085-35.852-35.853-35.852zm0 74.517C250.345 87.09 233 69.744 233 48.425c0-21.318 17.346-38.664 38.665-38.664s38.664 17.346 38.664 38.664c0 21.32-17.345 38.665-38.664 38.665z"/>
            <path fill="#43A2CA" d="M273.78 48.94a1.602 1.602 0 1 1-3.203 0 1.602 1.602 0 0 1 3.203 0M273.78 21.165a1.602 1.602 0 1 1-3.204.001 1.602 1.602 0 0 1 3.204-.001M301.554 48.938a1.602 1.602 0 1 1-3.204.002 1.602 1.602 0 0 1 3.204-.002M273.78 78.346a1.602 1.602 0 1 1-3.204.002 1.602 1.602 0 0 1 3.204-.002"/>
            <path fill="#43A2CA" d="M294.293 48.911c0 .226-.226.412-.504.412l-20.783-.002c-.278 0-.506-.184-.506-.41 0-.226.228-.411.506-.411h20.783c.278 0 .504.185.504.411"/>
            <path fill="#43A2CA" d="M292.25 29.51a.491.491 0 0 1 0 .697l-19.199 19.2a.496.496 0 0 1-.697 0 .496.496 0 0 1 0-.698l19.198-19.198a.493.493 0 0 1 .698 0M245.71 48.938a1.602 1.602 0 1 1-3.204.002 1.602 1.602 0 0 1 3.204-.002"/>
            <path fill="#ECB996" d="M210.721 184c-1.231-.015-2.212-.994-2.205-2.182.188-32.553-5.532-61.521-7.433-68.079-.322-1.112.32-2.266 1.458-2.626l.011-.004c1.21-.383 2.503.275 2.851 1.456 2.016 6.839 7.791 35.883 7.592 69.286-.008 1.197-1.032 2.165-2.274 2.149"/>
            <path fill="#ECB996" d="M209.69 185.96c.52.143 1.085-.104 1.254-.548l2.008-5.304c.168-.443-.12-.924-.642-1.067-.52-.144-1.085.103-1.253.547l-2.009 5.304c-.168.444.12.924.642 1.067"/>
            <path fill="#ECB996" d="M208.86 186.991c.55.067 1.066-.245 1.148-.693l.98-5.352c.084-.448-.3-.87-.849-.937-.55-.067-1.065.245-1.147.693l-.98 5.352c-.083.448.3.87.848.937M208.682 178.996c-.414-.044-.719-.448-.678-.896l.488-5.36c.041-.45.413-.78.826-.736.414.045.719.448.678.896l-.488 5.36c-.041.45-.413.78-.826.736M156.075 179.922c-.68-37.064 3.448-61.525 5.166-67.395.35-1.192 1.668-1.829 2.856-1.386l1.903.712-2.021-.756.126.048c1.044.387 1.619 1.48 1.317 2.53-1.56 5.423-5.683 29.707-5.019 66.167.022 1.162-.921 2.131-2.113 2.157-1.199.027-2.193-.908-2.215-2.077"/>
            <path fill="#ECB996" d="M159.31 185.96c-.521.143-1.084-.104-1.253-.548l-2.01-5.303c-.167-.444.121-.925.642-1.068.523-.143 1.086.102 1.254.547l2.009 5.304c.168.444-.12.924-.642 1.067"/>
            <path fill="#ECB996" d="M160.14 185.991c-.55.067-1.066-.245-1.148-.693l-.98-5.353c-.083-.448.299-.869.848-.936.55-.068 1.066.245 1.148.693l.98 5.352c.083.448-.299.87-.848.937M161.376 178.974a.83.83 0 0 1-1.007-.604l-1.343-5.327a.84.84 0 0 1 .598-1.017.83.83 0 0 1 1.007.604l1.343 5.327a.84.84 0 0 1-.598 1.017"/>
            <path fill="#E5E5E0" d="M208.783 188l-.02-.001c.386-.011.723-.362.782-.842l.024-.198a.307.307 0 0 0 .037.021l-.022.177c-.06.49-.408.843-.8.843m1.2-.927h-.02c.335-.01.645-.276.753-.685l1.248-4.724.035.014-1.244 4.71c-.11.417-.43.685-.772.685m-1.966-7.563H208c.29-.009.551-.21.688-.51v.073c-.148.266-.396.437-.67.437"/>
            <path fill="#DDAE8E" d="M210.595 182l.166-1.17c.071-.5-.258-.969-.73-1.043a.876.876 0 0 0-.128-.01c-.4 0-.756.296-.845.715-.014-.098-.02-.2-.02-.3.002-.325 0-.646 0-.97a1.014 1.014 0 0 1-.038.066v-.068a.949.949 0 0 0 .104-.353l.081-.867c.27.104.542.2.817.29.099.888.43 1.736.998 2.386l-.405 1.324M210.495 187a1.117 1.117 0 0 1-.285-.044l-.159-.08-.006.039a.43.43 0 0 1-.045-.02l.76-4.579.472-1.316a4.134 4.134 0 0 0 1.768 1.01l-1.563 4.359c-.135.377-.523.622-.942.631"/>
            <path fill="#DDAE8E" d="M209.002 187a1.43 1.43 0 0 1-.127-.008c-.558-.067-.947-.489-.864-.937l.986-5.296a1.301 1.301 0 0 1-.023-.117c.105-.377.526-.642 1-.642.05 0 .101.003.151.008.559.067.948.489.863.937l-.965 5.188-.031.166c-.074.4-.502.692-.99.701M208.958 180c-.025 0-.05-.002-.074-.003-.537-.04-.932-.408-.88-.816l.154-1.181c.595.243 1.21.447 1.842.623l-.092.705a.623.623 0 0 1-.117.287c-.166.226-.482.378-.833.385"/>
            <path fill="#0A0B09" d="M165 111l.119.046-.002-.001L165 111"/>
            <path fill="#E5E5E0" d="M162.201 186h-.019a.801.801 0 0 0 .77-.916l-.46-3.084a.668.668 0 0 0 .04.012l.46 3.07a.801.801 0 0 1-.79.918m-1.184-.75H161a.791.791 0 0 0 .35-.092v.004l-.09.05a.774.774 0 0 1-.242.039"/>
            <path fill="#DDAE8E" d="M156.388 181a1.836 1.836 0 0 1-.388-1l.068.054.32.946"/>
            <path fill="#DDAE8E" d="M158.603 186a.909.909 0 0 1-.842-.631l-1.356-4.238a2.135 2.135 0 0 1-.074-.095L156 180a10.63 10.63 0 0 0 2.355 1.544l.645 4.35a.89.89 0 0 1-.397.105"/>
            <path fill="#DDAE8E" d="M159.285 187c-.348-.01-.653-.313-.706-.731l-.024-.19-.033.021v-.004L158 182c.504.284 1.03.513 1.57.702l.422 3.313c.06.467-.218.907-.616.976a.584.584 0 0 1-.091.009"/>
            <path fill="#0A0B09" d="M165 111h.001l1.999.67-2-.67"/>
            <path fill="#F4F4F1" d="M228 266.5c0 4.694-19.923 8.5-44.5 8.5s-44.5-3.806-44.5-8.5c0-4.695 19.923-8.5 44.5-8.5s44.5 3.805 44.5 8.5"/>
            <path fill="#E7AE89" d="M180 112h9V97h-9z"/>
            <path fill="#ECB996" d="M198.058 176h-27.553c-4.885 0 1.17-28.703 1.17-33.004L165 111.767c0-4.3.626-5.66 5.51-5.66l27.548-.107c4.884 0 3.85 3.119 3.85 7.418l-5.918 40.077c0 4.3 6.952 22.505 2.068 22.505"/>
            <path fill="#ECBE9E" d="M217 66c0 18.225-14.775 33-33 33s-33-14.775-33-33 14.775-33 33-33 33 14.775 33 33"/>
            <path fill="#D9A481" d="M184 99.002h.012H184m.053 0h.016-.016m.054-.001h.015-.015m.055 0h.013-.013m.056 0h.008-.008m.057-.001h.004-.004"/>
            <path fill="#DDB395" d="M184.217 100c-9.955 0-18.864-4.583-24.824-11.802-.145-1.976-.279-4.05-.393-6.198 1.89 3.353 4.19 6.443 7.176 9.11 5.96 5.33 12.99 7.524 20.178 7.524 3.883 0 7.814-.64 11.646-1.774a31.72 31.72 0 0 1-13.435 3.138h-.18c-.013.002-.026.002-.04.002h-.128"/>
            <path fill="#CF6B5C" d="M191.1 75h-13.2c-.497 0-.9-.448-.9-1s.403-1 .9-1h13.2c.497 0 .9.448.9 1s-.403 1-.9 1"/>
            <path fill="#39291D" d="M205.174 63c-.38 0-.713.224-.791.543-.424 1.741-2.226 3.054-4.383 3.054s-3.958-1.313-4.382-3.054c-.078-.319-.412-.543-.792-.543-.515 0-.914.405-.81.837C194.6 66.212 197.058 68 200 68s5.402-1.788 5.983-4.163c.106-.432-.294-.837-.809-.837M174.174 63c-.38 0-.714.224-.792.543-.424 1.741-2.225 3.054-4.382 3.054-2.157 0-3.958-1.313-4.382-3.054-.078-.319-.412-.543-.792-.543-.515 0-.914.405-.81.837C163.6 66.212 166.058 68 169 68s5.4-1.788 5.983-4.163c.105-.432-.294-.837-.81-.837"/>
            <path fill="#E7AA93" d="M166 84a7 7 0 1 1 0-14 7 7 0 0 1 0 14M203 84a7 7 0 1 1 0-14 7 7 0 0 1 0 14"/>
            <path fill="#62452F" d="M187.016 21h-4.68c-46.233 0-33.073 71.636-31.142 75.35 1.933 3.713 10.057 9.65 10.057 9.65s-3.504-22.13-2.85-44.507c.02-.635.272-1.239.618-1.772.86-1.328 2.366-2.118 3.97-2.179 3.234-.121 10.702-.329 22.748-.257l20.385.111h2.16c2.925.009 2.904 2.354 2.964 5.22.39 18.608-2.21 43.384-2.21 43.384s7.528-5.937 9.46-9.65c1.932-3.714 16.263-74.54-31.48-75.35"/>
            <path fill="#E5E5E0" d="M160.91 106s-8.005-5.769-9.909-9.378c-.442-.837-1.299-4.437-2.001-9.622.728 5.255 1.605 8.87 2.001 9.622 1.904 3.61 9.91 9.378 9.91 9.378M160.905 105.97c-.084-.53-1.28-8.164-2.104-18.73.825 10.566 2.02 18.2 2.104 18.73"/>
            <path fill="#DDB395" d="M158.357 81v-.001c-.305-5.88-.46-12.327-.281-18.795-.178 6.468-.024 12.914.28 18.796"/>
            <path fill="#D0A98D" d="M159.376 88c-.139-1.913-.267-3.92-.376-6v.001c.109 2.078.237 4.086.376 5.999"/>
            <path fill="#E5E5E0" d="M150 50c2.504-11.378 7.799-21.548 18-26-10.175 4.749-15.477 14.817-18 26"/>
            <path fill="#5C422D" d="M207.798 107s2.548-25.036 2.165-43.839a22.01 22.01 0 0 0-.005-.27c-.055-2.771-.122-4.996-2.9-5.004h-2.117l-19.984-.113a832.49 832.49 0 0 0-4.76-.015c-9.044 0-14.82.17-17.541.275l-.074.004c-1.544.083-2.988.876-3.818 2.198-.302.48-.531 1.016-.59 1.58a2.75 2.75 0 0 0-.016.21c-.188 6.632-.025 13.242.298 19.272.115 2.146.251 4.22.398 6.194.82 10.988 2.01 18.925 2.094 19.477l.004.031s-7.964-5.999-9.859-9.751c-.394-.782-1.266-4.542-1.99-10.006-1.231-9.495-1.986-24.097.829-37.121 2.487-11.235 7.715-21.35 17.748-26.12l.005-.002c2.007 4.453 4.298 9.32 7.153 13.27 4.301 5.954 9.72 11.086 16.299 14.403 6.347 3.2 12.916 4.668 19.68 5.981-.077 6.475-1.475 43.648 2.917 43.648a.848.848 0 0 0 .266-.044c-2.736 2.912-6.092 5.652-6.199 5.74h-.002V107h-.001"/>
            <path fill="#D77F71" d="M200 87.196a.779.779 0 0 0-.354-.639C195.491 83.708 190.223 82 184.494 82c-5.724 0-10.987 1.705-15.14 4.548a.779.779 0 0 0-.354.639c0 .684.919 1.048 1.542.622 3.828-2.62 8.678-4.19 13.952-4.19 5.279 0 10.134 1.573 13.963 4.198.623.427 1.543.062 1.543-.621"/>
            <path fill="#BF7539" d="M179.879 267.957c-1.78.333-3.156-1.318-3.413-3.622-.659-5.842-.99-9.703 1.629-10.322 2.52-.35 2.443 6.237 3.684 9.18.399 2.219.509 4.319-1.9 4.764M187.122 267.957c1.78.333 3.155-1.318 3.412-3.622.659-5.842.99-9.703-1.628-10.322-2.521-.35-2.443 6.237-3.685 9.18-.399 2.219-.508 4.319 1.9 4.764"/>
            <path fill="#E5E5E0" d="M179.972 267c-1.552 0-2.718-1.274-2.972-3l.067.065c.271 1.682 1.416 2.917 2.933 2.934l-.028.001"/>
            <path fill="#B57037" d="M179.888 267c-1.494-.017-2.621-1.28-2.888-3l.038.038c.453.907 1.312 1.62 2.364 2.03l.29.112c.335.131.676.19 1.012.19.446 0 .884-.104 1.296-.285-.318.43-.846.744-1.693.88a2.688 2.688 0 0 1-.42.035"/>
            <path fill="#E5E5E0" d="M187.978 267c-.17 0-.342-.012-.518-.036-2.246-.305-2.604-1.52-2.417-2.964.017.039.035.077.055.115-.152 1.394.246 2.552 2.432 2.85.16.022.317.033.47.035h-.022"/>
            <path fill="#B57037" d="M187.605 267a2.81 2.81 0 0 1-.418-.037c-1.943-.309-2.297-1.513-2.161-2.963a4.373 4.373 0 0 0 1.893 2.122 3.926 3.926 0 0 0 2.081.474c-.412.256-.883.4-1.395.404"/>
            <path fill="#ECBE9E" d="M175.317 178H170c.148 16.812 1.041 52.309 5.72 80.795.239 1.448 1.627 2.417 3.08 2.165a2.65 2.65 0 0 0 2.164-3.043c-4.616-28.113-5.501-63.227-5.647-79.917M192.37 178c-.357 18.266-1.655 52.98-6.332 79.914-.25 1.438.765 2.796 2.29 3.046 1.537.252 3.009-.717 3.26-2.167 4.741-27.29 6.05-62.39 6.412-80.793h-5.63"/>
            <path fill="#DD8E2F" d="M172.786 106s-1.498 11.014 10.29 11.153c14.16.169 11.364-11.137 11.36-11.153l7.944.738s4.548-.015 6.358 10.61c1.909 11.204 4.26 33.8 4.262 33.817l-6.902.723s-2.048-17.66-4.095-28.209c-.108-.554-.936-.438-.884.125.992 10.62 2.703 32.778 3.743 67.86.236 7.98-6.315 14.528-14.341 14.332l-14.173-.348c-7.974-.195-14.175-6.96-13.625-14.871 1.615-23.237 4.532-66.221 4.01-66.41-1.325-.48-2.464 6.49-3.262 13.46-.771 6.743-1.223 13.486-1.215 13.485l-7.256-.046c0-.02.68-42.607 10.546-44.528 2.248-.438 7.24-.738 7.24-.738"/>
            <path fill="#E5E5E0" d="M163 151c.025-.444.12-1.931.274-4h.001a263.82 263.82 0 0 0-.275 4"/>
            <path fill="#D08630" d="M161.721 151l-6.721-.04c0-.005.04-2.38.244-5.96 1.29 1.583 3.087 2.686 5.298 2.699h.02c.494 0 .981-.104 1.438-.284-.153 1.792-.247 3.08-.272 3.464l-.007.121"/>
            <path fill="#E5E5E0" d="M163.034 191.037c0-.028.003-.056.005-.083a2.056 2.056 0 0 0-.005.083M167 125.019l-.003-.018V125c.002.006.002.013.003.02"/>
            <path fill="#D08630" d="M190.555 206c-.113 0-.228-.002-.343-.004l-13.858-.349c-7.477-.187-13.358-6.441-13.354-13.96a14.394 14.394 0 0 1 .039-1.055c1.535-22.675 4.366-64.244 3.952-66.466-.002-.006-.002-.013-.003-.02.752-.951.999-2.095 2.463-2.144l.09-.002c2.79 0 1.518 4.61 4.422 7.61 6.86 7.086 18.63-2.497 16.802 11.984-1.047 5.028-46.517 58.081 11.097 58.081H202c-2.445 3.805-6.658 6.325-11.445 6.325"/>
            <path fill="#E5E5E0" d="M201.075 124l-.073-.634.073.634m-.075-.671c-.001-.212.212-.329.423-.329-.211 0-.424.117-.423.329"/>
            <path fill="#D08630" d="M206.902 151s-2.106-17.503-4.211-27.957a.434.434 0 0 0-.445-.358c-.233 0-.467.154-.466.432 0 .016 0 .033.002.05l.08.833.072.764c-.573-1.35-1.25-2.656-1.934-3.958.531-.596 1.075-1.196 1.614-1.806.89.467 1.804.867 2.729 1.236 1.528 9.45 2.828 19.492 9.336 27.152.203 1.804.321 2.89.321 2.895l-7.098.717"/>
            <path fill="#ECBE9E" d="M169.67 42s-1.1 10.13-8.972 15.353c-7.871 5.223 13.746 1.082 21.438 1.211 7.692.13 15.864-1.859 15.864-1.859s-24.22.577-28.33-14.705"/>
            <mask id="b" fill="#fff">
                <use xlink:href="#a"/>
            </mask>
            <g mask="url(#b)">
                <g fill="#43A2CA" transform="translate(171 249)">
                    <use xlink:href="#c"/>
                    <use xlink:href="#d"/>
                </g>
            </g>
            <path fill="#F4F4F1" d="M476 268c0-2.761-15.222-5-34-5-18.777 0-34 2.239-34 5s15.223 5 34 5c18.778 0 34-2.239 34-5"/>
            <path fill="#85C1C5" d="M447.66 217s2.392-12.89-5.502-18c0 0-1.617 16.862 5.501 18M439.425 227s6.95-6.384 1.47-14c0 0-7.681 9.221-1.47 14"/>
            <path fill="#678D94" d="M442 248s8-25.652 8-50l-8 50"/>
            <path fill="#678D94" d="M444.874 234s-5.205-2.858-16.874-22l17 21.166-.126.834"/>
            <path fill="#85C1C5" d="M449 211.37s19.693 9.26 28-16.36c0 0-25.846-7.1-28 16.36M448 217.872s2.354 11.753 16 6.993c0 0-5.256-13.761-16-6.993"/>
            <path fill="#85C1C5" d="M449.795 200s10.691-4.473 4.007-18c0 0-12.116 7.864-4.007 18M468 229.562s-13.883-9.527-24 8.16c0 0 18.95 8.789 24-8.16M422 232.261s8.93 8.092 18-3.542c0 0-12.628-8.045-18 3.542M435.777 222.754s3.673-17.967-18.774-19.754c0 0-.713 22.59 18.774 19.754"/>
            <path fill="#A6624D" d="M458 242s-1.014 26.855-16.08 27c-15.065.144-14.92-27-14.92-27h31"/>
            <path fill="#DD8E2F" d="M429.797 267.436c.254.484.684.808 1.173.877 1.631.232 5.805.71 12.27.686 5.1-.02 9.148-.479 10.783-.695.494-.066.93-.39 1.185-.88L457 264c-4.606 0-9.212.545-13.818.545-5.06 0-10.122-.545-15.182-.545.6 1.145 1.198 2.29 1.797 3.436"/>
            <path fill="#ECB996" d="M394.341 201c-.588 6.197-1.343 15.615-2.312 22.132-.2 1.345.66 2.614 1.938 2.835 1.286.222 2.508-.704 2.71-2.059.968-6.503 1.728-15.885 2.323-22.043-1.554-.28-3.106-.578-4.659-.865"/>
            <path fill="#ECB996" d="M390.574 230.788l.288.121c.509.213 1.132.038 1.386-.39l3.644-6.144c.254-.427.043-.952-.466-1.163l-.288-.121c-.509-.213-1.134-.038-1.388.39l-3.642 6.144c-.254.427-.043.952.466 1.163"/>
            <path fill="#ECB996" d="M389.764 231.874l.323.085c.569.15 1.178-.127 1.353-.618l2.512-7.05c.176-.49-.147-1.014-.716-1.164l-.323-.087c-.569-.15-1.178.129-1.353.62l-2.512 7.048c-.176.49.147 1.015.716 1.166M391.997 221.972l-.285-.071a.925.925 0 0 1-.683-1.123l1.83-7.08a.947.947 0 0 1 1.145-.67l.286.071c.502.125.808.63.681 1.123l-1.83 7.08a.946.946 0 0 1-1.144.67"/>
            <path fill="#F4F4F1" d="M390 232a.902.902 0 0 0 .161-.282l.119-.341.04.017.013.007-.062.183c-.056.159-.15.3-.271.416m1.9-.68a.968.968 0 0 0 .173-.223L394.95 226l.05.021-2.792 4.952a1.165 1.165 0 0 1-.309.347"/>
            <path fill="#DDAE8E" d="M395 226l.604-1.569a.785.785 0 0 0 .042-.431c.1.15.208.296.328.436L395 226"/>
            <path fill="#DDAE8E" d="M392.688 230c-.16 0-.322-.031-.474-.097l-.152-.104-.016.047-.012-.007-.034-.014 1.603-4.555.724-1.27c.402.518.956.959 1.673 1.269l-2.464 4.318a.822.822 0 0 1-.149.19 1.184 1.184 0 0 1-.699.223M394 224c.002-.12.013-.238.032-.359.061-.395.118-.786.178-1.18a.948.948 0 0 0 .114-.273l.304-1.188c.332.18.668.35 1.01.51a5.414 5.414 0 0 0 .362 2.169.98.98 0 0 0-.454-.292l-.294-.09a.99.99 0 0 0-1.234.649L394 224"/>
            <path fill="#DDAE8E" d="M390.274 232c-.124 0-.248-.015-.372-.048-.67-.174-1.052-.781-.846-1.35l2.437-6.722c-.004-.055-.004-.11-.004-.166l.02-.053c.145-.402.586-.661 1.059-.661.106 0 .214.013.322.04l.33.087c.203.053.377.152.508.28.076.164.163.326.26.484a.778.778 0 0 1-.037.405l-.535 1.473-1.909 5.265-.12.335a.876.876 0 0 1-.165.276c-.241.223-.586.355-.948.355"/>
            <path fill="#DDAE8E" d="M392.39 224c-.112 0-.226-.01-.338-.033-.743-.146-1.197-.734-1.01-1.307l.545-1.66c.768.443 1.575.835 2.413 1.189l-.37 1.132a.82.82 0 0 1-.155.275c-.263.25-.662.404-1.085.404"/>
            <path fill="#0A0B09" d="M335 200l.085.033-.001-.001L335 200"/>
            <path fill="#ECB996" d="M337.461 203c-1.821-.001-3.64.037-5.461.051.065 5.582.237 14.417.55 20.635.069 1.337 1.356 2.378 2.863 2.31 1.5-.064 2.652-1.202 2.584-2.533-.31-6.155-.478-14.934-.536-20.463"/>
            <path fill="#ECB996" d="M337.203 232.938c-.62.2-1.305-.1-1.524-.67l-2.611-6.806c-.219-.569.11-1.2.729-1.4.62-.2 1.305.102 1.524.671l2.611 6.805c.219.57-.11 1.199-.729 1.4"/>
            <path fill="#ECB996" d="M338.105 233.936l-.333.05c-.59.088-1.155-.245-1.26-.74l-1.495-7.122c-.105-.496.29-.972.878-1.06l.334-.05c.587-.088 1.154.246 1.258.742l1.496 7.12c.105.496-.29.972-.878 1.06M337.234 223.96a1.055 1.055 0 0 1-1.303-.746l-1.891-6.837c-.158-.573.168-1.174.727-1.336a1.054 1.054 0 0 1 1.301.745l1.892 6.838c.16.572-.169 1.173-.726 1.335"/>
            <path fill="#F4F4F1" d="M336.87 234l.243-.043a.425.425 0 0 0 .045-.008 1.102 1.102 0 0 1-.287.051m1.07-.821c.031-.135.038-.277.011-.422l-.844-4.757c.02.007.042.012.062.017l.812 4.58c.035.202.018.4-.042.582m-2.94-.25a1.218 1.218 0 0 0 .523-.151v.004l-.13.078c-.13.047-.263.068-.393.068"/>
            <path fill="#DDAE8E" d="M333.686 225.716A3.272 3.272 0 0 1 333 224c.038.032.076.062.114.092l.572 1.624"/>
            <path fill="#DDAE8E" d="M336.496 232c-.473 0-.918-.276-1.084-.718l-1.862-4.96a2.072 2.072 0 0 1-.095-.11L333 225c.46.344.94.67 1.453.966.51.295 1.039.553 1.582.781l.965 5.117a1.202 1.202 0 0 1-.5.136h-.004"/>
            <path fill="#DDAE8E" d="M336.987 234c-.493 0-.935-.356-1.024-.857l-.04-.23-.047.028v-.004L335 228c.727.323 1.482.582 2.253.788l.733 4.131a.89.89 0 0 1-.01.366 1.048 1.048 0 0 1-.68.669.37.37 0 0 1-.04.007l-.21.038-.059.001"/>
            <path fill="#F4F4F1" d="M411 310.5c0 5.246-21.266 9.5-47.5 9.5s-47.5-4.254-47.5-9.5 21.266-9.5 47.5-9.5 47.5 4.254 47.5 9.5"/>
            <path fill="#EAAB91" d="M360 142h10v-16h-10z"/>
            <path fill="#ECB996" d="M382.409 203.355c-1.204-.706-2.078-1.975-2.078-3.814.003-11.954.745-23.633 1.943-35.518.558-5.546 1.379-11.029 2.084-16.55.499-3.917.528-7.897 1.642-11.678-.992-.558-2.503-.795-4.84-.795l-35.372.264c-3.08 0-5.842 1.015-7.788 2.624 1.925 15.688 5.187 31.192 5.852 47.024.043 1.006-.198 1.826-.6 2.492l.235 1.279c0 3.093-1.668 11.451-.856 16.448l-.388.014c.02.576 2.231 59.57 4.894 101.151.102 1.597 1.467 2.804 3.046 2.697 1.574-.106 2.766-1.48 2.664-3.078-2.25-35.126-4.178-82.73-4.727-96.858h28.6c-.689 14.187-3.063 61.807-5.601 96.788-.115 1.595 1.065 2.98 2.64 3.1 1.576.122 2.952-1.073 3.067-2.67 3.005-41.425 5.78-100.515 5.808-101.093l-.375-.02c.083-.563.13-1.172.15-1.807"/>
            <path fill="#FFFFFE" d="M346.458 135.629L346 177l36-1.286L378.854 135l-3.962.252s-10.473 16.583-24.458.034l-3.976.343"/>
            <path fill="#EAAB91" d="M406 96c0 2.209-2.239 4-5 4s-5-1.791-5-4 2.239-4 5-4 5 1.791 5 4"/>
            <path fill="#ECB996" d="M400 92c0 19.882-16.118 36-36 36-19.883 0-36-16.118-36-36 0-19.883 16.117-36 36-36 19.882 0 36 16.117 36 36"/>
            <path fill="#DCA189" d="M363.47 128.026h.07-.07m.087 0h.05-.05m-.2 0h-.016.017m.278 0h.04-.04m.068 0c.013-.002.024-.002.037-.002-.013 0-.024 0-.037.002m-.47 0l-.02-.002.02.002m.545-.002h.028-.028m-.675 0h-.007.007m.743 0h.026-.026m.068 0h.024-.024m-.929-.002h-.009.01m.996 0H364h-.017m-1.116 0h-.017.017m-.126-.002h-.016.016m-.242-.006h-.013.013m-.125-.002c-.002 0-.008 0-.012-.002a.022.022 0 0 0 .012.002m-.368-.012H362h.006"/>
            <path fill="#DDAE8E" d="M364.662 129h-.19l-.016-.002h-.09-.005l-.089-.001h-.11c-.03-.002-.059-.002-.087-.002h-.014l-.182-.004h-.01l-.09-.002-.01-.001-.093-.002c-.065-.001-.13-.004-.194-.006h-.005c-16.35-.544-29.903-12.315-33.477-27.98 3.027 7.962 9.293 14.837 16.288 19.252 6.287 3.97 14.658 6.095 22.401 6.095 1.791 0 3.55-.115 5.242-.346 4.929-.674 9.698-2.917 14.069-5.886-6.15 5.441-14.146 8.778-22.911 8.882h-.014l-.035.001h-.16c-.01 0-.02 0-.03.002h-.188"/>
            <path fill="#E28D7D" d="M372.98 100h-14.96a1.01 1.01 0 0 1-1.02-1c0-.553.456-1 1.02-1h14.96c.563 0 1.02.447 1.02 1 0 .553-.457 1-1.02 1"/>
            <path fill="#39291D" d="M352 90c0 1.656-.897 3-2 3-1.105 0-2-1.344-2-3s.895-3 2-3c1.103 0 2 1.344 2 3M381 90c0 1.656-.895 3-2 3s-2-1.344-2-3 .895-3 2-3 2 1.344 2 3"/>
            <path fill="#E5A730" d="M340.448 85.84h-.002a.986.986 0 0 1-.233-1.44c4.006-5.166 10.316-5.486 12.837-5.386a.982.982 0 0 1 .941 1.11l-.001.008a.98.98 0 0 1-1.005.847c-2.16-.073-7.794.21-11.234 4.655-.306.398-.88.479-1.303.206M386.247 85.634c-3.447-4.462-9.071-4.735-11.23-4.66a.981.981 0 0 1-1.008-.845v-.004a.98.98 0 0 1 .94-1.111c2.523-.1 8.832.22 12.838 5.386a.986.986 0 0 1-.234 1.44c-.424.273-.998.192-1.306-.206"/>
            <path fill="#E5A730" d="M386.842 64.206s-1.697-4.398-3.027-6.41c-2.73-4.132-9.974-3.756-18.085-3.379-6.502.303-13.562.604-19.299-1.417 0 0 6.137 4.014 8.126 4.279 0 0-12.404.764-17.96-1.253l3.31 9.242s-12.282 9.216-11.898 25.981c0 0 2.878-20.205 20.062-21.088 6.015-.31 10.668.14 14.601.588 7.306.831 12.136 1.664 18.619-2.354l5.55 10.22 1.141-7.444S399.36 82.168 399.983 92c0 0 .68-21.777-6.573-28.526-3.66-3.407-6.568.732-6.568.732"/>
            <path fill="#F4F4F1" d="M346.07 53.026l-.07-.024V53l.062.02.008.006"/>
            <path fill="#D99E2F" d="M354 58a26.624 26.624 0 0 1-2.863-1.225c-2.315-1.481-5.079-3.73-5.137-3.775l.05.021c1.874 1.44 3.857 2.43 5.936 3.128.165.14.332.28.498.416.234.19.469.404.716.568l.139.11.275.292c.133.148.257.31.386.465"/>
            <path fill="#F4F4F1" d="M328 91c.418-13.267 8.824-21.427 11.323-23.567l-.003.003c-2.503 2.144-10.899 10.3-11.32 23.558V91m12-24.182L336.805 58 340 66.818"/>
            <path fill="#DDAE8E" d="M328.01 93c-.016-.61-.013-1.21.007-1.802v-.006c-.02.591-.023 1.194-.007 1.807V93m11.294-25.382l.003-.003c.436-.376.693-.566.693-.566l-.018-.049.018.05s-.257.19-.696.568"/>
            <path fill="#D99E2F" d="M328.01 93a31.678 31.678 0 0 1 .007-1.815c.418-13.335 8.753-21.538 11.237-23.695.438-.379.694-.571.694-.571l-.018-.05L336.758 58c7.586 8.758 18.898 11.068 30.182 12.271 2.564.644 5.34.969 8.138.969 1.647 0 3.3-.113 4.922-.342-3.195 1.688-6.042 2.21-9.09 2.21-2.477 0-5.086-.346-8.12-.69-3.036-.345-6.495-.69-10.675-.69-1.256 0-2.578.03-3.974.103-17.15.881-20.1 20.948-20.131 21.169"/>
            <path fill="#DCA189" d="M378.046 93.552l-.002-.016.002.016m-.006-.084v-.008.008m-.006-.072v-.012.012m-.006-.082v-.008.008m-.006-.074v-.008.008m-.006-.08l-.002-.008.002.008m-.008-.078v-.002.002m-.008-.076V93v.006"/>
            <path fill="#DDAE8E" d="M396 82l-.009-.014L396 82m-.033-.056c-.006-.007-.01-.016-.015-.024l.015.024m-.024-.039c-1.584-2.67-3.412-5.07-4.943-6.903V75c1.53 1.831 3.36 4.234 4.943 6.905"/>
            <path fill="#D99E2F" d="M398.985 93c-.001-.017-.001-.036-.003-.053v-.012l-.004-.053v-.007l-.004-.05v-.009l-.004-.054v-.006l-.004-.052v-.006l-.004-.056-.001-.006-.005-.055v-.001c-.001-.019-.002-.04-.005-.058v-.004c-.293-3.441-1.659-6.994-3.33-10.183l-.008-.014-.022-.044c-.006-.008-.01-.017-.014-.025l-.008-.015c-1.457-2.763-3.14-5.248-4.547-7.142L391 75l.363.229c.139.08.279.159.417.24l.067.043.107.145c.148.188.287.389.446.566.293.332.625.632.973.903 2.56 3.402 4.271 7.691 5.601 12.038.05 2.337.011 3.836.011 3.836"/>
            <path fill="#E28D7D" d="M381 115.062a.927.927 0 0 0-.377-.744c-4.422-3.325-10.03-5.318-16.13-5.318-6.092 0-11.695 1.99-16.116 5.306a.931.931 0 0 0-.377.746c0 .797.977 1.223 1.64.725 4.076-3.056 9.238-4.889 14.853-4.889 5.62 0 10.788 1.837 14.865 4.898.664.499 1.642.073 1.642-.724"/>
            <path fill="#72797D" d="M346.392 303s-11.102.705-12.392 7h19.727l.273-4.927c-.164-1.314-.73-.822-.857-.417-.654 2.055-6.182 2.711-6.751-1.656M377.608 303s11.102.706 12.392 6.999l-19.727.001-.273-4.926c.164-1.315.73-.823.857-.418.654 2.056 6.182 2.712 6.751-1.656"/>
            <path fill="#9F5F49" d="M341 194s1.715 32.146 5.728 108l7.025-.697s-3.766-54.03-1.376-89.012l21.046 1.516s3.686 20.206-1.978 87.12l6.826.496S383.295 242.103 385 194h-44"/>
            <path fill="#4B5F6F" d="M353.355 135.06s-7.79-.553-14.162 1.71c-6.37 2.264-12.12 24.089-7.548 75.23h6.623s-3.53-41.062.178-54.69l2.792 49.803 25.762.169-1.51-34.892s-10.867-20.075-12.135-37.33"/>
            <path fill="#F4F4F1" d="M339 212.999l-.002-.003.002.003m-.002-.003c-.043-.386-3.013-26.711-1.632-43.996h.076c-1.421 17.58 1.513 43.613 1.556 43.996"/>
            <path fill="#E5E5E0" d="M338.262 161l-.272-4c-.358 1.192-.664 2.535-.924 4H337c.276-1.492.604-2.837.99-4l.272 4M337.078 167H337c.202-2.197.496-4.229.906-6h.066c-.398 1.796-.69 3.825-.894 6"/>
            <path fill="#475A69" d="M337.717 212h-6.68c-.038-6.329-.301-16.535 1.422-37.345.566-6.833 3.008-17.035 4.088-23.368.195-1.145.492-2.23.83-3.287.414 6.25 1.998 13.507 5.343 18.377 1.27 1.85 2.886 3.152 4.7 4.085-.286 5.258-.149 10.519.047 15.802.25 6.744-.396 13.757.432 20.436.02.159.07.297.101.446l-7.287-.048-2.817-49.948c-.29 1.063-.538 2.294-.747 3.659-.309 2.03-.53 4.36-.683 6.878-1.056 17.408 1.216 43.92 1.25 44.309V212"/>
            <path fill="#506577" d="M373.605 134.19s-.095 27.262-13.605 38.197l1.764 35.198 25.725-1.217 1.29-52.97s6.234 10.386 3.618 58.602h6.042s3.889-70.398-8.806-77.294c-2.19-1.19-16.028-.516-16.028-.516"/>
            <path fill="#F4F4F1" d="M386.002 172c0-.004 0-.007-.002-.012l.392-11.871h.002L386.002 172m.614-18.685l.01-.315-.01.315"/>
            <path fill="#E5E5E0" d="M388.002 161H388l.224-6-.222 6"/>
            <path fill="#4C5F70" d="M387.858 172c-.759-5.084-2.204-10.046-2.858-15.14 1.226-2.166 2.135-4.475 2.814-6.86 4.169 4.868 4.263 16.706 4.166 20.068-.91-12.674-3.642-17.023-3.655-17.042l-.007.315-.46 18.659"/>
            <path fill="#3A5875" d="M364.923 175.45a1.998 1.998 0 1 1-3.844 1.1 1.996 1.996 0 0 1 1.37-2.472 1.998 1.998 0 0 1 2.474 1.372M365.922 189.45a1.999 1.999 0 1 1-3.843 1.102 1.999 1.999 0 0 1 3.843-1.102"/>
        </g>
    </svg>
</template>

<script>
export default {

}
</script>