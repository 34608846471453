import store from "@/store"
import utils from "@/services/utils"

var lastKeySent = 0
export function inputValueChange (e = null) {
    let keyboard = { ...store.state.ui.keyboard }
    if (keyboard.timeout !== null) {
        clearTimeout(keyboard.timeout)
    }
    keyboard.timeout = null

    keyboard.timeout = (() => {
        inputValueChange(e)
    }, 30)

    console.log("OLD VALUE :", keyboard.value)
    const el = store.state.ui.keyboardEl
    const newValue = el.value
    console.log("NEW VALUE: ", newValue)
    console.log("Keyboard el is ", store.state.ui.keyboardEl)
    console.log("Event is ", e)

    if (e && (e.key === "Enter" || e.key === "Backspace" || e.inputType === "deleteContentBackward")) {
        /*
            TODO & POSSIBLE IMPROVEMENT:
            Out of this if and bellow it we do manual check to detect whether autospell or autosuggest or regular typing triggered this function
            In this way we handle the change in the most optimal way for it

            But in this link: https://rawgit.com/w3c/input-events/v1/index.html#interface-InputEvent-Attributes
            There are multiple input types also including the autospell and autosuggest which are our trouble makers
            To be confirmed whether this is success and if it is we can simplify the flow into the following:
             - If autocomplete or autosuggest then write via clipboard
             - Else write via keyboard
        */

        let message = {
            type: "keyboard",
            body: {
                key: e.key,
                type: "down"
            }
        }

        if (e.inputType === "deleteContentBackward") {
            message.body.key = "Backspace"
        }
        store.dispatch("comms/sendMessage", message)


        setTimeout(function () {
            message.body.type = "up"
            store.dispatch("comms/sendMessage", message)
        }, 50)
        return
    }

    /*
        If this value has higher length than 1 then clipboard is used for writing
        Else the robotgo regular typing is used on backend
        This is because using clipboard for regular typing has noticeable delay and using robotgo for cases where autosuggest is used is incorrect
    */
    let valueToSend = newValue

    /*
        If lengths are equal and this event triggered it means it is autosuggest for sure so we send the whole value
    */
    if (keyboard.value && newValue.length !== keyboard.value.length) { // 
        console.log("They are different")

        if (Math.abs(newValue.length - keyboard.value.length) <= 1) {
            let difference = []

            for (var k in newValue) {
                if (newValue[k] !== keyboard.value[k]) {
                    difference.push(parseInt(k))
                }
            }

            /*
                Since backspace is handled above then this must be triggered by change that made the input larger
                If the change after the difference is the same in the old and new input then it is only 1 character and it can be typed with keyboard
                If the change above isn't true then we assume more characters have changed at once and we write via clipboard & paste
            */
            let strAfterDif = newValue.substr(difference[0] + 1, newValue.length)
            let oldAfterDif = keyboard.value.substr(difference[0], keyboard.value.length)

            if (strAfterDif === oldAfterDif) {
                valueToSend = newValue[difference[0]]
            }
        }
    }
    keyboard.value = newValue
    store.dispatch("ui/setKeyboard", keyboard)

    const extensionMessage = {
        type: "transit",
        to: "extension",
        body: {
            expectPaste: true,
            selectionStart: el.selectionStart,
            selectionEnd: el.selectionEnd,
        }
    }
    store.dispatch("comms/sendMessage", extensionMessage)

    const message = {
        type: "typeFromClipboard",
        body: {
            input: true,
            value: valueToSend,
            selectionStart: el.selectionStart,
            selectionEnd: el.selectionEnd,
            id: lastKeySent
        }
    }
    lastKeySent++
    store.dispatch("comms/sendMessage", message)
}

export function handleInput (input) {
    if (!utils.isMobile()) return
    if (input.value) {
        store.state.ui.keyboardEl.value = input.value
    }
    if (input.type) {
        store.dispatch("ui/setKeyboard", { ...store.state.ui.keyboard, type: input.type })
    }
    if (input.focus) {
        openKeyboard(input)
    } else if (input.blur) {
        closeKeyboard()
    } else if (input.reset) {
        store.state.ui.keyboardEl.value = ""
        console.log("The value should have been reset by now ")
    }
}

export function openKeyboard (input = null) {
    const el = store.state.ui.keyboardEl

    const fake = document.createElement("input")
    fake.type = "text"
    fake.style.position = "absolute"
    fake.style.height = 0
    fake.style.opacity = 0

    const keyboardContainer = document.getElementById("btn-keyboard")
    keyboardContainer.style.position = "relative"
    keyboardContainer.appendChild(fake)
    fake.click()
    fake.focus()

    setTimeout(() => {
        el.click()
        el.focus()
        keyboardContainer.removeChild(fake)
    }, 1000)

    // Some input types don't support setting selection range and the bar goes on start making issues in typing
    // Thats why setting type as text is needed just to execute the selection range and place the bar at the end of the input
    const tempType = el.type
    let selection = {
        start: el.value.length,
        end: el.value.length
    }

    if (input.selectionStart || input.selectionEnd) {
        selection.start = input.selectionStart || 0
        selection.end = input.selectionEnd
    }

    store.dispatch("ui/setKeyboard", { ...store.state.ui.keyboard, type: "text", selectionStart: selection.start, selectionEnd: selection.end })
    el.setSelectionRange(selection.start, selection.end);
    store.dispatch("ui/setKeyboard", { ...store.state.ui.keyboard, type: tempType })

    if (input && input.value) {
        store.dispatch("ui/setKeyboard", { ...store.state.ui.keyboard, value: input.value })
    }
}

export function closeKeyboard () {
    if(!store.state.ui.keyboardEl) return
    
    const keyboard = { ...store.state.ui.keyboard }
    keyboard.blur = true
    store.dispatch("ui/setKeyboard", keyboard)
    store.state.ui.keyboardEl.value = ""
    store.state.ui.keyboardEl.blur()

    setTimeout(() => {
        keyboard.blur = false
        store.dispatch("ui/setKeyboard", keyboard)
    }, 200)
}