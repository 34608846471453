import { streamCategories, newCategory } from "@/services/stream"

function defaultState () {
    // Uncomment if needed to remember the previous state
    let state // = localStorage.getItem('cp-streaminfo')
    if (!state) {
        state = {
            currentCategory: null,
            lastGoodCategory: {},
            pastTests: [],
            samples: [],
            currentTest: {},
            queryActive: false
        }
    } else {
        state = JSON.parse(state)
    }

    return state
}

export default {
    namespaced: true,
    state: defaultState(),
    mutations: {
        SET_CURRENT_CATEGORY (state, value) {
            state.currentCategory = value
        },
        SET_LAST_GOOD_CATEGORY (state, value) {
            state.lastGoodCategory = value
        },
        PUSH_TEST (state, value) {
            state.pastTests.push(value)
        },
        SET_CURRENT_TEST (state, value) {
            state.currentTest = value
        },
        SET_TESTS (state, value) {
            state.pastTests = value
        },
        PUSH_SAMPLE (state, value) {
            state.samples.push(value)
        },
        SET_SAMPLES (state, value) {
            state.samples = value
        },
        SET_QUERY_ACTIVE (state, value) {
            state.queryActive = value
        }
    },
    actions: {
        setCurrentCategory ({ commit }, value) {
            commit("SET_CURRENT_CATEGORY", value)
        },
        setLastGoodCategory ({ commit }, value) {
            commit("SET_LAST_GOOD_CATEGORY", value)
        },
        pushTest ({ commit }, value) {
            commit("PUSH_TEST", value)
        },
        setCurrentTest ({ commit }, value) {
            commit("SET_CURRENT_TEST", value)
        },
        shiftTests ({ commit, state }) {
            const tests = state.pastTests
            tests.shift()
            commit("SET_TESTS", tests)
        },
        pushSample ({ commit }, value) {
            commit("PUSH_SAMPLE", value)
        },
        setSamples ({ commit }, value) {
            commit("SET_SAMPLES", value)
        },
        setQueryActive ({ commit }, value) {
            commit("SET_QUERY_ACTIVE", value)
        },
        setInitialStreamCategory ({ commit, state, rootState }) {
            if (state.currentCategory === null) {
                if (rootState.config.defaultWebrtcCategory) {
                    commit("SET_CURRENT_CATEGORY", streamCategories.high)
                } else {
                    const category = newCategory(rootState.config.bitrate, rootState.config.fps, rootState.config.keyframe)
                    commit("SET_CURRENT_CATEGORY", category)
                }
            }
        }
    }
}