import { openKeyboard, closeKeyboard } from "@/services/mobileKeyboard"
import { resize, getVideoWidth, getVideoHeight } from "@/services/ui"
import utils from "@/services/utils"

const defaultValues = () => {
    return {
        videoEl: null,
        audioEl: null,
        interactionEl: null,
        appEl: null,
        taskbarActiveBtn: null,
        activePopups: [],
        notifications: [],
        taskbarNotifications: [],
        videoReady: false,
        frameReady: false,
        interacted: false,
        resizing: false,
        extensionLoaded: false,
        deviceOrientation: null,
        resizeTimeout: null,
        clipboardMessage: "",
        keyboardEl: null,
        continueInProgress: false,
        videoWidth: 1920,
        videoHeight: 1080,
        keyboard: {
            value: null,
            blur: false,
            timeout: null,
            type: "text"
        },
        landscapeHeight: 0
    }
}

export default {
    namespaced: true,
    state: defaultValues(),
    mutations: {
        SET_VIDEO_EL(state, el) {
            state.videoEl = el
        },
        SET_AUDIO_EL(state, el) {
            state.audioEl = el
        },
        SET_INTERACTION_EL(state, el) {
            state.interactionEl = el
        },
        SET_KEYBOARD_EL(state, el) {
            state.keyboardEl = el
        },
        SET_TASKBAR_ACTIVE_BTN(state, btn) {
            state.taskbarActiveBtn = btn
        },
        PUSH_ACTIVE_POPUP(state, popup) {
            if (!state.activePopups.includes(popup)) {
                state.activePopups.push(popup)
            }
        },
        POP_ACTIVE_POPUP(state, popup) {
            const index = state.activePopups.indexOf(popup)
            state.activePopups.splice(index, 1)
        },
        CLOSE_POPUPS(state) {
            state.activePopups = []
        },
        SET_KEYBOARD(state, keyboard) {
            console.log("WILL SET KEYBOARD TO BE ", keyboard)
            state.keyboard = keyboard
        },
        PUSH_NOTIFICATION(state, notification) {
            state.notifications.push(notification)
        },
        POP_NOTIFICATION(state, notification) {
            let index = state.notifications.findIndexOf(item => item.id === notification.id)
            state.notifications.splice(index, 1)
        },
        CLOSE_NOTIFICATIONS(state) {
            state.notifications = []
        },
        SET_VIDEO_READY(state, value) {
            state.videoReady = value
        },
        SET_FRAME_READY(state, value) {
            state.frameReady = value
        },
        SET_INTERACTED(state, interacted) {
            state.interacted = interacted
        },
        SET_RESIZING(state, resizing) {
            state.resizing = resizing
        },
        SET_EXTENSION_LOADED(state, value) {
            state.extensionLoaded = value
        },
        SET_DEVICE_ORIENTATION(state, value) {
            state.deviceOrientation = value
        },
        SET_RESIZE_TIMEOUT(state, timeout) {
            state.resizeTimeout = timeout
        },
        SET_CLIPBOARD_MESSAGE(state, message) {
            state.clipboardMessage = message
        },
        PUSH_TASKBAR_NOTIFICATION(state, type) {
            if (!state.taskbarNotifications.includes(type)) state.taskbarNotifications.push(type)
        },
        POP_TASKBAR_NOTIFICATION(state, type) {
            let index = state.taskbarNotifications.indexOf(type)
            console.log("WILL SPLICE INDEX ", index)
            state.taskbarNotifications.splice(index, 1)
        },
        CLEAR_TASKBAR_NOTIFICATIONS(state) {
            state.taskbarNotifications = []
        },
        SET_APP_EL(state, el) {
            state.appEl = el
        },
        SET_CONTINUE_IN_PROGRESS(state, value) {
            state.continueInProgress = value
        },
        SET_VIDEO_WIDTH(state, value) {
            state.videoWidth = value
        },
        SET_VIDEO_HEIGHT(state, value) {
            state.videoHeight = value
        },
        SET_LANDSCAPE_HEIGHT(state, value) {
            state.landscapeHeight = value
        },
        RESET(state) {
            const appEl = state.appEl
            Object.assign(state, defaultValues())
            state.appEl = appEl
        }
    },
    actions: {
        setVideoEl({ commit }, el) {
            commit("SET_VIDEO_EL", el)
        },
        setAudioEl({ commit }, el) {
            commit("SET_AUDIO_EL", el)
        },
        setInteractionEl({ commit }, el) {
            commit("SET_INTERACTION_EL", el)
        },
        setKeyboardEl({ commit }, el) {
            commit("SET_KEYBOARD_EL", el)
        },
        setTaskbarActiveBtn({ commit }, btn) {
            commit("SET_TASKBAR_ACTIVE_BTN", btn)
        },
        pushActivePopup({ commit }, popup) {
            commit("PUSH_ACTIVE_POPUP", popup)
        },
        popActivePopup({ commit }, popup) {
            commit("POP_ACTIVE_POPUP", popup)
        },
        closePopups({ commit }) {
            commit("CLOSE_POPUPS")
        },
        setKeyboard({ commit }, keyboard) {
            commit("SET_KEYBOARD", keyboard)
        },
        openKeyboard({ }, input = null) {
            openKeyboard(input)
        },
        closeKeyboard({ }) {
            closeKeyboard()
        },
        handleKeyboardInput({ commit, state }) {
            commit("SET_KEYBOARD", { ...state.keyboard })
        },
        pushNotification({ commit }, notification) {
            commit("PUSH_NOTIFICATION", new Notification(...notification))
        },
        popNotification({ commit }, notification) {
            commit("POP_NOTIFICATION", notification)
        },
        closeNotifications({ commit }) {
            commit("CLOSE_NOTIFICATIONS")
        },
        setVideoReady({ commit }, value) {
            commit("SET_VIDEO_READY", value)
        },
        setFrameReady({ commit }, value) {
            commit("SET_FRAME_READY", value)
        },
        setInteracted({ commit }, value) {
            commit("SET_INTERACTED", value)
        },
        setResizing({ commit }, value) {
            commit("SET_RESIZING", value)
        },
        setExtensionLoaded({ commit }, value) {
            commit("SET_EXTENSION_LOADED", value)
        },
        setDeviceOrientation({ commit }, value) {
            commit("SET_DEVICE_ORIENTATION", value)
        },
        setResizeTimeout({ commit }, timeout) {
            commit("SET_RESIZE_TIMEOUT", timeout)
        },
        setContinueInProgress({ commit }, value) {
            commit("SET_CONTINUE_IN_PROGRESS", value)
        },
        resize({ commit, state }) {
            const width = getVideoWidth()
            let height = getVideoHeight()

            if (width === state.videoWidth && height === state.videoHeight) {
                return
            }

            if (utils.isMobile() && state.landscapeHeight > 0 && height < width) {
                height = state.landscapeHeight
            }

            commit("SET_RESIZING", true)
            commit("SET_VIDEO_WIDTH", width)
            commit("SET_VIDEO_HEIGHT", height)

            if (state.resizeTimeout) {
                clearTimeout(state.resizeTimeout)
                commit("SET_RESIZE_TIMEOUT", null)
            }

            let timeout = setTimeout(() => {
                resize().then(() => {
                    console.log("Successfully resized")
                }).catch(err => {
                    console.log("Error while resizing ", err)
                }).finally(() => {
                    commit("SET_RESIZING", false)
                    commit("SET_RESIZE_TIMEOUT", null)
                })
            }, 500)
            commit("SET_RESIZE_TIMEOUT", timeout)
        },
        updateVideoSize({ commit }) {
            const width = getVideoWidth()
            const height = getVideoHeight()

            commit("SET_VIDEO_WIDTH", width)
            commit("SET_VIDEO_HEIGHT", height)
        },
        confirmVideoIsPlaying({ commit, state }) {
            if (!state.videoEl.paused && state.videoEl.currentTime > 0 && !state.videoEl.ended) {
                commit("SET_VIDEO_READY", true)
            } else if (state.videoEl.currentTime == 0 && !state.videoEl.ended && !state.videoEl.paused) {
                setTimeout(() => {
                    if (!state.videoEl.paused && state.videoEl.currentTime > 0 && !state.videoEl.ended) {
                        commit("SET_VIDEO_READY", true)
                    }
                }, 400)
            } else {
                try {
                    state.videoEl.play().catch(err => {
                        console.log("=============================== ")
                        console.log("ERRROR WHILE TRYING TO PLAY VIDEO", err)
                    })
                } catch (e) {
                    console.log(e)
                }
            }
        },
        showClipboardMessage({ commit }, value) {
            // Reseting to null and assigning another value allows when button is clicked multiple times fast to give nudge effect
            commit("SET_CLIPBOARD_MESSAGE", null)
            setTimeout(() => {
                commit("SET_CLIPBOARD_MESSAGE", value)
            }, 30)
        },
        pushTaskbarNotification({ commit }, type) {
            commit("PUSH_TASKBAR_NOTIFICATION", type)
        },
        popTaskbarNotification({ commit }, type) {
            commit("POP_TASKBAR_NOTIFICATION", type)
        },
        clearTaskbarNotifications({ commit }) {
            commit("CLEAR_TASKBAR_NOTIFICATIONS")
        },
        setAppEl({ commit }, el) {
            commit("SET_APP_EL", el)
        },
        setLandscapeHeight({ commit }, value) {
            commit("SET_LANDSCAPE_HEIGHT", value)
        },
        reset({ commit }) {
            commit("RESET")
        }
    }
}