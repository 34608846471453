import store from '@/store'

export async function getFiles() {
    fetch(`${store.getters['general/isolatorURL']}uploads`)
    .then(response=>response.json())
    .then(res=> {
        if(res) {
            return res
        } else {
            return []
        }
    })
    .catch(err => {
        console.log("Error while getting downloads", err)
    })
}

export function processDownload(obj) {
    switch(obj.type) {
        case 'all':
            store.dispatch("downloads/setFiles", obj.files)
            break;
        case 'add':
            store.dispatch("downloads/addFile", obj.file)
            break;
        case 'update':
            store.dispatch("downloads/updateFile", obj.file)
            break;
        case 'statuses':
            store.dispatch("downloads/setStatuses", obj.statuses)
            store.dispatch("uploads/setStatuses", obj.statuses)
            break;
    }
}

export async function downloadFile(f) {
    if(navigator.userAgent.match(/safari/i)) {
        const url = `${store.getters['general/isolatorURL']}download/${f.name}`
        let a = document.createElement("a")
        a.setAttribute("href", url)
        a.setAttribute("target", "_blank")
        a.click()
        return
    }
    const file = Object.assign({}, f)
    const response = await fetch(store.getters['general/isolatorURL'] + 'download/' + file.name)
    const contentLength = response.headers.get('content-length')
    const total = parseInt(contentLength, 10);

    let loaded = 0;
    let progress = 0;

    const res = new Response(new ReadableStream({
        async start(controller) {
            const reader = response.body.getReader()
            let finished = false;
            for(;;) {
                const {done, value} = await reader.read()
                if(done) break;
                loaded += value.byteLength;
                progress = Math.round(loaded/total*100);

                if(progress % 5 === 0 || progress === 0) {
                    file.downloading = progress === 100 ? false : true;
                    file.loaded = loaded;
                    file.total = total;
                    file.progress = progress;
                    store.dispatch("downloads/updateFile", file)
                }

                if(progress === 100) {
                    if(!finished) {
                        finished = true
                        store.dispatch("downloads/decrementInProgress")
                    } else if(progress > 100) {
                        console.log("Progress above 100, ignoring.")
                    }
                }
                controller.enqueue(value)
            }
            controller.close()
        }
    }))
    const blob = await res.blob();
    downloadToClient(blob, file)
}

function downloadToClient(blob, file) {
    const url = window.URL.createObjectURL(blob);
    const a= document.createElement('a');
    a.style.display='none';
    a.href=url;
    a.download=file.name;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
}

export function deleteFile(file) {
    fetch(store.getters['general/isolatorURL'] + "delete/" + file.name, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
     })
      .then((response) => response.json())
      .then((res) => {
        console.log("Response from Downloaded file delete is ", res);
      })
      .catch((err) => {
        console.log("Error while deleting file ", err);
    });
}