<template>
    <div class='btn-paste taskbar-btn' @click="paste" title="Paste">
        <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" width="512px" height="512px">
            <g id="g854"><g id="g852"><g id="g850">
                <path d="M 391.5,250.306 V 90 c 0,-33.084 -16.916,-50 -50,-50 H 276.073 C 267.818,16.719 245.575,0 219.5,0 193.425,0 171.182,16.719 162.927,40 H 96.5 c -33.084,0 -50,16.916 -50,50 v 322 c 0,33.084 16.916,50 50,50 h 137.439 c 8.255,23.278 22.489,40 48.573,40 l 132.989,-0.074 c 33.084,0 50,-16.913 50,-49.992 V 324.728 Z m 15.856,62.619 H 381.5 c -11.028,0 -20,-8.972 -20,-20 V 266.808 Z M 159.5,70 h 40 V 50 c 0,-11.028 8.972,-20 20,-20 11.028,0 20,8.972 20,20 v 20 h 40 v 39 h -120 z m 73,188.993 V 432 h -136 c -11.028,0 -20,-8.972 -20,-20 V 90 c 0,-11.028 9.886551,-24.397152 20,-20 h 33 v 59 h 180 V 70 h 32 c 11.028,0 20,8.972 20,20 V 210.079 L 350.484,209 H 282.5 c -33.084,0 -50,16.913 -50,49.993 z m 203,192.941 c 0,11.024 -8.972,19.992 -20.011,19.992 L 282.5,472 c -11.028,0 -20,-8.969 -20,-19.993 V 258.993 c 0,-11.024 8.972,-19.993 20,-19.993 h 49 v 53.926 c 0,33.084 17.4691,43.97576 50,50 h 54 z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#5f6368" />
            </g></g></g>
        </svg>
    </div>
</template>

<script>
import { useStore } from "vuex"
import { sendPaste } from "@/services/clipboard"

export default {
    data() {
        return {
            store: useStore()
        }
    },
    methods: {
        paste(event) {
            sendPaste(event)
        }
    }
}
</script>