import store from "@/store";
import utils from "./utils";
import { getVideoWidth, getVideoHeight } from "@/services/ui";
import * as deviceinfo from "@/services/deviceinfo";
import { v4 as uuidv4 } from "uuid";
import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";

export function assignIsolator() {
  if(!store.state.general.id) {
    const options = generateAssignInfo()
    const query = new URLSearchParams(options).toString()
    
    store.dispatch("/general/setLoadingID", true)
    const connection = new HubConnectionBuilder()
      .withUrl("/dispatcher/api/assignIsolator?" + query, {
        skipNegotiation: false,
        transport: HttpTransportType.WebSockets| 
                   HttpTransportType.LongPolling |
                   HttpTransportType.serverSentEvents,
      })
      .build()

    connection.start().then(() => {
      startWaitingForID()
    })

    connection.on("OnIsolator", (id) => {
      setIsolatorId(id)
      connection.invoke("OnAssignConfirm", options.identifier)
    })

  } else {
    store.dispatch("comms/openWSS")
  }
}

// Run an interval to check if we need to display the busy page
function startWaitingForID() {
  var busyInterval = null;
  store.dispatch("general/setFirstIDGet")

  busyInterval = setInterval(() => {
    store.dispatch("general/setLastIDGet")
    if(store.state.general.id) {
      clearInterval(busyInterval)
    }
  }, 1000)
}

function setIsolatorId(id) {
  store.dispatch("general/setID", id)
  store.dispatch("comms/openWSS")

  // If the last url in store is different than config url
  // Send this url to the isolator so he navigates to that page
  if(store.state.general.lastURL && store.state.general.lastURL !== store.state.config.url) {
    sendExpiredSignal()
  }
}

function generateAssignInfo() {
  const config = store.state.config;
  var id = store.state.general.clientId;
  if (!id) {
    id = uuidv4();
    store.dispatch("general/setClientId", id);
  }

  const options = {
    identifier: id,
    width: getVideoWidth(),
    height: getVideoHeight(),
    app: config.app,
    url: config.url,
    debug: false,
    primaryProtocol: config.primaryProtocol,
    userAgent: navigator.userAgent,
    kiosk: config.kiosk,
    domain: location.host,
    isMobile: utils.isMobile(),
    browserName: deviceinfo.getBrowserName(),
    browserLanguage: deviceinfo.getBrowserLanguage(),
    browserPlugins: deviceinfo.getBrowserPlugins(),
    browserCookies: deviceinfo.getBrowserCookies(),
    osName: deviceinfo.getOsName(),
    cpuInfo: deviceinfo.getCpuInfo(),
    gpuInfo: deviceinfo.getGpuInfo(),
    gpuVendor: deviceinfo.getGpuVendor(),
    gpuRenderer: deviceinfo.getGpuRenderer(),
    deviceType: deviceinfo.geteviceType(),
    systemMemory: deviceinfo.getSystemMemory(),
    // batteryInfo: await deviceinfo.getBatteryInfo(),
    // ISP: await deviceinfo.getISP(),
    connectionType: deviceinfo.getConnectionType(),
    networkType: deviceinfo.getNetworkType(),
  };
  if (
    store.state.general.lastURL &&
    store.state.general.lastURL !== options.url
  ) {
    options.url = store.state.general.lastURL;
  }
  console.log("Options", options);
  return options;
}


function sendExpiredSignal() {
  store.dispatch("ui/setContinueInProgress", true);
  fetch(store.getters["general/isolatorURL"] + "continueExpired", {
    method: "POST",
    body: JSON.stringify({ link: store.state.general.lastURL }),
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((res) => {
      store.dispatch("ui/setContinueInProgress", false);
      console.log("Response from expired is ", res);
    })
    .catch((err) => {
      store.dispatch("ui/setContinueInProgress", false);
      console.log(err);
    });
}
