<template>
  <div
    id="btn-downloads"
    ref="btnDownload"
    v-if="config.allowDownload || config.allowUpload"
  >
    <uploads-popup v-show="uploadsPopup" @close="closeUploads"></uploads-popup>

    <div
      class="downloads-popup"
      :class="{
        'show-popup': popup,
        'hidden-taskbar': config.taskbar === 'hidden',
      }"
    >
      <div class="tabs-container">
        <p class="close-popup" @click="closePopup">Close</p>
        <div
          class="tab"
          v-if="config.allowDownload"
          :class="{ active: activeTab === 'downloads' }"
          @click="changeTab('downloads')"
        >
          <p>Downloads</p>
        </div>
        <div
          class="tab uploads-tab"
          v-if="config.allowUpload"
          :class="{ active: activeTab === 'uploads' }"
          @click="changeTab('uploads')"
          ref="uploads"
        >
          <p>
            Uploads
            <svg
              xmlns="http://www.w3.org/2000/svg"
              @click="openUploads"
              id="upload-btn"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-plus-circle"
            >
              <title>Upload new</title>
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" y1="8" x2="12" y2="16"></line>
              <line x1="8" y1="12" x2="16" y2="12"></line>
            </svg>
          </p>
        </div>
        <span
          :class="{
            'uploads-active': activeTab === 'uploads' && config.allowDownload,
          }"
        ></span>
      </div>

      <div
        class="tab-content"
        v-if="activeTab === 'downloads' && config.allowDownload"
      >
        <p v-if="downloads.length === 0">No downloads found.</p>
        <ul class="files-list downloads-list" v-else>
          <li v-for="file in downloads" :key="file.name">
            <div
              v-if="
                file.status === fileStatus.waiting ||
                file.status === fileStatus.ready
              "
            >
              <span>
                {{ truncateLongFilename(file.name) }}
                <img
                  v-if="config.downloadScan"
                  src="../../../assets/images/scanning.gif"
                />
              </span>
            </div>

            <div v-else-if="file.status === fileStatus.safe">
              <a
                style="color: #39db80"
                :href="`${isolatorURL}download/${file.name}`"
                download
              >
                {{ file.name }}
                <ve-progress
                  :size="18"
                  :color="'#4d90fe'"
                  :thickness="2"
                  :hide-legend="true"
                  :progress="file.progress"
                  v-if="file.progress !== null && file.progress < 100"
                />
                <svg v-if="config.downloadScan">
                  <use xlink:href="#safe-shield" />
                  <title>Safe file</title>
                </svg>
              </a>
            </div>

            <div v-else-if="file.status === fileStatus.malicious">
              <div
                v-if="!file.keep"
                class="malicious-actions"
                style="color: red"
              >
                {{ truncateLongFilename(file.name) }}
                <ve-progress
                  :size="18"
                  :color="'#4d90fe'"
                  :thickness="2"
                  :hide-legend="true"
                  :progress="file.progress"
                  v-if="file.progress !== null && file.progress < 100"
                />
                <div class="action-btns">
                  <span
                    style="color: red; display: inline"
                    @click="maliciousUpdate(file, 'keep')"
                    >Keep</span
                  >
                  <span
                    style="color: red; display: inline"
                    @click="maliciousUpdate(file, 'delete')"
                    >Delete</span
                  >
                </div>
                <svg v-if="config.downloadScan">
                  <use xlink:href="#malicious-shield" />
                  <title>Malicious file</title>
                </svg>
              </div>
              <a
                v-else
                style="color: red"
                :href="`${isolatorURL}download/${file.name}`"
                download
              >
                {{ file.name }}
                <svg v-if="config.downloadScan">
                  <use xlink:href="#malicious-shield" />
                  <title>Malicious file</title>
                </svg>
              </a>
            </div>
          </li>
        </ul>
      </div>

      <div
        class="tab-content"
        v-else-if="activeTab === 'uploads' && config.allowUpload"
      >
        <p v-if="uploads.length === 0 && uploadingfiles.length === 0">
          No uploads found.
        </p>
        <ul class="files-list uploads-list" v-else>
          <li v-for="file in uploads" :key="file.name">
            <p
              v-if="
                file.status !== fileStatus.waiting &&
                file.status !== fileStatus.ready
              "
            >
              {{ truncateLongFilename(file.name) }}
              <ve-progress
                :size="18"
                :color="'#4d90fe'"
                :thickness="2"
                :hide-legend="true"
                :progress="file.progress"
                v-if="file.progress !== null && file.progress < 100"
              />
              <svg v-else-if="file.status === fileStatus.safe">
                <use xlink:href="#safe-shield" />
                <title>Safe file</title>
              </svg>
              <svg v-else-if="file.status === fileStatus.malicious">
                <use xlink:href="#malicious-shield" />
                <title>Malicious file</title>
              </svg>
            </p>
          </li>
        </ul>
        <ul class="files-list uploads-list">
          <li v-for="file in uploadingfiles" :key="file.name">
            <p>
              {{ truncateLongFilename(file.name) }}
              <ve-progress
                :size="18"
                :color="'#4d90fe'"
                :thickness="2"
                :hide-legend="true"
                :progress="file.progress"
                v-if="file.progress !== null && file.progress < 100"
              />
              <img src="../../../assets/images/scanning.gif" />
            </p>
          </li>
        </ul>
      </div>
    </div>

    <svg
      class="btn-download taskbar-btn"
      :class="{
        active: popup,
        'download-progress': downloadsInProgress > 0,
        'upload-progress': uploadsInProgress > 0,
      }"
      @click="togglePopup"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 30 30"
      version="1.1"
    >
      <g id="surface1">
        <path
          style="stroke: none; fill-rule: nonzero; fill-opacity: 1"
          d="M 5.75 20.292969 L 5.085938 20.292969 C 4 20.292969 2.964844 19.855469 2.164062 19.058594 C 1.367188 18.265625 0.910156 17.226562 0.878906 16.128906 C 0.878906 16.121094 0.878906 16.109375 0.878906 16.101562 C 0.898438 13.992188 2.410156 12.183594 4.488281 11.789062 C 4.609375 11.769531 4.710938 11.695312 4.777344 11.59375 C 4.84375 11.492188 4.863281 11.367188 4.832031 11.25 C 4.660156 10.589844 4.585938 9.90625 4.605469 9.214844 C 4.636719 8.050781 4.957031 6.902344 5.53125 5.886719 C 5.648438 5.675781 5.574219 5.40625 5.363281 5.289062 C 5.152344 5.167969 4.886719 5.242188 4.765625 5.457031 C 4.121094 6.59375 3.761719 7.882812 3.726562 9.191406 C 3.707031 9.8125 3.761719 10.429688 3.882812 11.03125 C 1.605469 11.664062 0 13.746094 0 16.140625 C 0 16.15625 0 16.175781 0.00390625 16.191406 C 0.0507812 17.5 0.59375 18.738281 1.542969 19.683594 C 2.507812 20.644531 3.765625 21.171875 5.085938 21.171875 L 5.75 21.171875 C 5.992188 21.171875 6.1875 20.976562 6.1875 20.734375 C 6.1875 20.488281 5.992188 20.292969 5.75 20.292969 Z M 5.75 20.292969 "
        />
        <path
          style="stroke: none; fill-rule: nonzero; fill-opacity: 1"
          d="M 26.207031 11.054688 C 26.253906 10.757812 26.277344 10.457031 26.277344 10.15625 C 26.277344 6.984375 23.695312 4.402344 20.523438 4.402344 C 19.824219 4.402344 19.144531 4.527344 18.496094 4.769531 C 16.988281 2.613281 14.546875 1.324219 11.898438 1.304688 C 11.878906 1.304688 11.863281 1.304688 11.847656 1.304688 C 9.621094 1.304688 7.464844 2.242188 5.929688 3.875 C 5.761719 4.050781 5.773438 4.328125 5.949219 4.496094 C 6.125 4.664062 6.402344 4.652344 6.570312 4.476562 C 7.941406 3.019531 9.863281 2.183594 11.847656 2.183594 C 11.859375 2.183594 11.875 2.183594 11.890625 2.183594 C 14.363281 2.203125 16.632812 3.460938 17.960938 5.550781 C 18.078125 5.734375 18.316406 5.804688 18.515625 5.714844 C 19.148438 5.425781 19.824219 5.28125 20.523438 5.28125 C 23.210938 5.28125 25.398438 7.46875 25.398438 10.15625 C 25.398438 10.53125 25.355469 10.910156 25.265625 11.277344 C 25.242188 11.394531 25.261719 11.515625 25.324219 11.613281 C 25.386719 11.714844 25.488281 11.785156 25.605469 11.808594 C 27.640625 12.238281 29.117188 14.058594 29.121094 16.132812 C 29.085938 17.226562 28.628906 18.265625 27.832031 19.058594 C 27.03125 19.855469 25.996094 20.292969 24.914062 20.292969 L 24.292969 20.292969 C 24.046875 20.292969 23.851562 20.488281 23.851562 20.734375 C 23.851562 20.976562 24.046875 21.171875 24.292969 21.171875 L 24.914062 21.171875 C 26.230469 21.171875 27.484375 20.640625 28.453125 19.679688 C 29.410156 18.726562 29.960938 17.472656 30 16.152344 C 30 16.148438 30 16.144531 30 16.140625 C 30 13.792969 28.429688 11.71875 26.207031 11.054688 Z M 26.207031 11.054688 "
        />
        <path
          id="download-arrow"
          style="stroke: none; fill-rule: nonzero; fill-opacity: 1"
          d="M 11.40625 16.113281 C 11.070312 16.113281 10.796875 16.386719 10.796875 16.726562 L 10.796875 22.640625 L 9.167969 21.007812 C 8.929688 20.769531 8.542969 20.769531 8.304688 21.007812 C 8.066406 21.25 8.066406 21.636719 8.304688 21.875 L 10.972656 24.550781 C 11.09375 24.667969 11.25 24.726562 11.40625 24.726562 C 11.5625 24.726562 11.71875 24.667969 11.835938 24.550781 L 14.507812 21.875 C 14.746094 21.636719 14.746094 21.25 14.507812 21.007812 C 14.269531 20.769531 13.882812 20.769531 13.644531 21.007812 L 12.015625 22.640625 L 12.015625 16.726562 C 12.015625 16.386719 11.742188 16.113281 11.40625 16.113281 Z M 11.40625 16.113281 "
        >
          <title>
            {{ downloadsInProgress ? "Download in progress" : "Downloads" }}
          </title>
        </path>
        <path
          id="upload-arrow"
          style="stroke: none; fill-rule: nonzero; fill-opacity: 1"
          d="M 18.984375 24.472656 C 19.320312 24.472656 19.59375 24.199219 19.59375 23.863281 L 19.59375 17.949219 L 21.222656 19.578125 C 21.339844 19.699219 21.496094 19.757812 21.652344 19.757812 C 21.808594 19.757812 21.964844 19.699219 22.085938 19.578125 C 22.324219 19.339844 22.324219 18.953125 22.085938 18.714844 L 19.414062 16.039062 C 19.300781 15.925781 19.144531 15.859375 18.984375 15.859375 C 18.820312 15.859375 18.667969 15.925781 18.550781 16.039062 L 15.882812 18.714844 C 15.640625 18.953125 15.640625 19.339844 15.882812 19.578125 C 16.121094 19.820312 16.507812 19.820312 16.746094 19.578125 L 18.371094 17.949219 L 18.371094 23.863281 C 18.371094 24.199219 18.644531 24.472656 18.984375 24.472656 Z M 18.984375 24.472656 "
        >
          <title>
            {{ uploadsInProgress ? "Upload in progress" : "Uploads" }}
          </title>
        </path>
      </g>
      <title>Downloads &amp; Uploads</title>
    </svg>

    <div
      class="downloads-notification"
      v-if="inProgressNotification"
      @click="openPopup"
    >
      <div class="downloads-notification-content">
        {{
          inProgressNotification === "downloads"
            ? "Downloads in progress"
            : "Uploads in progress"
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { useStore, mapState, mapGetters } from "vuex";

import UploadsPopup from "@/components/popups/UploadsPopup.vue";

export default {
  components: {
    UploadsPopup,
  },
  data() {
    return {
      store: useStore(),
      activeTab: "downloads",
      inProgressNotification: null,
      inProgressNotificationTimeout: null,
    };
  },
  computed: {
    ...mapState({
      config: (state) => state.config,
      downloads: (state) => state.downloads.files,
      downloadsInProgress: (state) => state.downloads.inProgress,
      uploads: (state) => state.uploads.files,
      uploadingfiles: (state) => state.uploads.uploadingfiles,
      uploadsInProgress: (state) => state.uploads.inProgress,
      uploadsPopup: (state) => state.ui.activePopups.includes("uploads"),
      popup: (state) => state.ui.activePopups.includes("files"),
      fileStatus: (state) => state.downloads.fileStatuses,
    }),
    ...mapGetters({
      isolatorURL: "general/isolatorURL",
    }),
  },
  created() {
    this.store.dispatch("downloads/get");
    this.store.dispatch("uploads/get");
    this.activeTab = this.config.allowDownload ? "downloads" : "uploads";
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab;
    },
    togglePopup() {
      if (this.popup) {
        this.closePopup();
      } else {
        this.openPopup();
      }
    },
    openPopup() {
      if (this.inProgressNotification) {
        this.changeTab(this.inProgressNotification);
      }
      this.store.dispatch("ui/pushActivePopup", "files");
    },
    closePopup() {
      this.store.dispatch("ui/popActivePopup", "files");
      this.checkInProgressNotification();
    },
    openUploads() {
      this.store.dispatch("ui/pushActivePopup", "uploads");
      this.closePopup();
    },
    closeUploads() {
      this.store.dispatch("ui/popActivePopup", "uploads");
      this.checkInProgressNotification();
    },
    maliciousUpdate(file, type) {
      this.store.dispatch("downloads/maliciousUpdate", { file, type });
    },
    truncateLongFilename(filename) {
      if (!filename) return "";
      return filename.length > 50 ? filename.slice(0, 51 - 1) + "…" : filename;
    },
    triggerInProgressNotification(type) {
      this.inProgressNotificationTimeout = setTimeout(() => {
        this.inProgressNotification = type;
        this.inProgressNotificationTimeout = setTimeout(() => {
          this.inProgressNotification = null;
        }, 3000);
      }, 500);
    },
    checkInProgressNotification() {
      console.log("uploadsPopup", this.uploadsPopup);
      console.log("popup", this.popup);
      this.uploadsPopup = true;
      if (this.popup || this.uploadsPopup) {
        this.inProgressNotification = null;
        if (this.inProgressNotificationTimeout) {
          clearTimeout(this.inProgressNotificationTimeout);
        }
        return;
      }

      if (this.inProgressNotification) {
        return;
      }

      if (this.downloadsInProgress > 0) {
        this.triggerInProgressNotification("downloads");
      } else if (this.uploadsInProgress > 0) {
        this.triggerInProgressNotification("uploads");
      }
    },
  },
  watch: {
    downloads: {
      handler() {},
      deep: true,
    },
    uploads: {
      handler() {},
      deep: true,
    },
    downloadsInProgress() {
      this.checkInProgressNotification();
    },
    uploadsInProgress() {
      this.checkInProgressNotification();
    },
  },
};
</script>