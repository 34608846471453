export const langList = {
    af: "Afghani",
    al: "Albanian",
    et: "Amharic",
    ara: "Arabic",
    ma: "Arabic (Morocco)",
    sy: "Arabic (Syria)",
    am: "Armenian",
    az: "Azerbaijani",
    ml: "Bambara",
    by: "Belarusian",
    be: "Belgian",
    dz: "Berber (Algeria, Latin characters)",
    ba: "Bosnian",
    bg: "Bulgarian",
    mm: "Burmese",
    cn: "Chinese",
    hr: "Croatian",
    cz: "Czech",
    dk: "Danish",
    mv: "Dhivehi",
    nl: "Dutch",
    bt: "Dzongkha",
    au: "English (Australian)",
    cm: "English (Cameroon)",
    gh: "English (Ghana)",
    ng: "English (Nigeria)",
    za: "English (South Africa)",
    gb: "English (UK)",
    us: "English (US)",
    ee: "Estonian",
    fo: "Faroese",
    ph: "Filipino",
    fi: "Finnish",
    fr: "French",
    ca: "French (Canada)",
    cd: "French (Democratic Republic of the Congo)",
    gn: "French (Guinea)",
    tg: "French (Togo)",
    ge: "Georgian",
    de: "German",
    at: "German (Austria)",
    ch: "German (Switzerland)",
    gr: "Greek",
    il: "Hebrew",
    hu: "Hungarian",
    is: "Icelandic",
    in: "Indian",
    id: "Indonesian (Jawi)",
    iq: "Iraqi",
    ie: "Irish",
    it: "Italian",
    jp: "Japanese",
    kz: "Kazakh",
    kh: "Khmer (Cambodia)",
    kr: "Korean",
    kg: "Kyrgyz",
    la: "Lao",
    lv: "Latvian",
    lt: "Lithuanian",
    mk: "Macedonian",
    bn: "Malay (Jawi)",
    my: "Malay (Jawi)",
    mt: "Maltese",
    md: "Moldavian",
    mn: "Mongolian",
    me: "Montenegrin",
    np: "Nepali",
    no: "Norwegian",
    ir: "Persian",
    pl: "Polish",
    pt: "Portuguese",
    br: "Portuguese (Brazil)",
    ro: "Romanian",
    ru: "Russian",
    rs: "Serbian",
    lk: "Sinhala (phonetic)",
    sk: "Slovak",
    sl: "Slovenian",
    es: "Spanish",
    ke: "Swahili (Kenya)",
    tz: "Swahili (Tanzania)",
    se: "Swedish",
    tw: "Taiwanese",
    tj: "Tajik",
    th: "Thai",
    bw: "Tswana",
    tr: "Turkish",
    tm: "Turkmen",
    ua: "Ukrainian",
    pk: "Urdu (Pakistan)",
    uz: "Uzbek",
    vn: "Vietnamese",
    sn: "Wolof",
}