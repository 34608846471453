import { getFiles, downloadFile, deleteFile } from "@/services/downloads"

const defaultValues = () => {
    return {
        files: [],
        fileStatuses: {},
        inProgress: 0
    }
}

export default {
    namespaced: true,
    state: defaultValues(),
    getters: {},
    mutations: {
        SET_FILES(state, files) {
            if(files) {
                state.files = [...files]
            }
        },
        ADD_FILE(state, file) {
            state.files.push(file)
        },
        UPDATE_FILE(state, file) {
            const index = state.files.findIndex(item => item.name === file.name)
            const files = Object.assign([], state.files)
            // Avoid refreshing and rerendering when no progress is made since this executes way too fast and can slow the app
            if((files[index].progress || files[index].progress === 0) && files[index].progress === file.progress && files[index].status === file.status) {
                return
            } else {
                files[index] = Object.assign({}, file)
                state.files = files
            }
        },
        REMOVE_FILE(state, file) {
            const index = state.files.findIndex(item => item.name === file.name)
            state.files.splice(index, 1)
        },
        SET_STATUSES(state, statuses) {
            state.fileStatuses = statuses
        },
        INCREMENT_IN_PROGRESS(state) {
            state.inProgress++
        },
        DECREMENT_IN_PROGRESS(state) {
            state.inProgress--
        },
        RESET(state) {
            Object.assign(state, defaultValues())
        }
    },
    actions: {
        get({ commit }) {
            getFiles().then(files=>{
                commit("SET_FILES", files)
            })
        },
        setFiles({ commit }, files) {
            commit("SET_FILES", files)
        },
        addFile({ commit }, file) {
            commit("ADD_FILE", file)
            commit("INCREMENT_IN_PROGRESS")
        },
        updateFile({ commit, state, dispatch }, file) {
            commit("UPDATE_FILE", file)

            if(file.status === state.fileStatuses.safe && (!file.progress && file.progress !== 0)) {
                dispatch("downloadFile", file)
            } else if(file.status === state.fileStatuses.malicious) {
                commit("DECREMENT_IN_PROGRESS")
            }
        },
        maliciousUpdate({ commit, dispatch, state }, obj) {
            if(obj.type === "delete") {
                dispatch("deleteFile", obj.file)
            } else if(obj.type === "keep") {
                const index = state.files.findIndex(item => item.name === obj.file.name)
                if(index !== -1) {
                    let _file = state.files[index]
                    _file.keep = true
                    commit("UPDATE_FILE", _file)
                }
            }
        },
        setStatuses({ commit }, statuses) {
            commit("SET_STATUSES", statuses)
        },
        downloadFile({}, file) {
            downloadFile(file)
        },
        deleteFile({ commit }, file) {
            deleteFile(file)
            commit("REMOVE_FILE", file)
        },
        incrementInProgress({ commit }) {
            commit("INCREMENT_IN_PROGRESS")
        },
        decrementInProgress({ commit }) {
            commit("DECREMENT_IN_PROGRESS")
        },
        reset({ commit }) {
            commit("RESET")
        }
    }
}