import store from "@/store"

export function configureTab (obj) {
    const config = store.state.config
    if (config.customTitle !== '') {
        document.title = config.customTitle
    } else {
        document.title = obj.title
    }

    if (obj.ignoreFavicon) return

    let finalFavicon = ''
    if (config.customFavicon !== '') {
        finalFavicon = config.customFavicon
    } else {
        finalFavicon = obj.faviconURL
    }

    if (finalFavicon && finalFavicon.indexOf("http://") === 0) {
        finalFavicon = finalFavicon.replace("http://", "https://")
    }
    changeFavicon(finalFavicon)
}

export function changeFavicon (favicon) {
    const links = window.parent.document.querySelectorAll("link[rel~='icon']");
    links.forEach(link => {
        link.setAttribute('href', favicon);
    });
}

export async function resize () {
    console.log("Will resize config here is ", store.state.ui)
    return await fetch(`${store.getters['general/isolatorURL']}resize`, {
        method: "POST",
        body: JSON.stringify({ width: getVideoWidth(), height: getVideoHeight(), sdp: "" })
    }).then((res) => {
        return res
    }).catch(err => err)
}

export function getVideoWidth () {
    let width = document.body.clientWidth //store.state.ui.appEl ? store.state.ui.appEl.offsetWidth : document.body.clientWidth
    width = store.state.config.allowBorder ? width - 10 : width

    return width
}

export function getVideoHeight () {
    let height = document.body.clientHeight //store.state.ui.appEl ? store.state.ui.appEl.offsetHeight : document.body.clientHeight
    height = store.state.config.allowBorder ? height - 10 : height

    if (store.state.config.taskbar === "fixed") {
        height = height - 40
    }

    return height
}