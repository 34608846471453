<template>
    <div class="taskbar-notification" v-show="show && !dontShow">
        <div class="notification-icon">
            <ve-progress v-if="type === 'downloading'"
                :progress="progress"
                :size="32"
                color="#2EA3FF">
                <slot name="legend-value">
                    <downloading-svg class="smaller" />
                </slot>
            </ve-progress>
            <ve-progress v-else-if="type === 'uploading'"
                :progress="progress"
                :size="32"
                color="#2EA3FF">
                <slot name="legend-value">
                    <uploading-svg class="smaller" />
                </slot>
            </ve-progress>
            <scanning-svg v-else-if="type === 'downloadScan' || type === 'uploadScan'" />
            <malicious-svg v-else-if="type === 'malicious'" />
        </div>

        <div class="notification-body">
            <div class="notification-title">
                {{ title }} <blinking-dots v-if="type !== 'malicious'" />
            </div>
            <div class="notification-text">{{ text }}</div>
            <div class="dont-show" v-if="timesTriggered > 1 && type !== 'malicious'">
                <label>Don't show again</label>
            </div>
        </div>

        <div class="notification-actions">
            <button @click="view">View</button>
            <button v-if="type !== 'malicious'" @click="close">Close</button>
        </div>
    </div>
</template>

<script>
import DownloadingSvg from "@/components/svg/DownloadingSvg.vue"
import UploadingSvg from "@/components/svg/UploadingSvg.vue"
import ScanningSvg from "@/components/svg/ScanningSvg.vue"
import MaliciousSvg from "@/components/svg/MaliciousSvg.vue"
import BlinkingDots from "@/components/partials/BlinkingDots.vue"
import { VeProgress } from "vue-ellipse-progress";

export default {
    components: { 
        UploadingSvg,
        DownloadingSvg,
        ScanningSvg,
        MaliciousSvg,
        BlinkingDots,
        VeProgress
    },
    props: {
        // Notification title
        title: {
            type: String,
            default: ""
        },
        // Notification text
        text: {
            type: String,
            default: ""
        },
        // Defines the type of the notification
        // Available options: ['uploading', 'downloading', 'scanning', 'malicious']
        type: {
            type: String,
            default: 'downloading'
        },
        progress: {
            type: Number,
            default: 0
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            timesTriggered: 0,
            dontShow: false
        }
    },
    methods: {
        view() {
            this.$emit('onView', this.type)
        },
        close() {
            this.$emit('onClose', this.type)
        }
    },
    watch: {
        show() {
            this.timesTriggered++
        }
    }
}
</script>