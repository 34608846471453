<template>
  <div class="interaction-container">
    <div
      class="interaction-layer"
      ref="interactionContainer"
      @mousedown="mouseDown"
      @mousemove="mouse.mouseMove"
      @mouseup="mouse.mouseUp"
      @contextmenu="mouse.contextmenu"
      @wheel="mouse.wheel"
      @touchstart="touchStart"
      @touchmove="mouse.touchHandler"
      @touchend="mouse.touchHandler"
      @touchcancel="mouse.touchHandler"
      :class="{'with-taskbar': config.taskbar !== 'hidden'}"
    >
      <div class="interaction-filler"></div>
    </div>
  </div>
</template>

<script>
import { useStore, mapState } from 'vuex'
import Mouse from "@/services/mouse"
import Keyboard from "@/services/keyboard"
import { sendCopy, sendPaste } from "@/services/clipboard"
import utils from "@/services/utils"

export default {
  data () {
    return {
      store: useStore(),
      mouse: new Mouse(),
      keyboard: new Keyboard(),
      appHeight: 0,
      windowHeight: 0
    }
  },
  computed: {
    ...mapState({
      id: state => state.general.id,
      protocol: state => state.config.primaryProtocol,
      config: state => state.config,
      interacted: state => state.ui.interacted,
      deviceOrientation: state => state.ui.deviceOrientation,
      landscapeHeight: state => state.ui.landscapeHeight,
    })
  },
  created () {
    if (utils.isMobile()) {
      if (window.innerHeight < window.innerWidth) {
        this.store.dispatch("ui/setLandscapeHeight", window.innerHeight)
      } else {
        this.store.dispatch("ui/setLandscapeHeight", window.innerWidth)
      }
    }
  },
  mounted () {
    this.store.dispatch("ui/setInteractionEl", this.$refs.interactionContainer)
    this.mouse.resetScrollPosition("x")
    this.mouse.resetScrollPosition("y")
    this.activateResize()
    this.initListeners()
  },
  unmounted() {
    window.removeEventListener("keydown", this.keyboard.handleInteraction)
    window.removeEventListener("keyup", this.keyboard.handleInteraction)
    window.removeEventListener("paste", this.processPaste)
    window.removeEventListener("copy", this.processCopy)
    window.removeEventListener("blur", this.keyboard.releaseModifiers)
    window.removeEventListener("resize", this.handleResize)
  },
  methods: {
    initListeners () {
      window.addEventListener("keydown", this.keyboard.handleInteraction)
      window.addEventListener("keyup", this.keyboard.handleInteraction)
      window.addEventListener("paste", this.processPaste)
      window.addEventListener("copy", this.processCopy)
      window.addEventListener("blur", this.keyboard.releaseModifiers)
    },
    activateResize () {
      window.addEventListener("resize", this.handleResize)
    },
    handleResize() {
        let mql

        if (screen.orientation) {
          mql = screen.orientation.type.toString()
        } else {
          mql = window.matchMedia("(orientation: portrait)")
        }

        if (this.deviceOrientation !== mql) {
          this.store.dispatch("ui/resize")
          this.store.dispatch("ui/setDeviceOrientation", mql)
        } else {
          this.store.dispatch("ui/resize")
        }

        // This is workaround for iOS, when changing to landscape it resizes two times and second time is incorrect height with 50px
        this.confirmSizes()

        this.mouse.resetScrollPosition("x")
        this.mouse.resetScrollPosition("y")
    },
    confirmSizes() {
        setTimeout(() => {
          if (window.innerHeight < window.innerWidth && this.landscapeHeight !== 0) {
            this.windowHeight = this.landscapeHeight
          } else {
            this.windowHeight = window.innerHeight
          }
          this.appHeight = document.querySelectorAll("#app")[0].clientHeight

          if (this.appHeight != this.windowHeight) {
            document.querySelectorAll("#app")[0].style.height = this.windowHeight + "px";
            document.querySelectorAll(".app-container")[0].style.height = this.windowHeight + "px";
            document.querySelectorAll("body")[0].style.height = this.windowHeight + "px";
            document.querySelectorAll("html")[0].style.height = this.windowHeight + "px";
          }
        }, 300)
    },
    touchStart (event) {
      this.emitInteraction()
      this.mouse.touchHandler(event)
    },
    mouseDown (event) {
      this.emitInteraction()
      this.mouse.mouseDown(event)
    },
    emitInteraction() {
      this.$emit('onInteraction')
    },
    processCopy (event) {
      event.stopPropagation()
      event.preventDefault()
      sendCopy(event)
    },
    processPaste (event) {
      event.stopPropagation()
      event.preventDefault()
      sendPaste(event)
    }
  }
}
</script>