import store from "@/store";
import utils from "@/services/utils"
import { availableKeys } from "../assets/js/keyboard";

export default class Keyboard {
    constructor() {
        this.alt = true;
        this.ctrl = true;
        this.shift = true;
        this.type = null
        this.key = null
        this.modifiers = []
        this.lastKeyDown = null
        this.lastKeyType = null
    }

    handleInteraction = (event) => {
        if (utils.isMobile()) {
            return
        }
        if (this.disableKeys(event)) {
            this.disabledEvent(event)
            return
        }

        // Mac sends one event for keydown and keyup
        // Windows sends one event for keydown and one for keyup
        // Ignoring the keyup allows the functionality to work on both OSs
        if(!utils.isMac() && event.code === "CapsLock" && event.type === "keyup") {
            this.lastKeyType = event.type
            this.lastKeyDown = event.key.toLowerCase()
            return
        }

        if (availableKeys.includes(event.key.toLowerCase())) {
            event.preventDefault()
            if (this.lastKeyDown === event.key.toLowerCase() && this.lastKeyType === event.type) {
                return;
            }
            this.lastKeyType = event.type
            this.lastKeyDown = event.key.toLowerCase()
        }
        this.preventDefaults(event)
        this.checkDebug(event)
        this.key = event.key
        
        // Command button is named Meta in Safari, but robotgo doesn't support it
        // Since it is alternative for control on windows, we just swap the value here
        if(this.key === "Meta") {
            this.key = "control"
        }

        this.checkIfArrow()
        this.checkType(event)
        this.checkModifiers(event)

        if (utils.isMobile()) {
            this.checkIfUpper()
        }
        this.sendInput()
    }

    checkDebug (event) {
        if (event.keyCode == 120 && event.ctrlKey && event.type == "keydown") {
            store.dispatch("config/updateMode", { name: "debug", value: true })
        }
    }

    checkIfUpper () {
        if ((isNaN(this.key * 1)) && (this.key.length == 1)) {
            if (this.key.match(/[a-z]/i) && this.key.charAt(this.key.length - 1) == this.key.charAt(this.key.length - 1).toUpperCase()) {
                this.modifiers.push("shift");
                this.key.toUpperCase();
            }
            else {
                this.modifiers = [];
            }
        } else {
            this.modifiers = [];
        }
    }

    checkType (event) {
        if (event.type === "keydown") {
            this.type = "down"
        } else if (event.type === "keyup") {
            this.type = "up"
        } else {
            this.type = event.type
        }
    }

    checkIfArrow () {
        switch (this.key) {
            case 'ArrowUp': this.key = "up";
                break;
            case 'ArrowDown': this.key = "down";
                break;
            case 'ArrowLeft': this.key = "left";
                break;
            case 'ArrowRight': this.key = "right";
                break;
        }
    }

    checkModifiers (event) {
        if (this.type === "up") {
            var indexOf;
            if ((!event.ctrlKey || !(event.key.toLowerCase() === "control")) && !this.ctrl) {
                this.ctrl = true;
                indexOf = this.modifiers.indexOf("ctrl");
                this.modifiers.splice(indexOf, 1);
            }
            if (!event.altKey && !this.alt) {
                this.alt = true;
                indexOf = this.modifiers.indexOf("alt");
                this.modifiers.splice(indexOf, 1);
            }
            if (!event.shiftKey && !this.shift) {
                this.shift = true;
                indexOf = this.modifiers.indexOf("shift");
                this.modifiers.splice(indexOf, 1);
            }
        } else if (this.type === "down") {
            if ((event.ctrlKey || event.key.toLowerCase() === "control") && this.ctrl) {
                this.ctrl = false;
                this.modifiers.push("ctrl")
            }
            if (event.altKey && this.alt) {
                this.alt = false;
                this.modifiers.push("alt")
            }
            if (event.shiftKey && this.shift) {
                this.shift = false;
                this.modifiers.push("shift")
            }
        }
    }

    releaseModifiers = () => {
        this.lastKeyDown = null
        this.lastKeyType = null

        const message = {
            type: "blur",
            body: {
                ctrl: "false",
                alt: "false",
                shift: "false"
            }
        }
        store.dispatch("comms/sendMessage", message)
    }

    disabledEvent (event) {
        if (event.stopPropagation) {
            event.stopPropagation();
        } else if (window.event) {
            window.event.cancelBubble = true;
        }
        event.preventDefault();

        return false;
    }

    disableKeys (event) {

        // CTRL + SHIFT + "C" key
        if (event.ctrlKey && event.shiftKey && event.keyCode == 67) {
            return true
        }
        // "B" key
        if (event.ctrlKey && event.keyCode == 66) {
            return true
        }
        // CTRL + SHIFT + "I" key
        if (event.ctrlKey && event.shiftKey && event.keyCode == 73) {
            return true
        }
        // "J" key
        if (event.ctrlKey && event.shiftKey && event.keyCode == 74) {
            return true
        }
        // "R" key
        if (event.ctrlKey && event.keyCode == 82) {
            return true
        }
        // "S" key + macOS
        if (event.keyCode == 83 && (navigator.platform.match("Mac") ? event.metaKey : event.ctrlKey)) {
            return true
        }
        // "U" key
        if (event.ctrlKey && event.keyCode == 85) {
            return true
        }
        //CTRL + "Q" key
        if (event.ctrlKey && event.keyCode == 81) {
            return true
        }
        // "F5" key
        if (event.keyCode == 116) {
            return true
        }
        // "F12" key
        if (event.keyCode == 123) {
            return true
        }
    }

    preventDefaults (event) {
        if (event.ctrlKey && event.keyCode == 84) {
            //console.log("Hey! Ctrl+T event captured!");
            event.preventDefault();
        }
        if (event.ctrlKey && event.keyCode == 83) {
            //console.log("Hey! Ctrl+S event captured!");
            event.preventDefault();
        }
        //Tab key ignore default
        if (event.keyCode == 9) {
            event.preventDefault();
        }
        // Slash or single quote, for mozzila quick search
        if (event.keyCode == 191 || event.keyCode == 222) {
            event.preventDefault();
        }
    }

    sendInput () {
        const message = {
            type: "keyboard",
            body: {
                key: this.key,
                modifiers: this.modifiers,
                type: this.type
            }
        }
        store.dispatch("comms/sendMessage", message)
    }
}