import store from "@/store"

class WebSocketStream {
    constructor() {
        this.player = null;
        this.canvas = null;
    }

    startStream (video = true, audio = true) {
        fetch(`${store.getters['general/isolatorURL']}start_wss_stream`, {
            method: "POST",
            body: JSON.stringify({ video, audio })
        })
            .then(response => response.json())
            .then(() => {
                this.initPlayer()
            })
            .catch(err => {
                console.log("Error from that is ", err)
            })
    }

    initPlayer () {
        // eslint-disable-next-line
        var jsmpeg = JSMpeg

        this.canvas = document.getElementById("video-canvas")
        if (!this.canvas) {
            setTimeout(() => {
                this.initPlayer()
            }, 50)

            return
        }

        store.dispatch("ui/setVideoEl", this.canvas)

        var url = store.getters["general/wsAddr"] + 's';
        // eslint-disable-next-line
        this.player = new jsmpeg.Player(url, { canvas: this.canvas, videoBufferSize: 8192 * 1024 });
    }
}

export const WSSStream = new WebSocketStream()