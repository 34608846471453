import { CONFIG } from "@/assets/js/config"
import { overrideConsole } from '@/services/logging';
import { changeFavicon } from "@/services/ui";

const defaultState = () => {
  return { ...CONFIG }
}

export default {
  state: defaultState(),
  namespaced: true,
  mutations: {
    SET_CONFIG (state, config) {

      for (const property in config) {
        const mode = config[property]
        if (mode === "true" || mode === "false") {
          state[property] = JSON.parse(mode)
        } else {
          state[property] = config[property]
        }
      }
      state.init = true

      if (config.coturn) {
        state.coturnClient = config.coturn.client
      }

      if (state.customTitle) {
        document.title = state.customTitle
      }

      if (state.customFavicon) {
        changeFavicon(state.customFavicon)
      }
    },
    SET_PROTOCOL (state, protocol) {
      state.primaryProtocol = protocol
    },
    UPDATE_MODE (state, pair) {
      state[pair.name] = pair.value
    },
    RESET_CONFIG (state) {
      Object.assign(state, defaultState())
    }
  },
  actions: {
    async get ({ commit, state, rootGetters }) {
      if (!state.init) {
        fetch(rootGetters['general/href'] + 'config')
          .then(response => response.json())
          .then(res => {
            commit('SET_CONFIG', res.data)
          })
      }
      overrideConsole()
    },
    setProtocol ({ commit }, protocol) {
      commit('SET_PROTOCOL', protocol)
    },
    updateMode ({ commit }, pair) {
      commit('UPDATE_MODE', pair)
    },
    reset ({ commit }) {
      commit('RESET_CONFIG')
    }
  }
}