<template>
    <div class="information-container">
        
        <div class="default-loading-container">
            
            <div class="background-loading-container" v-if="backgroundUrl!='' && !busy">
                <img :src="backgroundUrl" class="bgImage">
            </div>
            <div v-else>
                <img v-if="loadingImage !== ''" :src="loadingImage" :placeholder="''" class="logo">
                <div class="dots-loading">
                    <span/><span/><span/>
                </div>

                <div id="loading_div_text">
                    <div v-if="offline">
                        <h2>Please check your internet connection</h2>
                    </div>
                    <div v-else-if="!busy">
                        <h1 class="loading_securing" v-if="loadingText !== '' && !busy" v-html="loadingText"></h1>
                        <p class="loading_information">This process is automatic. Your browser will redirect to your requested content shortly.</p>
                        <p class="loading_time">Please allow up to 5 seconds...</p>
                        <p class="loading_protection" v-if="loadingFooter !== ''" v-html="loadingFooter"></p>
                    </div>
                    <div v-else>
                        <h1>Please wait, this might take a while</h1>
                        <p class="loading_information">Our servers are currently busy, we will redirect you as soon as possible</p>
                        <p class="loading_time">Thank you for your patience</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore, mapState, mapGetters } from "vuex"
export default {
    data() {
        return {
            store: useStore()
        }
    },
    computed: {
        ...mapGetters({
            domainValues: 'general/domainValues'
        }),
        ...mapState({
            busy(state) {
                return !state.general.id && (state.general.lastIDGet > state.general.firstIDGet + 5000)
            },
            resizing: state => state.ui.resizing,
            loadingImage (state) {
                return state.general.production ? this.domainValues.loadingImage : ""
            },
            loadingText (state) {
                return state.general.production ? this.domainValues.loadingText : ""
            },
            customTitle (state) {
                return state.general.production ? this.domainValues.customTitle : ""
            },
            loadingFooter (state) {
                return state.general.production ? this.domainValues.loadingFooter : ""
            },
            backgroundUrl (state) {
                return state.general.production ? this.domainValues.backgroundUrl : ""
            },
            offline: state => state.general.connection === "offline"
        })
    },
    methods: {
        startVideo() {
            this.store.dispatch("ui/setInteracted", true)
        }
    },
    mounted() {
    }
}
</script>