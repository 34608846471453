import store from "@/store"
import { sendIdentification, pingExtension, processMessage, checkServer, keepAlive } from "@/services/comms"

export default class WSS {
    constructor() {
        this.conn = null;
        this.forcedAttempts = 0;
        this.reconnectCounter = 0;
        this.maxTimesReset = 5;
        this.initialized = false;
        this.forceSendTimeout = null;

        this.init()
    }

    init () {
        if(!store.state.general.id) {
            return
        }

        this.conn = new WebSocket(store.getters['general/wsAddr'])

        this.conn.onopen = () => this.onOpen()
        this.conn.onclose = (ev) => this.onClose(ev)
        this.conn.onmessage = (msg) => processMessage(msg)
    }

    onOpen () {
        sendIdentification()
        keepAlive()
        pingExtension()

        this.initialized = true
        store.dispatch("general/setConnection", "online")
    }

    onClose (ev) {
        console.log("THE WS ON CLOSE IS CALLED")
        setTimeout(() => {
            if(store.state.general.id) {
                checkServer().then(res => {
                    console.log("Res from check server is ", res)
                    this.init()
                })
            }
        }, 1000)
        if (ev.reason === "close") {
            this.initialized = false;
            return
        }
    }

    sendMessage (msg) {
        if (this.conn.readyState === this.conn.OPEN) {
            this.conn.send(JSON.stringify(msg))
            this.forcedAttempts = 0;
        } else {
            this.forceSendMessage(msg)
        }
    }

    forceSendMessage (msg) {
        const self = this;
        this.forcedAttempts++;

        if(!store.state.general.id) {
            clearTimeout(this.forceSendTimeout)
            return
        }

        if (store.state.general.expired || store.state.general.connection === "offline") {
            clearTimeout(this.forceSendTimeout)
            return
        }

        if (this.forcedAttempts <= 20) {
            this.forceSendTimeout = setTimeout(function () {
                self.sendMessage(msg)
            }, 100)
        } else {
            clearTimeout(this.forceSendTimeout)
            console.log("Tried to send a message for 2 seconds, but connection is not open yet. Aborting message")
        }
    }
}