import { uploadFile, getUploads, processQueue } from "@/services/uploads"

const defaultValues = () => {
    return {
        files: [],
        uploadingfiles: [],
        fileStatuses: {},
        inProgress: 0,
        queue: [],
        queueIndex: 0,
        queueSize: 0
    }
}

export default {
    namespaced: true,
    state: defaultValues(),
    getters: {},
    mutations: {
        SET_FILES(state, files) {
            if (files) {
                state.files = files
            }
        },
        ADD_FILE(state, file) {
            state.files.push(file)
        },
        ADD_UPLOADING_FILE(state, file) {
            state.uploadingfiles.push(file)
        },
        UPDATE_FILE(state, file) {
            const index = state.uploadingfiles.findIndex(item => item.name === file.name)
            const files = Object.assign([], state.uploadingfiles)
            state.uploadingfiles = files
            if (file.status != 3) {
                const originalindex = state.files.findIndex(item => item.name === file.name)
                const originalfiles = Object.assign([], state.files);
                originalfiles[originalindex] = file
                state.files = originalfiles
                state.uploadingfiles.splice(index, 1)
            }
        },
        REMOVE_FILE(state, filename) {
            var index = state.files.findIndex(item => item.name === filename)
            state.files.splice(index, 1)
        },
        INCREMENT_IN_PROGRESS(state) {
            state.inProgress++
        },
        DECREMENT_IN_PROGRESS(state) {
            state.inProgress--
        },
        UPDATE_QUEUE(state, files) {
            state.queue = files
        },
        UPDATE_QUEUE_SIZE(state, size) {
            state.queueSize = size
        },
        UPDATE_QUEUE_INDEX(state, index) {
            state.queueIndex = index
        },
        SET_STATUSES(state, statuses) {
            state.fileStatuses = statuses
        },
        RESET(state) {
            Object.assign(state, defaultValues())
        }
    },
    actions: {
        get({ commit }) {
            getUploads().then(files => {
                commit("SET_FILES", files)
            })
        },
        setFiles({ commit }, files) {
            commit("SET_FILES", files)
        },
        addFile({ commit }, file) {
            commit("ADD_FILE", file)
        },
        addUploadingFile({ commit }, file) {
            commit("ADD_UPLOADING_FILE", file)
        },
        updateFile({ commit }, file) {
            commit("UPDATE_FILE", file)
        },
        removeFile({ commit }, file) {
            commit("DELETE_FILE", file)
        },
        incrementInProgress({ commit }) {
            commit("INCREMENT_IN_PROGRESS")
        },
        decrementInProgress({ commit }) {
            commit("DECREMENT_IN_PROGRESS")
        },
        uploadFile({ commit }, file) {
            commit("INCREMENT_IN_PROGRESS")
            uploadFile(file)
        },
        updateQueue({ commit }, files) {
            commit("UPDATE_QUEUE", files)
            const size = files.reduce(function (a, b) { console.log("Current content size is ", b.content.size); return a + b.content.size }, 0)
            commit("UPDATE_QUEUE_SIZE", size)
        },
        updateQueueIndex({ commit }, index) {
            commit("UPDATE_QUEUE_INDEX", index)
        },
        setStatuses({ commit }, statuses) {
            commit("SET_STATUSES", statuses)
        },
        processQueue({ }) {
            processQueue()
        },
        reset({ commit }) {
            commit("RESET")
        }
    }
}