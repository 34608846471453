<template>
  <div class="app-container" v-show="init" ref="app">
    <expired v-if="expired" @launch="launchProtection" />
    <home-component v-else-if="!reverseProxy && !launch && !id" @launch="launchProtection" />
    <stream-component v-else-if="reverseProxy || launch || id" />
  </diV>
</template>

<script>
import { useStore, mapState } from 'vuex'

import Expired from "@/views/Expired"
import HomeComponent from "@/views/HomeComponent"
import StreamComponent from "@/views/StreamComponent"

export default {
  name: "App",
  components: {
    Expired,
    HomeComponent,
    StreamComponent
  },
  data() {
    return {
      store: useStore(),
      launch: false
    }
  },
  created() {
    this.store.dispatch('config/get')
    this.preventBack()
  },
  mounted() {
    console.log("Will set app el")
    this.store.dispatch("ui/setAppEl", this.$refs.app)
  },
  methods: {
    launchProtection(val) {
      this.launch = val
    },
    preventBack() {
      var self = this
      history.pushState(null, null, "");
      const message = {
        to: "extension",
        type: "backbutton"
      }
      window.addEventListener("popstate", function () {
        history.pushState(null, null, "");
        self.store.dispatch("comms/sendMessage", message)
        
        if(self.store.state.config.customTitle) {
          document.title = location.href
          document.title = self.store.state.config.customTitle
        }
      });
    }
  },
  computed: {
    ...mapState({
      expired: state => state.general.expired,
      reverseProxy: state => state.config.reverseProxy,
      init: state => state.config.init,
      id: state => state.general.id
    })
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
