<template>
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer" enable-background="new 0 0 512 512" height="512px" viewBox="0 0 512 512" width="512px">
        <g>
            <path d="m458 88h-404c-29.776 0-54 24.224-54 54v228c0 29.776 24.224 54 54 54h404c29.775 0 54-24.224 54-54v-228c0-29.776-24.225-54-54-54zm6 282c0 3.309-2.691 6-6 6h-404c-3.309 0-6-2.691-6-6v-228c0-3.309 2.691-6 6-6h404c3.309 0 6 2.691 6 6z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#BABABA"/>
            <path d="m328 296h-144c-13.255 0-24 10.745-24 24s10.745 24 24 24h144c13.255 0 24-10.745 24-24s-10.745-24-24-24z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#BABABA"/>
            <path d="m120 296h-32c-13.255 0-24 10.745-24 24s10.745 24 24 24h32c13.255 0 24-10.745 24-24s-10.745-24-24-24z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#BABABA"/>
            <path d="m136 232h-16c-13.255 0-24 10.745-24 24s10.745 24 24 24h16c13.255 0 24-10.745 24-24s-10.745-24-24-24z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#BABABA"/>
            <path d="m184 256c0 13.255 10.745 24 24 24h16c13.255 0 24-10.745 24-24s-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#BABABA"/>
            <path d="m264 256c0 13.255 10.745 24 24 24h16c13.255 0 24-10.745 24-24s-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#BABABA"/>
            <path d="m392 280c13.255 0 24-10.745 24-24s-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24s10.745 24 24 24z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#BABABA"/>
            <path d="m88 216h16c13.255 0 24-10.745 24-24s-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24s10.745 24 24 24z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#BABABA"/>
            <path d="m168 216h16c13.255 0 24-10.745 24-24s-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24s10.745 24 24 24z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#BABABA"/>
            <path d="m328 216h16c13.255 0 24-10.745 24-24s-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24s10.745 24 24 24z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#BABABA"/>
            <path d="m248 216h16c13.255 0 24-10.745 24-24s-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24s10.745 24 24 24z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#BABABA"/>
            <path d="m424 168h-16c-13.255 0-24 10.745-24 24s10.745 24 24 24h16c13.255 0 24-10.745 24-24s-10.745-24-24-24z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#BABABA"/>
            <path d="m424 296h-32c-13.255 0-24 10.745-24 24s10.745 24 24 24h32c13.255 0 24-10.745 24-24s-10.745-24-24-24z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#BABABA"/>
        </g>
    </svg>
</template>

<script>
export default {
}
</script>