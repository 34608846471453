import Swal from "sweetalert2"
import store from "@/store"
import { checkServer } from "./comms"

class SweetAlert {
    confirm(title, confirmText, cancelText, confirmFn, cancelFn) {
        Swal.fire({
            title,
            showCancelButton: true,
            confirmButtonText: confirmText,
            cancelButtonText: cancelText,
            customClass: {
                title: 'swal-confirm-title'
            }
        }).then((result) => {
            if(result.isConfirmed) {
                confirmFn()
            } else if(result.isDenied) {
                cancelFn()
            }
        })
    }

    expiredPageCountdown() {
        let timeInterval
    
        Swal.fire({
            title: "Warning",
            html: 'Your session is about to expire in  <b></b> seconds. <br> Click "Continue" to keep working.',
            showCancelButton: false,
            confirmButtonColor: '#008000',
            confirmButtonText: 'Continue',
            timer: 15000,
            didOpen: () => {
                const b = Swal.getHtmlContainer().querySelector('b')
                timeInterval = setInterval(() => {
                  b.textContent = (Swal.getTimerLeft() / 1000)
                      .toFixed(0)
                }, 100)
            },
            willClose: () => {
                clearInterval(timeInterval)
            }
        }).then((result) => {
            if (result.isConfirmed) {
                const message = { type: "cancelExpiration" }
                store.dispatch("comms/sendMessage", message)
            } else if(result.isDismissed) {
                setTimeout(() => {
                    checkServer()
                }, 1000)
            }
        })
    }
}

const Sweetalert = new SweetAlert()
export default Sweetalert