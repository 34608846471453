<template>
    <div id="btn-keyboard" class="taskbar-btn">
        <keyboard-svg @click="openKeyboard" />
        <input
            :type="keyboard.type"
            id="keyboard__input"
            autocapitalize="off"
            autocomplete="new-password"
            spellcheck="false"
            autocorrect="off"
            ref="keyboard"
            :name="stamp"
            @input="changeV"
            @blur="keyboardBlur"
            @keydown.backspace="changeV"
            @keydown.enter="changeV"
        />
    </div>
</template>

<script>
import { useStore, mapState } from "vuex"
import { openKeyboard, inputValueChange } from "@/services/mobileKeyboard"

import KeyboardSvg from '@/components/svg/KeyboardSvg.vue'

export default {
    components: { 
        KeyboardSvg
    },
    data() {
        return {
            store: useStore(),
            stamp: Date.now()
        }
    },
    computed: {
        ...mapState({
            keyboard: state => state.ui.keyboard
        })
    },
    mounted() {
        this.store.dispatch("ui/setKeyboardEl", this.$refs.keyboard)
    },
    methods: {
        openKeyboard() {
            openKeyboard()
        },
        keyboardBlur() {
            if(!this.keyboard.blur) {
                if(this.$refs.keyboard) {
                    this.$refs.keyboard.focus()
                }
            }
        },
        changeV(e=null) {
            inputValueChange(e)
        }
    }
}
</script>