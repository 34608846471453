<template>
	<div class="expired-container">
		<h1>Ooops</h1>
		<h2>Looks like your session has expired.</h2>
		<expired-image-svg />
		<p>But don't worry we got your back.</p>
		<a @click="resetSession" class="btn">Start new session</a>
		<p class="split">or</p>
		<button @click="continueSession" >Continue where you left off</button>
	</div>
</template>

<script>
import { useStore } from 'vuex'
import ExpiredImageSvg from '@/components/svg/ExpiredImageSvg'

export default {
    components: {
        ExpiredImageSvg
    },
    data() {
        return {
            store: useStore()
        }
    },
    mounted() {
        this.store.dispatch('config/updateMode', {name: 'init', value: false})
        this.store.dispatch('config/get')
    },
    methods: {
        resetSession() {
            this.store.dispatch("general/resetSession")
            this.$emit('launch', false)
        },
        continueSession() {
            this.store.dispatch("general/continueSession")
            this.$emit('launch', true)
        }
    }
}
</script>