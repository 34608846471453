import store from "@/store"
import { processDownload } from "./downloads"
import { processUpload } from "./uploads"
import { handleInput, closeKeyboard } from "./mobileKeyboard"
import Sweetalert from "./sweetalert"
import { configureTab } from "./ui"
import { promptCopy } from "./clipboard"
import { WSSStream } from '@/services/wss_stream.js'

// Send identification to the BE so it can categorize our websocket client
export function sendIdentification() {
    const msg = {
        type: "identification",
        identification: store.state.general.wsIdentification
    }
    store.dispatch('comms/sendMessage', msg)
}

// Ping the extension so it can know that a user has connected
export function pingExtension() {
    setTimeout(() => {
        if (!store.state.ui.extensionLoaded) {
            const msg = {
                type: "transit",
                to: "extension",
                body: {
                    statusCheck: true
                }
            }
            store.dispatch('comms/sendMessage', msg)
            pingExtension();
        }
    }, 500)
}

// Check if the server is still open
// If it's not then close the current session as it is probably deleted
export function checkServer() {
    return new Promise((resolve, reject) => {
        if (!store.state.general.id) {
            resolve(null)
        }

        setTimeout(() => {
            if (navigator.onLine) {
                checkDispatcher().then(res => {
                    if (res) {
                        fetch(store.getters['general/isolatorURL'] + "status")
                            .then(response => response.json())
                            .then(res => {
                                console.log("CHECK SERVER RES IS ", res)
                                resolve(res)
                            })
                            .catch(err => {
                                if (!store.state.general.expired) {
                                    store.dispatch('general/setExpired', true)
                                    if (WSSStream.player) {
                                        WSSStream.player.destroy()
                                        WSSStream.player = null
                                    }
                                }
                                reject(err)
                                console.log("The server check failed", err)
                            })
                    } else {
                        console.log("The device is offline")
                        store.dispatch("general/setConnection", "offline")
                        resolve(res)
                    }
                })
            } else {
                store.dispatch("general/setConnection", "offline")
                resolve(null)
            }
        }, 100)
    })
}

async function checkDispatcher() {
    if (!store.state.general.production && store.state.general.strictLocal) {
        return true
    } else {
        return fetch("/status")
            .then(res => {
                console.log("Response from dispatcher is ", res)
                return true
            })
            .catch(err => {
                console.log("error even from dispatcher", err)
                return false
            })
    }
}

function checkIfAdminMessage() {
    // const stats = store.state.webrtc.stats
    // switch (data.adminPortalStatAction) {
    //     case 'startStats':
    //         // stats.startSending()
    //         break;
    //     case 'stopStats':
    //         // stats.stopSending()
    //         break;
    //     case 'alive':
    //         // stats.updateTime()
    //         break;
    //     default: return
    // }
}

export function keepAlive() {
    setInterval(() => {
        if (!store.state.general.expired) {
            const msg = { type: "ping" }
            store.dispatch("comms/sendMessage", msg)
        }
    }, 5000)
}

// Process websocket message contents
// All wss communication is handled here
export function processMessage(msg) {
    const data = JSON.parse(msg.data)
    const body = data.body
    if (data.to !== store.state.general.wsIdentification) {
        return
    }

    checkIfAdminMessage(data)

    if (data.type === "urlChange") {
        //iOS we cant open new tab so we open in same window.
        var iOS = !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent); // fails on iPad iOS 13
        if (iOS) {
            window.location = data.value;
        } else {
            window.open(data.value, "_blank");
        }
    }

    if (data.sdp) {
        store.state.webrtc.webrtc.setRemoteSDP(data.sdp)
    }

    if (body) {
        if (body.cursor) {
            store.state.ui.interactionEl.style.cursor = body.cursor
        }

        if (body.copy) {
            promptCopy(body)
        }

        if (body.paste) {
            console.log("Paste")
        }

        if (body.downloads) {
            console.log("WILL PROCESS DOWNLOAD ", body)
            processDownload(body)
        }

        if (body.uploads) {
            console.log("WILL PROCESS UPLOAD ", body)
            processUpload(body)
        }

        if (body.fileUpload) {
            if (store.state.config.allowUpload) {
                store.dispatch("ui/pushActivePopup", "uploads")
            }
        }

        if (body.input) {
            handleInput(body)
        }

        if (body.loaded === true) {
            closeKeyboard()

            if (body.link.includes("iam.demo.haventec") && body.link === store.state.general.lastURL) {
                store.dispatch("ui/setExtensionLoaded", true)
                store.dispatch("general/setLastURL", body.link)
                configureTab(body)
            } else if (body.link.includes("iam.demo.haventec")) {
                store.dispatch("general/setLastURL", body.link)
                window.location = "http://localhost:8080/profile"
            } else {
                store.dispatch("ui/setExtensionLoaded", true)
                store.dispatch("general/setLastURL", body.link)
                configureTab(body)
            }
        }

        if (body.notifyExpiration) {
            Sweetalert.expiredPageCountdown()
        }
    }
}