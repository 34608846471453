<template>
    <div id="btn-settings">
        <div class="settings-container" :class="{'show-settings': settings}">
            <div class="settings-group" :class="{'hide-clipboard': !config.allowClipboard}">
                <h3>Clipboard</h3>
                <div class="cc_checkbox_container">
                    <label class="checkbox">
                        <input type="checkbox" :checked="config.copyOption" v-model="config.copyOption" @change="change('copyOption')" />
                        <span></span>
                    </label>
                    <label>Copy to my pc {{config.copyOption}}</label>
                </div>
                <div class="cc_checkbox_container">
                    <label class="checkbox">
                        <input type="checkbox" :checked="config.pasteOption" v-model="config.pasteOption" @change="change('pasteOption')" />
                        <span></span>
                    </label>
                    <label>Paste from my pc {{config.pasteOption}}</label>
                </div>
            </div>

            <div v-if="config.languageChange" class="settings-group">
                <h3>Language:</h3>
                <select v-model="selectedLang" @change="langChange" @click.stop>
                    <option v-for="(name, code) in langList" :key="code" :value="code">{{name}}</option>
                </select>
            </div>

            <div class="settings-group">
                <h3>Configuration Options:</h3>
                <div class="cc_checkbox_container">
                    <label class="checkbox">
                        <input type="checkbox" :checked="config.highPerformanceMode" v-model="config.highPerformanceMode" @change="change('highPerformanceMode')" />
                        <span></span>
                    </label>
                    <label>High Precision Mode</label>
                </div>
            </div>
        </div>

        <div class="btn-container taskbar-btn" title="Settings" @click="toggleSettings">
            <settings-svg />
        </div>
    </div>
</template>

<script>
import { useStore, mapState } from 'vuex'
import { langList } from '@/assets/js/lang.js'

import SettingsSvg from '@/components/svg/SettingsSvg.vue'

export default {
    components: { 
        SettingsSvg
    },
    data() {
        return {
            store: useStore(),
            langList,
            selectedLang: "us"
        }
    },
    methods: {
        toggleSettings() {
            if(this.settings) {
                this.store.dispatch("ui/popActivePopup", "settings")
            } else {
                this.store.dispatch("ui/pushActivePopup", "settings")
            }
        },
        change(attr) {
            console.log("WILL CHANGE VALUE TO ", this.config[attr])
            this.store.dispatch("config/updateMode", {name: attr, value: this.config[attr]})
        },
        langChange() {
            const message = {
                type: "keyboard",
                body: {
                    type: "lang",
                    key: this.selectedLang
                }
            }
            this.store.dispatch("comms/sendMessage", message)
        }
    },
    computed: {
        ...mapState({
            config: state =>  state.config,
            settings: state => state.ui.activePopups.includes("settings")
        })
    }
}
</script>