import { getReadyIsolator, sendExpiredSignal } from "@/services/isolator";
import store from '@/store'


const defaultValues = () => {
  return {
    id: null,
    clientId: null,
    expired: false,
    extensionLoaded: false,
    production: process.env.NODE_ENV === "production" ? true : false,
    localURL: "http://localhost:8080/",
    localPort: "8080",
    strictLocal: true,
    lastURL: null,
    wss: null,
    wsIdentification: "webclient",
    ignoreLocal: false,
    connection: "online",
    firstIDGet: 0,
    lastIDGet: 0,
    activeStreamProtocol: "",
  };
};

export default {
  namespaced: true,
  state: defaultValues(),
  getters: {   
    href(state) {
      if (state.production) {
        return window.location.href.split("?")[0];
      } else {
        return state.localURL;
      }
    },
    protocol(state, getters) {
      return getters.href.split("//")[0] || state.href;
    },
    path(state, getters) {
      return getters.href.split("//")[1] || state.href;
    },
    wsAddr(state, getters) {
      if (!state.production && !state.ignoreLocal) {
        return `ws://localhost:${state.localPort}/ws`;
      } else {
        const path = `${getters.path}isolator/${state.id}-isolator/ws`;
        const wsProtocol = getters.protocol === "https:" ? "wss" : "ws";

        return `${wsProtocol}://${path}`;
      }
    },
    isolatorURL(state, getters) {
      if (!state.production && state.strictLocal) {
        return state.localURL;
      } else if (!state.production && !state.ignoreLocal) {
        return `${state.localURL}isolator/${state.id}-isolator/`;
      } else {
        return `${getters.href}isolator/${state.id}-isolator/`;
      }
    },
    domainValues() {
      return store.state.config;
    },
  },
  mutations: {
    SET_ID(state, id) {
      state.id = id;
    },
    SET_EXPIRED(state, expired) {
      state.expired = expired;
    },
    SET_LAST_URL(state, lastURL) {
      state.lastURL = lastURL;
    },
    SET_LOADING_ID(state, value) {
      state.loadingID = value;
    },
    RESET(state) {
      Object.assign(state, defaultValues());
    },
    SET_CONNECTION(state, connection) {
      state.connection = connection;
    },
    SET_FIRST_ID_GET(state) {
      state.firstIDGet = Date.now();
    },
    SET_LAST_ID_GET(state) {
      state.lastIDGet = Date.now();
    },
    SET_ACTIVE_STREAM_PROTOCOL(state, protocol) {
      state.activeStreamProtocol = protocol;
    },
    SET_CLIENT_ID(state, id) {
        state.clientId = id;
    }
  },
  actions: {
    getIsolator({ commit, dispatch, state, rootState }) {
      commit("SET_LOADING_ID", true);

      if (state.id) {
        dispatch("comms/openWSS", false, { root: true });
      } else {
        getReadyIsolator()
          .then((id) => {
            if (!id) {
              setTimeout(() => {
                dispatch("getIsolator");
              }, 1000);
            } else {
              commit("SET_ID", id);
              if (state.lastURL && state.lastURL !== rootState.config.url) {
                sendExpiredSignal();
              }
              dispatch("comms/openWSS", false, { root: true });
            }
          })
          .catch((err) => {
            console.log("It went in the errors ", err);
          })
          .finally(() => {
            commit("SET_LOADING_ID", false);
          });
      }
    },
    setID({ commit }, id) {
      commit("SET_ID", id);
    },
    setLastURL({ commit }, lastURL) {
      commit("SET_LAST_URL", lastURL);
    },
    setExpired({ commit }, value) {
      commit("SET_EXPIRED", value);
    },
    resetSession({ commit, dispatch }) {
      dispatch("resetState", false, { root: true });

      commit("RESET");
    },
    continueSession({ commit, dispatch, state }) {
      const URL = state.lastURL;
      commit("RESET");
      commit("SET_LAST_URL", URL);
      dispatch("resetState", false, { root: true });
    },
    setConnection({ commit }, connection) {
      commit("SET_CONNECTION", connection);
    },
    setFirstIDGet({ commit }) {
      commit("SET_FIRST_ID_GET");
    },
    setLastIDGet({ commit }) {
      commit("SET_LAST_ID_GET");
    },
    setLoadingID({ commit }) {
      commit("SET_LOADING_ID");
    },
    setActiveStreamProtocol({ commit }, protocol) {
      commit("SET_ACTIVE_STREAM_PROTOCOL", protocol);
    },
    setClientId({ commit }, id) {
        commit("SET_CLIENT_ID", id);
    }
  },
};
