<template>
	<div class="home">
		<div class="home-container">
			<img src="../assets/images/logo_inverse.png" class="logo-img" />

			<h3 class="home-header">
				<span>Protect.</span> <span>Contain.</span> <span>Virtualise.</span>&nbsp;<span>Zero Trust.</span>
			</h3>

			<div class="home-text">
				<p>
					We provide secure browsing without any harmful content reaching your computer.
				</p>
			</div>

			<div class="agent-box" id="agent-box">
				<input type="checkbox" id="kiosk" v-model="kiosk" />
				<label for="kiosk">Kiosk</label>

				<input type="checkbox" id="vnc" v-model="vnc" />
				<label for="vnc">VNC</label>
			</div>

			<div class="url-box" id="url-box">
				<div class="dropdown">
					<button class="dropbtn" id="protocol">{{ selectedProtocol }}</button>
					<div class="dropdown-content">
						<p
							@click="selectedProtocol = 'http://'"
							:class="{ 'selected-protocol': selectedProtocol == 'http://' }"
							>http://
                        </p>
						<p
							@click="selectedProtocol = 'https://'"
							:class="{ 'selected-protocol': selectedProtocol == 'https://' }"
							>https://
                        </p>
					</div>
				</div>

				<input
					type="text"
					id="url-input"
					placeholder="Enter URL.."
					:class="{ 'invalid-url': invalidURL }"
					v-model="url"
					v-on:keyup.enter="start"
					autofocus
				/>
				<button type="button" @click="start()" class="home-btn" id="browse">
					Browse safely
				</button>
			</div>

			<p class="invalid-msg" id="invalid-msg" v-show="invalidURL">
				* Please enter valid URL
			</p>
		</div>
	</div>
</template>

<script>
import { useStore, mapState } from 'vuex'
import utils from "@/services/utils"

export default {
    data() {
        return {
            store: useStore(),
            selectedProtocol: 'https://',
            invalidURL: false
        }
    },
    created() {
        if (this.url == "") {
            console.log("URL is empty");
        } else {
            if (this.url.includes("https://")) {
                this.url = this.url.replace("https://", "")
            } else if (this.url.includes("http://")) {
                this.url = this.url.replace("http://", "")
            }
        }
    },
    methods: {
        start() {
            const url = this.selectedProtocol + this.url
            const valid = utils.validateURL(url)

            if(valid) {
                this.invalidURL = false
                this.updateMode('url', url)
                this.$emit('launch', true)
            } else {
                this.invalidURL = true
            }
        },
        updateMode(name, value) {
            this.store.dispatch('config/updateMode', {name, value})
        }
    },
    computed: {
        ...mapState({
            config: state => state.config
        }),
        url: {
            get() {
                return this.config.url
            },
            set(value) {
                return this.updateMode('url', value)
            }
        },
        bitrate: {
            get() {
                return this.config.bitrate
            },
            set(value) {
                this.updateMode('bitrate', value)
            }
        },
        kiosk: {
            get() {
                return this.config.kiosk
            },
            set(value) {
                this.updateMode('kiosk', value)
            }
        },
        vnc: {
            get() {
                return this.config.primaryProtocol === "novnc" ? true : false
            },
            set(value) {
                this.store.dispatch('config/setProtocol', value ? "novnc" : "webrtc")
            }
        }
    }
}
</script>