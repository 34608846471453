<template>
  <div
    class="taskbar-notifications-container"
    v-show="!otherActivePopups"
    :class="{ 'hidden-taskbar': config.taskbar }"
  >
    <notification
      v-show="showDownloadScan"
      title="Scanning downloads"
      :text="scanningDownloadsText"
      type="downloadScan"
      class="scanning"
      @onClose="closeNotification('downloadScan')"
      @onView="viewFiles"
    />

    <notification
      v-show="showUploadScan"
      title="Scanning uploads"
      :text="scanningUploadsText"
      type="uploadScan"
      class="scanning"
      @onClose="closeNotification('uploadScan')"
      @onView="viewFiles"
    />

    <notification
      v-show="showMalicious"
      title="Malicious files found"
      :text="maliciousText"
      type="malicious"
      class="malicious"
      @onClose="closeNotification('malicious')"
      @onView="viewFiles"
    />

    <notification
      v-show="showDownloading"
      title="Downloading"
      :text="downloadingText"
      type="downloading"
      class="downloading"
      :progress="downloadProgress"
      @onClose="closeNotification('downloading')"
      @onView="viewFiles"
    />

    <notification
      v-show="showUploading"
      title="Uploading"
      :text="uploadingText"
      type="uploading"
      class="uploading"
      :progress="uploadProgress"
      @onClose="closeNotification('uploading')"
      @onView="viewFiles"
    />
  </div>
</template>

<script>
import { useStore, mapState } from "vuex";
import Notification from "./Notification.vue";

export default {
  components: {
    Notification,
  },
  data() {
    return {
      store: useStore(),
      showDownloadsList: false,
      downloadsNotification: false,
      activeNotifications: [],
    };
  },
  computed: {
    ...mapState({
      config: (state) => state.config,
      statuses: (state) => state.downloads.fileStatuses,
      taskbarNotifications: (state) => state.ui.taskbarNotifications,
      uploads(state) {
        let uploads = state.uploads.files.filter((file) => {
          return file.status === this.statuses.waiting;
        });
        return uploads;
      },
      downloading(state) {
        let downloading = state.downloads.files.filter(
          (file) => file.downloading === true && file.progress < 100
        );
        return downloading;
      },
      malicious(state) {
        let malicious = state.downloads.files.filter(
          (file) => file.status === this.statuses.malicious
        );
        return malicious;
      },
      scanningDownloads(state) {
        let scanning = state.downloads.files.filter(
          (file) =>
            file.status === this.statuses.scanning ||
            file.status === this.statuses.waiting
        );
        return scanning;
      },
      scanningUploads(state) {
        let scanning = state.uploads.files.filter(
          (file) =>
            file.status === this.statuses.scanning ||
            file.status === this.statuses.safe
        );
        return scanning;
      },
      otherActivePopups(state) {
        if (state.ui.activePopups.includes("files")) {
          this.store.dispatch("ui/clearTaskbarNotifications");
        }
        return state.ui.activePopups.length > 0;
      },
    }),
    downloadProgress() {
      if (this.downloading.length > 0) {
        let progress = 0;
        this.downloading.map((file) => {
          progress += file.progress;
        });
        return progress / this.downloading.length;
      } else {
        return null;
      }
    },
    uploadProgress() {
      if (this.uploads.length > 0) {
        let progress = 0;
        this.uploads.map((file) => {
          progress += file.progress;
        });
        return progress / this.uploads.length;
      } else {
        return null;
      }
    },
    showDownloading() {
      console.log("showDownloading", this.taskbarNotifications);
      return (
        this.taskbarNotifications.includes("downloading") &&
        !this.otherActivePopups &&
        this.downloading.length > 0
      );
    },
    showUploading() {
      console.log("showUploading", this.taskbarNotifications);
      return (
        this.taskbarNotifications.includes("uploading") &&
        !this.otherActivePopups &&
        this.uploads.length > 0
      );
    },
    showMalicious() {
      console.log("showMalicious", this.taskbarNotifications);
      return (
        this.taskbarNotifications.includes("malicious") &&
        !this.otherActivePopups &&
        this.malicious.length > 0
      );
    },
    showDownloadScan() {
      console.log("showDownloadScan", this.taskbarNotifications);
      console.log("Length ", this.scanningDownloads.length);
      console.log(
        "The taskbar notifications ",
        this.taskbarNotifications.includes("downloadScan")
      );
      return (
        this.taskbarNotifications.includes("downloadScan") &&
        !this.otherActivePopups &&
        this.scanningDownloads.length > 0
      );
    },
    showUploadScan() {
      return (
        this.taskbarNotifications.includes("uploadScan") &&
        !this.otherActivePopups &&
        this.scanningUploads.length > 0
      );
    },
    uploadingText() {
      return this.calculateFileText(this.uploads.length) + " being uploaded";
    },
    downloadingText() {
      return (
        this.calculateFileText(this.downloading.length) + " being downloaded"
      );
    },
    maliciousText() {
      let text = this.malicious.length + " malicious file";
      if (this.malicious.length > 1) text += "s";

      return (text += " found");
    },
    scanningDownloadsText() {
      return (
        this.calculateFileText(this.scanningDownloads.length) + " being scanned"
      );
    },
    scanningUploadsText() {
      return (
        this.calculateFileText(this.scanningUploads.length) + " being scanned"
      );
    },
  },
  methods: {
    closeNotification(type) {
      this.store.dispatch("ui/popTaskbarNotification", type);
    },
    toggleDownloadsList() {
      this.showDownloadsList = !this.showDownloadsList;
    },
    dismissDownloads() {
      this.downloadsNotification = false;
    },
    handleMalicious(file, type) {
      this.store.dispatch("downloads/maliciousUpdate", { file, type });
    },
    calculateFileText(num) {
      if (num === 1) {
        return "1 file is";
      } else {
        return num + " files are";
      }
    },
    pushTaskbarNotification(type) {
      this.store.dispatch("ui/pushTaskbarNotification", type);
    },
    viewFiles() {
      this.store.dispatch("ui/pushActivePopup", "files");
    },
  },
  watch: {
    uploads(newVal, oldVal) {
      if (newVal.length > oldVal.length) {
        this.pushTaskbarNotification("uploading");
      }
    },
    downloading(newVal, oldVal) {
      if (newVal.length > oldVal.length) {
        this.pushTaskbarNotification("downloading");
      }
    },
    malicious(newVal, oldVal) {
      if (newVal.length > oldVal.length) {
        this.pushTaskbarNotification("malicious");
      }
    },
    scanningDownloads(newVal, oldVal) {
      if (newVal.length > oldVal.length) {
        this.pushTaskbarNotification("downloadScan");
      }
    },
    scanningUploads(newVal, oldVal) {
      if (newVal.length > oldVal.length) {
        this.pushTaskbarNotification("uploadScan");
      }
    },
  },
};
</script>