<template>
  <div
    class="taskbar-container"
    :class="[
      config.taskbar + '-taskbar',
      { 'show-taskbar': showTaskbar && config.taskbar !== 'hidden' },
    ]"
    @mouseover="triggerTaskbar"
    @mouseleave="cleanTaskbar"
  >
    <div class="t-btn-holder">
      <div class="left-container">
        <btn-copy />
        <btn-paste />
        <!-- <btn-fallback /> -->
        <btn-keyboard v-if="$isMobile" />
      </div>

      <div class="right-container">
        <btn-downloads />
        <btn-fullscreen />
        <btn-settings />
      </div>
    </div>

    <clipboard-message />
    <taskbar-notifications />
  </div>
</template>

<script>
import BtnCopy from "./buttons/BtnCopy";
import BtnPaste from "./buttons/BtnPaste";
// import BtnFallback from "./buttons/BtnFallback"
import BtnKeyboard from "./buttons/BtnKeyboard";
import BtnDownloads from "./buttons/BtnDownloads";
import BtnSettings from "./buttons/BtnSettings";
import BtnFullscreen from "./buttons/BtnFullscreen";
import ClipboardMessage from "./ClipboardMessage";
import TaskbarNotifications from "./TaskbarNotifications.vue";

import { useStore, mapState } from "vuex";

export default {
  components: {
    BtnCopy,
    BtnPaste,
    // BtnFallback,
    BtnKeyboard,
    BtnDownloads,
    BtnSettings,
    BtnFullscreen,
    ClipboardMessage,
    TaskbarNotifications,
  },
  data() {
    return {
      store: useStore(),
      showTaskbar: true,
    };
  },
  created() {
    console.log("The is mobile is ", this.$isMobile);
    console.log("config.taskbar ", this.$config.taskbar);
  },
  computed: {
    ...mapState({
      config: (state) => state.config,
    }),
  },
  methods: {
    triggerTaskbar() {},
    cleanTaskbar() {},
  },
};
</script>