import store from "@/store"

export const fileTemplate = {
    content: null,
    type: null,
    progressPercent: 0,
    status: 0,
    error: null,
    hideLoader: false
}

export function processUpload(obj) {
    if(!store.state.config.allowUpload) return
    
        switch(obj.type) {
            case 'all':
                store.dispatch('uploads/setFiles', obj.files)
                break;
            case 'add':
                store.dispatch('uploads/addFile', obj.file)
                break;
            case 'update':
                console.log("HERE THE FILE IS ", obj.file)
                store.dispatch('uploads/updateFile', obj.file)
                break;
        }
    }

export async function getUploads() {
    return await fetch(`${store.getters['general/isolatorURL']}uploads`)
        .then(response => response.json())
        .then(res => {
            if (res) {
                return res
            } else {
                return []
            }
        })
}

export function uploadFile(file) {
    const body = new FormData();
    const statuses = store.state.uploads.fileStatuses;
    console.log("WILL UPLOAD FILE ", file)
    body.append('file', file.content)
    file.ajax = new XMLHttpRequest()
    file.ajax.open("POST", store.getters['general/isolatorURL'] + 'upload')
    file.ajax.setRequestHeader("Accept", "multipart/form-data")
    file.status = statuses.waiting;

    store.dispatch("uploads/addUploadingFile", preparedFile(file))
    updateFileProgress(file)

    file.ajax.onreadystatechange = function () {
        if (this.readyState === 4) {
            if (this.status === 200) {
                file.error = null
            } else if (this.status === 413) {
                store.dispatch("uploads/decrementInProgress")
                file.error = "Maximum upload size is 256MB"
                file.status = statuses.failed
                updateFileProgress(file)
            } else {
                store.dispatch("uploads/decrementInProgress")
                file.error = "Error while uploading"
                file.status = statuses.failed
                updateFileProgress(file)
            }
        }
    }

    file.ajax.upload.onprogress = function (event) {
        const percent = parseInt((event.loaded / event.total) * 100)
        if (percent % 2 === 0) {
            file.progressPercent = percent;
            updateFileProgress(file)
        }
    }

    file.ajax.upload.onload = function () {
        store.dispatch("uploads/decrementInProgress")
        processQueue()
        updateFileProgress(file)
    }

    file.ajax.send(body)
}

export function processQueue() {
    const uploads = store.state.uploads
    if (uploads.queueIndex !== 0 && uploads.queue.length <= uploads.queueIndex && uploads.inProgress === 0) {
        store.dispatch("uploads/updateQueue", [])
        store.dispatch("uploads/updateQueueIndex", 0)
        store.dispatch("ui/popActivePopup", "uploads")
        return
    }

    if (uploads.queueIndex === 0) {
        for (var i = 0; i < 3; i++) {
            uploadFileFromQueue()
        }
    } else {
        uploadFileFromQueue()
    }
}

function uploadFileFromQueue() {
    const uploads = store.state.uploads
    const file = uploads.queue[uploads.queueIndex]
    if (file) {
        store.dispatch("uploads/uploadFile", file)
        store.dispatch("uploads/updateQueueIndex", uploads.queueIndex + 1)
    }
}

function updateFileProgress(file) {
    console.log("WILL UPDATE FILE STATUS", JSON.stringify(file))
    console.log("FILE IS ", file)
    console.log("STATUS OF FILE IS ", file.status)

    store.dispatch("uploads/updateFile", preparedFile(file))
}

function preparedFile(file) {
    return {
        name: file.content.name,
        progress: file.progressPercent,
        status: file.status
    }
}

export function processFiles(files) {
    let arr = []

    for (const key in files) {
        const file = files[key]
        if (typeof file === "object") {
            const fileObject = { ...fileTemplate }
            console.log("")
            fileObject.content = file

            if (file.type.indexOf("image") > -1) {
                fileObject.type = "image"
                fileObject.src = URL.createObjectURL(file)
            } else if (file.type.indexOf("video") > -1) {
                fileObject.type = "video"
                fileObject.src = window.location.href = "images/video.svg"
            } else {
                fileObject.type = "file"
                fileObject.src = window.location.href + "images/file.svg"
            }

            arr.push(fileObject)
        }
    }

    return arr
}