export const highPerformanceMouseDelay = 15
export const normalMouseDelay = 200
export const scrollCheckInterval = 50
export const maxScrollResetRetries = 50
export const leftClick = 1
export const minDistToCountScroll = 50
export const scrollResetTime = 3000
export const scrollSendDelay = 30

export const scrollModel  = {
    x: {
      lastSent: 0,
      default: 0,
      resetRetry: 0,
      resetTimeout: null,
      scrollTimeout: null,
      scrollInterval: null,
      sendTimeout: null,
      firstTouch: null
    },
    y: {
      lastSent: 0,
      default: 0,
      resetRetry: 0,
      resetTimeout: null,
      scrollTimeout: null,
      scrollInterval: null,
      sendTimeout: null,
      firstTouch: null
    }
  }