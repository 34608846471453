<template>
    <div :class="{'show-message': clipboardMessage}" class="clipboard-message">
        <p>{{ clipboardMessage }}</p>
    </div>
</template>

<script>
import { useStore, mapState } from 'vuex'
export default {
    data() {
        return {
            store: useStore(),
            clipboardMessageTimeout: null
        }
    },
    computed: {
        ...mapState({
            clipboardMessage: state => state.ui.clipboardMessage
        })
    },
    watch: {
        clipboardMessage() {
            if(this.clipboardMessageTimeout) {
                clearTimeout(this.clipboardMessageTimeout)
                this.clipboardMessageTimeout = null
            }
            
            this.clipboardMessageTimeout = setTimeout(() => {
                this.store.dispatch("ui/showClipboardMessage", null)
            }, 3000)
        }
    }
}
</script>