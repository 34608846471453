import store from "@/store"

let streaminfo

// We must call this function after the store inits
// If setting it directly it is undefined since it loads before the store
export function initStreamInfo () {
    streaminfo = store.state.streaminfo
}

// Defines the packet loss tolerance
// Bellow in the function verifyPacketsLost is the detailed description
const packetLossPercentLimit = {
    acceptable: 2,
    unacceptable: 4
}

// Indicator of how much we increase/decrease these parameters at once
const bitrateStep = 1000
const keyframeStep = 10

// Cleaner way of creating the sample object
function newSample (fps, lost, received) {
    return {
        fps,

        // Packages lost
        lost,

        // Packages received
        received
    }
}

// Statuses for the test action
// In case there is no need to increase or decrease we don't do anything and we specify it as 'stale' which is also the default status of a test
const testActions = {
    stale: 0,
    increase: 1,
    decrease: 2,
    decreaseDouble: 3,
}

// Returns a new test object
// Tests are containing the result of a quality verification of the webrt connection in a specific time interval
function newTest () {
    return {
        time: new Date(),
        passed: true,
        action: testActions.stale,
        samples: streaminfo.samples,
        testCategory: newTestCategory(),
        newCategory: newTestCategory(),
        packetsReceived: 0,
        packetLoss: 0,
        packetLossPercent: 0,
        avgFPS: 0,
        missingFPSpercent: 0,
    }
}

// Create a clean category for the test
// We decide the values depending on whether we are using query params to override default category values
function newTestCategory () {
    let category = Object.assign({}, streaminfo.currentCategory)

    if (streaminfo.queryActive) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString)

        const urlBitrate = urlParams.get('bitrate')
        const urlFPS = urlParams.get('fps')
        const urlKeyframe = urlParams.get('keyframe')

        if (urlBitrate) {
            category.bitrate = parseInt(urlBitrate);
        }

        if (urlFPS) {
            category.fps = parseInt(urlFPS);
        }

        if (urlKeyframe) {
            category.keyframe = parseInt(urlKeyframe);
        }
    }

    return category
}

// List of categories for quality control of the stream
export const streamCategories = {
    // Max
    max: newCategory(8000, 30, 90),

    // High
    high: newCategory(6000, 30, 90),

    // Medium
    // medium: newCategory(4000, 25, 15),

    // Low
    low: newCategory(4000, 30, 90)
}

// Cleaner way of creating the category object
export function newCategory (bitrate, fps, keyframe) {
    return {
        bitrate,
        fps,
        keyframe
    }
}

// Determine the packet loss percent for the current number of samples
function packetLossPercent () {
    const first = streaminfo.samples[0];
    const last = streaminfo.samples[streaminfo.samples.length - 1]

    // Since the sample holds the total number of packets lost up to that time
    // In order to know the specific packets lost in this interval we just subtract the last and first one
    const lost = last.lost - first.lost
    const received = last.received - first.received

    // Calculate the packets lost percentage based on the received ones
    const percentageRaw = (lost / received) * 100

    return Math.round(percentageRaw)
}

// Determine the average fps with excluded min and max value
function avgFPS () {
    const arr = streaminfo.samples.map(obj => obj.fps)
    const max = Math.max.apply(Math, arr)
    const min = Math.min.apply(Math, arr)

    let sum = arr.reduce((a, b) => a + b, 0);
    sum -= max + min;
    let num = streaminfo.samples.length - 2;

    return sum / num

}

// Determine how big is the percent of samples that don't have fps values
function missingFPSpercent () {
    let empty = 0

    streaminfo.samples.map(sample => {
        if (!sample.fps) {
            empty++
        }
    })
    const emptyPercentage = (empty / streaminfo.samples.length) * 100

    return Math.round(emptyPercentage)
}

// Called each time the stats collection interval is triggered
// Logs the stats info in a sample and decides if the number of samples is enough to run tests based of it
export function checkStats (stats) {
    if (!stats || !store.state.ui.videoReady) return
    logSample(stats);
    if (stats) {
        return
    }

    // The first three time stats are checked based on lower number of samples
    // This way we can determine if quality needs to be decreased and do it quicker and the user gets stable connection faster
    if ((streaminfo.pastTests.length < 3 && streaminfo.samples.length === 5) || streaminfo.samples.length === 15) {
        let test = newTest()
        // Verify if the samples fulfil the quality requirements
        verifySamples(test)

        // Setup the current category depending on the test results
        processTest(test)

        // Add the test in our store and log it
        addTest(test)

        // Clear the samples since the testing is done
        clearSamples()

        console.log("=========================================================================")
        console.log("=========================================================================")
        console.log("=========================================================================")
    }
    // We keep samples from the last 5min only, so we don't make a leak with some big data
    else if (streaminfo.pastTests.length > 20) {
        store.dispatch("streaminfo/shiftTests")
    }
}

// Print the test in console as a table
function logTest (test) {
    console.log("WE ARE IN LOGTEST", test)
    // let log = {}
    // const temp = Object.assign({}, test)

    // for (var key in test) {
    //     if (typeof test[key] !== "object") {
    //         // Because number won't mean anything we add the label of it as value for action
    //         if (key === "action") {
    //             for (var k in testActions) {
    //                 if (testActions[k] === test[key]) {
    //                     log[key] = k
    //                     temp.actionLabel = k
    //                 }
    //             }
    //         } else {
    //             log[key] = test[key]
    //         }
    //     }
    // }

    // console.table(log)
    // console.log("Full test info: ", test)

    // const msg = {
    //     type: 'streamInfoLog',
    //     body: {
    //         log: JSON.stringify(temp)
    //     }
    // }
    // store.dispatch('comms/sendMessage', msg)
}

// Creates and pushes a sample based of of the webrtc stats
function logSample (stats) {
    const sample = newSample(stats.framesPerSecond || 0, stats.packetsLost, stats.packetsReceived)
    store.dispatch("streaminfo/pushSample", sample)
}

function addTest (test) {
    store.dispatch("streaminfo/pushTest", test)
    store.dispatch("streaminfo/setCurrentTest", test)
}

// Reset the samples
// Is run after testing is finished on previous samples
function clearSamples () {
    store.dispatch("streaminfo/setSamples", [])
}

// Confirm the test status and react accordingly
function processTest (test) {
    // If the test passed and the category is different than our last one then set this as the last good category
    if (test.passed && test.currentCategory !== streaminfo.lastGoodCategory) {
        store.dispatch("streaminfo/setLastGoodCategory", test.currentCategory)
    }

    // Decrease quality of current category if that was decided
    if (test.action === testActions.decrease || test.action === testActions.decreaseDouble) {
        decreaseQuality(test)
    } else {
        // We can increase the quality only after minimum 3 consecutive tests
        // The next condition is three consecutive tests that passed the testing process
        if (streaminfo.pastTests.length > 3) {
            const last = streaminfo.pastTests.length - 1
            let increase = true;

            for (var i = last; i > last - 3; i--) {
                if (!streaminfo.pastTests[i].passed) {
                    increase = false
                }
            }

            if (increase) {
                test.action = testActions.increase
                increaseQuality(test)
            }
        } else {
            test.action = testActions.stale
        }
    }

    console.log("%cTest information:", "color: #4091d7")
    logTest(test)

    // If we are using query params to override values then we don't update the quality based on this stats
    // Instead the hardcoded value from the query params is being used through the whole stream
    if (!streaminfo.queryActive) {
        store.dispatch("streaminfo/setCurrentCategory", test.newCategory)
        updateStream(test)
    }
}

// Decide which parameters and for how much need to be decreased
// In case of already being at minimum or having query active for overriding we don't do anything
function decreaseQuality (test) {
    if (streaminfo.queryActive) return

    const double = test.action === testActions.decreaseDouble ? true : false
    let newCategory = Object.assign({}, streaminfo.currentCategory)

    if (newCategory.bitrate === streamCategories.low.bitrate) {
        if (newCategory.keyframe === streamCategories.low.keyframe) {
            console.log("%cConnection quality is at lowest", "color: #b34045")
            test.action = testActions.stale
            return
        } else {
            if (newCategory.keyframe < (streamCategories.low.keyframe - 5) && double) {
                newCategory.keyframe += 2 * keyframeStep
            } else {
                newCategory.keyframe += keyframeStep
            }
        }
    } else {
        let bitrateDecrease = double ? 2 * bitrateStep : bitrateStep
        newCategory.bitrate -= bitrateDecrease

        if(newCategory.bitrate < streamCategories.low.bitrate) {
            newCategory.bitrate = streamCategories.low.bitrate
        }

        for (var key in streamCategories) {
            const val = streamCategories[key]

            if (newCategory.bitrate === val.bitrate) {
                newCategory = val
            } else if (double && (newCategory.bitrate + bitrateStep === val)) {
                newCategory = val
            }
        }
    }

    console.log("%cConnection quality will be lowered", "color: #b34045")
    test.newCategory = newCategory
}

// Decide which parameters and for how much need to be increased
// In case of already being at maximum or having query active for overriding we don't do anything
function increaseQuality (test) {
    if (streaminfo.queryActive) return

    if (streaminfo.currentCategory === streamCategories.max.bitrate) {
        test.action = testActions.stale
        console.log("%cQuality is at highest", "color: #4091d7")
        return
    }

    /*  
        This is approach with gradually increasing the bitrate
        Commented due to really slow calibration to reach good point
    */
    // newCategory.bitrate += bitrateStep

    // for (var key in streamCategories) {
    //     if (streamCategories[key].bitrate === newCategory.bitrate) {
    //         newCategory = streamCategories[key]
    //     }
    // }

    console.log("%cWill increase quality", "color: #4091d7")
    test.newCategory = findNextCategory()
}

// Find the category that is next in quality above the current category stats
function findNextCategory () {
    let nextCategory = streamCategories.max

    for (var key in streamCategories) {
        if (streamCategories[key].bitrate > streaminfo.currentCategory.bitrate) {
            nextCategory = streamCategories[key]
        } else {
            break
        }
    }

    return nextCategory
}

// Runs tests to see if the quality of the video is satisfting
// If the samples collected result in bad quality then we decrease accordingly
function verifySamples (test) {
    const loss = packetLossPercent()

    test.packetLossPercent = loss;
    test.packetLoss = streaminfo.samples[streaminfo.samples.length - 1].lost - streaminfo.samples[0].lost
    test.packetsReceived = streaminfo.samples[streaminfo.samples.length - 1].received - streaminfo.samples[0].received
    test.avgFPS = avgFPS()
    test.missingFPSpercent = missingFPSpercent()

    if (loss > packetLossPercentLimit.acceptable) {
        test.passed = false;

        if (loss > packetLossPercentLimit.unacceptable) {
            test.action = testActions.decreaseDouble;
        } else {
            test.action = testActions.decrease;
        }

    } else if (missingFPSpercent() > 25) {
        test.action = testActions.decrease;
        test.passed = false;

    } else if (Math.abs(streaminfo.currentCategory.fps - avgFPS()) > 3) {
        test.action = testActions.decrease;
        test.passed = false;
    }
}

// Sends a message to the backend to update the streaming variables
function updateStream (test) {
    if (test.action === testActions.stale) {
        return
    }

    const msg = {
        type: "updateStream",
        body: {
            ...test.newCategory
        }
    }
    console.log("%cUpdating connection with the following params:", "color: #fecf6d")
    console.table(test.newCategory)
    store.dispatch('comms/sendMessage', msg)
}