export const availableKeys = [
    "arrowup",
    "arrowdown",
    "arrowleft",
    "arrowright",
    "control",
    "shift",
    "alt",
    "command",
    "delete",
    "insert",
    "backspace",
    "escape",
    "tab",
    "enter",
    "capslock",
    "numlock",
    "end",
    "home",
    "pageup",
    "pagedown",
    "up",
    "down",
    "left",
    "right"
]