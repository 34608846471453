import store from "@/store"
import { checkServer } from "./comms"
import { getVideoWidth, getVideoHeight } from "@/services/ui"

export function initWebrtc() {
    store.dispatch("streaminfo/setInitialStreamCategory")

    let webrtc = new WebRTC()
    webrtc.init()

    return webrtc;
}

class WebRTC {
    constructor() {
        this.peerConnection = null
        this.dataChannel = null
        this.fps = store.state.streaminfo.currentCategory.fps
        this.bitrate = store.state.streaminfo.currentCategory.bitrate
        this.keyframe = store.state.streaminfo.currentCategory.keyframe
        this.maxAttempts = store.state.config.webrtcAttempts
        this.iceIndex = 0
        this.disconnected = 0
        this.videoStream = null;
        this.audioStream = null;
    }

    init = () => {
        const coturn = this.getCoturn();
        console.log("Coturn is ", coturn)
        if (this.peerConnection) {  
            this.closeExistingPeerConnection()
        } 
        this.peerConnection = new RTCPeerConnection(coturn)
        
        this.peerConnection.onicecandidate = (ev) => this.gotIceCandidate(ev)
        this.peerConnection.oniceconnectionstatechange = () => this.onIceStateChange()
        this.peerConnection.ontrack = (ev) => this.onTrack(ev)
        this.peerConnection.onconnectionstatechange = () => this.onIceConnectionStateChange()

        this.peerConnection.ondatachannel = (ev) => this.onDataChannel(ev)

        this.sendOffer()
    }

    closeExistingPeerConnection = () => {
        try {
            this.dataChannel.close()
            this.peerConnection.close()
        } catch (e) {
            console.log("Couldnt close old webrtc connection or data channel")
        }

        this.peerConnection = null;
        this.dataChannel = null;
    }

    onDataChannel = (ev) => {
        this.dataChannel = ev.channel;
        this.dataChannel.onopen = function () {
            console.log("The channel is open and ready to be used")
        }
    }

    onIceConnectionStateChange = () => {
        if (this.peerConnection.connectionState === "connected") {
            this.disconnected = 0
            this.setSuccessfullIce()
        }
        console.log("Connection state changed and it is ", this.peerConnection.connectionState)
    }

    setSuccessfullIce = () => {
        var coturn = JSON.parse(atob(store.state.config.coturnClient));
        const obj = {
            index: this.iceIndex,
            url: coturn.IceServers[0].Urls[this.iceIndex]
        }
        localStorage.setItem('successfullIce', JSON.stringify(obj))
    }

    getSuccessfullIce = () => {
        const str = localStorage.getItem('successfullIce')
        return str ? JSON.parse(str) : null
    }

    getCoturn = () => {
        let coturn = store.state.config.coturnClient
        coturn = JSON.parse(atob(store.state.config.coturnClient))
        const successfullIce = this.getSuccessfullIce()
        console.log("Coturn is ", coturn)
        let index

        if(successfullIce && coturn.IceServers[0].Urls[successfullIce.index] === successfullIce.url) {
            index = successfullIce.index
        } else {
            localStorage.setItem('successfullIce', null)
            index = this.iceIndex
        }

        if(!coturn.IceServers[0].Urls[index]) {
            index = 0;
        }

        var coturnClient = {
            iceServers: [
                {
                    ...coturn.IceServers[0],
                    urls: [coturn.IceServers[0].Urls[index]]
                }
            ]
        }
        console.log("Will use coturn client ", coturnClient);
        return coturn
        
    }

    onTrack = ({ track, streams: [stream] }) => {
        if (track.kind === "audio") {
            this.audioStream = stream;
        } else {
            this.videoStream = stream;
            console.log("Just connected the video stream")
        }
    }

    gotIceCandidate = (ev) => {
        if (ev.candidate === null) {
            const sdp = btoa(JSON.stringify(this.peerConnection.localDescription))
            this.sendHttpAnswer(sdp)
        }
    }

    onIceStateChange = () => {
        console.log("Ice connection state changed and it is ", this.peerConnection.iceConnectionState)
        if (this.peerConnection.iceConnectionState === "disconnected" || this.peerConnection.iceConnectionState === "failed" || this.peerConnection.iceConnectionState === "closed") {
            console.log("Webrtc ice connection state has changed to ", this.peerConnection.iceConnectionState)
            this.disconnected++
            this.iceIndex++

            if (this.disconnected === this.maxAttempts) {
                this.fallback()
            } else {
                this.init()
            }

            setTimeout(() => {
                checkServer()
            }, 1000)
        }
    }

    setRemoteSDP = (sdp) => {
        try {
            this.peerConnection.setRemoteDescription(new RTCSessionDescription(JSON.parse(atob(sdp))))
            this.peerConnection.createAnswer().then(answer => this.peerConnection.setLocalDescription(answer))
        } catch (e) {
            console.log("Error setting remote description ", e)
        }
    }

    sendOffer = () => {
        console.log("Will send offer with category ", store.state.streaminfo.currentCategory)
        const streamOptions = this.generateStreamOptions();
        const msg = {
            type: "offer",
            w: getVideoWidth(),
            h: getVideoHeight(),
            ...streamOptions,
            audio: true,
            video: true
        }

        this.sendHttpOffer(msg)
    }

    generateStreamOptions() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        let queryActive = false;
        let bitrate = store.state.streaminfo.currentCategory.bitrate;
        let fps = store.state.streaminfo.currentCategory.fps;
        let keyframe = store.state.streaminfo.currentCategory.keyframe;

        const urlBitrate = urlParams.get('bitrate')
        const urlFPS = urlParams.get('fps')
        const urlKeyframe = urlParams.get('keyframe')

        if (urlBitrate) {
            bitrate = parseInt(urlBitrate);
            queryActive = true;
        } else {
            bitrate = parseInt("15000");
            queryActive = true;
        }

        if (urlFPS) {
            fps = parseInt(urlFPS);
            queryActive = true;
        } else {
            fps = parseInt("30");
            queryActive = true;
        }

        if (urlKeyframe) {
            keyframe = parseInt(urlKeyframe);
            queryActive = true;
        } else {
            keyframe = parseInt("60");
            queryActive = true;
        }
        store.dispatch("streaminfo/setQueryActive", queryActive)
        console.log("Will set query active to be ", queryActive)

        return {bitrate,fps,keyframe}
    }

    sendHttpOffer = (body) => {
        fetch(`${store.getters['general/isolatorURL']}offer`, {
            method: "POST",
            body: JSON.stringify(body)
        }).then(response => response.json())
            .then(res => {
                console.log("Response from offer is ", res)

                this.setRemoteSDP(res)
            }).catch(err => {
                console.log("Error from the offer is ", err)
            })
    }

    sendHttpAnswer = (sdp) => {
        fetch(`${store.getters['general/isolatorURL']}answer`, {
            method: "POST",
            body: JSON.stringify({ sdp })
        }).then(response => response.json())
            .then(res => {
                console.log("Response from answer is ", res)
            }).catch(err => {
                console.log("Error from the answer is ", err)
            })
    }

    fallback = () => {
        store.dispatch("general/setActiveStreamProtocol", "novnc")
    }
}