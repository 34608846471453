// import store from "@/store"

export function getBrowserName() {
  let browserName;
  let userAgent = navigator.userAgent
  let userVendor = navigator.vendor;

  if (userAgent.match(/firefox|fxios/i)) {
    browserName = "Mozilla Firefox";
  } else if (userAgent.match(/opr/i)) {
    browserName = "Opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "Microsoft Edge";
  }
  else if (userAgent.match(/safari/i) && userVendor.match(/apple/i)) {
    browserName = "Safari";
  }
  else if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "Google Chrome";
  } else {
    browserName = "Unknown Browser";
  }
  return browserName;
}
export function getBrowserLanguage() {
  let browserLanguage = navigator.language;
  return browserLanguage;
}
export function getBrowserPlugins() {
  let browserPlugins = JSON.stringify(navigator.plugins);
  return browserPlugins;
}
export function getBrowserCookies() {
  let browserCookies = document.cookie;
  return browserCookies;
}
export function getOsName() {
  let osName = ''


  if (geteviceType() === "Mobile") {
    osName = navigator.userAgentData.platform
    return osName;
  } else {
    if (navigator.platform) {
      osName = navigator.platform;
      return osName;
    } else if (navigator.userAgentData) {
      osName = navigator.userAgentData.platform;
      return osName;
    }
  }



}
export function getCpuInfo() {
  let cpuInfo = navigator.hardwareConcurrency;
  return cpuInfo;
}
export function getGpuInfo() {
  var canvas = document.createElement("canvas");
  var gl = canvas.getContext("webgl");
  let gpuInfo = gl.getParameter(gl.VERSION);
  return gpuInfo;
}
export function getGpuVendor() {
  var canvas = document.createElement("canvas");
  var gl = canvas.getContext("webgl");
  let gpuVendor = gl.getParameter(gl.VENDOR);
  return gpuVendor;
}
export function getGpuRenderer() {
  var canvas = document.createElement("canvas");
  var gl = canvas.getContext("webgl");
  let gpuRenderer = gl.getParameter(gl.RENDERER);
  return gpuRenderer;
}
export function geteviceType() {
  var deviceType = screen.width < 768 ? "Mobile" : "Desktop";
  return deviceType;
}
export function getSystemMemory() {
  let systemMemory = navigator.deviceMemory;
  return systemMemory;
}
// export async function getBatteryInfo() { TO DO
//   let battery = await navigator.getBattery();
//   return battery.level * 100 + "%";
// }
// export async function getISP() { TO DO
//   let request = await fetch("https://ipapi.co/json/");
//   let body = await request.json();
//   return body.org;
// }
export function getConnectionType() {
  if (
    navigator.connection === undefined ||
    navigator.connection === "" ||
    navigator.connection === null
  ) {
    return "";
  }
  let connectionType = navigator.connection.effectiveType;
  return connectionType;
}
export function getNetworkType() {
  if (
    navigator.connection === undefined ||
    navigator.connection === "" ||
    navigator.connection === null
  ) {
    return "";
  }
  let networkType = navigator.connection.downlink + "Mbps";
  return networkType;
}
